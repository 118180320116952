unit USucheTeilnehmer;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs,
  UDatamodule,
  UGlobvar, WEBLib.DBCtrls, WEBLib.StdCtrls, DB,
  WEBLib.ExtCtrls, WEBLib.Grids,
  WEBLib.CDS,
  UEditTeilnehmer,
  UVWAllergien,
  UWochenplan,
  UEditSubAccount;

type
  TSucheTeilnehmer = class(TForm)
    WebPanel1: TPanel;
    WebDBGrid1: TDBGrid;
    WebPanel3: TPanel;
    WebButton1: TButton;
    WebButton2: TButton;
    procedure WebFormShow(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
    procedure WebButton1Click(Sender: TObject);
    procedure WebButton2Click(Sender: TObject);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
    procedure WebFormResize(Sender: TObject);
  private
    { Private-Deklarationen }
  protected
    procedure LoadDFMValues; override;
  public
    { Public-Deklarationen }
    Zeilen_Liste : TStringList;
    Zeilen_Count : integer;
    Form_Aktiv : boolean;


  end;

var
  SucheTeilnehmer: TSucheTeilnehmer;

implementation





procedure TSucheTeilnehmer.WebButton1Click(Sender: TObject);
begin
  Close;
end;

procedure TSucheTeilnehmer.WebButton2Click(Sender: TObject);
begin
  if Datamodule1.TeilnehmerDataSet.FieldByName('ID').AsInteger = 0 then exit;
  Auswahl_TID := Datamodule1.TeilnehmerDataSet.FieldByName('ID').AsInteger;
//  Auswahl_TNo := Datamodule1.TeilnehmerDataSet.FieldByName('Nummer').AsInteger;
  Auswahl_TName := Datamodule1.TeilnehmerDataSet.FieldByName('Name').AsString;
  Close;
end;

procedure TSucheTeilnehmer.WebFormClose(Sender: TObject;
  var Action: TCloseAction);
begin
  Zeilen_Liste.Clear;
  Zeilen_Liste.Free;

end;

procedure TSucheTeilnehmer.WebFormCreate(Sender: TObject);
begin
  Form_Aktiv := false;
end;

procedure TSucheTeilnehmer.WebFormResize(Sender: TObject);
begin
  if not Form_Aktiv then exit;

  Resize_Edit_Controls(WF_Grid,self,Zeilen_Liste,WebPanel1,WebPanel3);

end;

procedure TSucheTeilnehmer.WebFormShow(Sender: TObject);
begin
  Auswahl_TID := 0;
  Auswahl_TNo := 0;
  Auswahl_TName := '';

  Resize_Top_Panel_3(self,WebPanel1,'Akt. Einrichtung/Schule: '+AktEinrichtungName,'Suche nach: '+Akt_Suchbegriff);
  Resize_Bottom_Panel(self,WebPanel3);
  Zeilen_Liste := TStringList.Create;
  Zeilen_Count := 0;

  Resize_Edit_Controls(WF_Grid,self,Zeilen_Liste,WebPanel1,WebPanel3);
  Form_Aktiv := true;

  WebDBGrid1.DataSource := DataModule1.TeilnehmerDataSource;
{
  Datamodule1.TeilnehmerDataSet.Active := false;
  if not Datamodule1.TeilnehmerDataSet.Active then begin
    if Lade_Daten(Datamodule1.TeilnehmerDataSet,'Teilnehmer','$filter=NL_Key eq '+IntToStr(AktFirmaNo)+' AND Einrichtung eq '
      + IntToStr(AktEinrichtung)) then begin
    end;
  end;
}

end;



procedure TSucheTeilnehmer.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebPanel1 := TPanel.Create(Self);
  WebDBGrid1 := TDBGrid.Create(Self);
  WebPanel3 := TPanel.Create(Self);
  WebButton1 := TButton.Create(Self);
  WebButton2 := TButton.Create(Self);

  WebPanel1.BeforeLoadDFMValues;
  WebDBGrid1.BeforeLoadDFMValues;
  WebPanel3.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  try
    Name := 'SucheTeilnehmer';
    Left := 0;
    Top := 0;
    Width := 789;
    Height := 480;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -16;
    Font.Name := 'Arial';
    Font.Style := [];
    TabOrder := 1;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnResize', 'WebFormResize');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 0;
    WebPanel1.Top := 0;
    WebPanel1.Width := 789;
    WebPanel1.Height := 177;
    WebPanel1.Align := alTop;
    WebPanel1.BorderColor := clSilver;
    WebPanel1.BorderStyle := bsSingle;
    WebPanel1.ChildOrder := 1;
    WebDBGrid1.SetParentComponent(Self);
    WebDBGrid1.Name := 'WebDBGrid1';
    WebDBGrid1.Left := 0;
    WebDBGrid1.Top := 177;
    WebDBGrid1.Width := 789;
    WebDBGrid1.Height := 243;
    WebDBGrid1.Align := alClient;
    WebDBGrid1.Columns.Clear;
    with WebDBGrid1.Columns.Add do
    begin
      DataField := 'AKTIV';
      DataType := cdCheck;
      Title := 'Aktiv';
      Width := 40;
    end;
    with WebDBGrid1.Columns.Add do
    begin
      DataField := 'NAME';
      Title := 'Name';
      Width := 239;
    end;
    with WebDBGrid1.Columns.Add do
    begin
      DataField := 'KZ_SUBACCOUNT';
      DataType := cdCheck;
      Title := 'Unterkonto';
      Width := 88;
    end;
    WebDBGrid1.FixedCols := 1;
    WebDBGrid1.Options := [goFixedVertLine,goFixedHorzLine,goVertLine,goHorzLine,goRangeSelect,goTabs,goRowSelect];
    WebDBGrid1.TabOrder := 1;
    WebDBGrid1.ShowIndicator := False;
    WebDBGrid1.ColWidths[0] := 20;
    WebDBGrid1.ColWidths[1] := 40;
    WebDBGrid1.ColWidths[2] := 239;
    WebDBGrid1.ColWidths[3] := 88;
    WebDBGrid1.RowHeights[0] := 22;
    WebDBGrid1.RowHeights[1] := 22;
    WebDBGrid1.RowHeights[2] := 22;
    WebDBGrid1.RowHeights[3] := 22;
    WebDBGrid1.RowHeights[4] := 22;
    WebPanel3.SetParentComponent(Self);
    WebPanel3.Name := 'WebPanel3';
    WebPanel3.Left := 0;
    WebPanel3.Top := 420;
    WebPanel3.Width := 789;
    WebPanel3.Height := 60;
    WebPanel3.Align := alBottom;
    WebPanel3.BorderColor := clSilver;
    WebPanel3.BorderStyle := bsSingle;
    WebPanel3.ChildOrder := 3;
    WebButton1.SetParentComponent(WebPanel3);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 213;
    WebButton1.Top := 6;
    WebButton1.Width := 81;
    WebButton1.Height := 41;
    WebButton1.Caption := 'Zur'#252'ck';
    WebButton1.ChildOrder := 3;
    WebButton1.ElementClassName := 'btn-primary';
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    WebButton2.SetParentComponent(WebPanel3);
    WebButton2.Name := 'WebButton2';
    WebButton2.Left := 24;
    WebButton2.Top := 6;
    WebButton2.Width := 153;
    WebButton2.Height := 41;
    WebButton2.Caption := #220'bernehmen';
    WebButton2.ChildOrder := 1;
    WebButton2.ElementClassName := 'btn-primary';
    SetEvent(WebButton2, Self, 'OnClick', 'WebButton2Click');
  finally
    WebPanel1.AfterLoadDFMValues;
    WebDBGrid1.AfterLoadDFMValues;
    WebPanel3.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
  end;
end;

end.
