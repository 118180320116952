unit UEssenausgabe;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.StdCtrls, WEBLib.ExtCtrls,
  DB,

  UDatamodule,
  UGlobvar, WEBLib.Grids, WEBLib.DBCtrls,
  USucheTeilnehmer,
  UZeigeEssen,
  UUebergabe_Chip,
  UZeigeSUmmen;

type
  TEssensausgabe = class(TForm)
    WebPanel1: TPanel;
    WebLabel2: TLabel;
    WebEdit3: TEdit;
    WebButton2: TButton;
    WebTimer1: TTimer;
    WebButton3: TButton;
    WebEdit4: TEdit;
    WebLabel3: TLabel;
    WebLabel4: TLabel;
    WebPanel2: TPanel;
    WebButton4: TButton;
    WebButton1: TButton;
    WebButton5: TButton;

    procedure WebFormShow(Sender: TObject);
    procedure WebButton1Click(Sender: TObject);
    procedure WebButton2Click(Sender: TObject);
    procedure WebEdit3Change(Sender: TObject);
    procedure WebTimer1Timer(Sender: TObject);
    procedure WebButton3Click(Sender: TObject);
    procedure Suchen_Teilnehmer(DataSet: TDataSet);
    procedure Zeige_Essen(DataSet: TDataSet);
    procedure Zeige_Essen_2(DataSet: TDataSet);
    procedure WebButton4Click(Sender: TObject);
    procedure Zeige_Summen(DataSet: TDataSet);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
    procedure WebFormCreate(Sender: TObject);
    procedure WebFormResize(Sender: TObject);
    procedure WebButton5Click(Sender: TObject);
    procedure Erfasse_Chip(DataSet: TDataSet);
    procedure Erfasse_Chip_2(DataSet: TDataSet);
  private
    { Private-Deklarationen }
  protected
    procedure LoadDFMValues; override;
  public
    { Public-Deklarationen }
    STN_Mode : integer;
    Zeilen_Liste : TStringList;
    Zeilen_Count : integer;
    Form_Aktiv : boolean;

  end;

var
  Essensausgabe: TEssensausgabe;

implementation


procedure TEssensausgabe.WebButton1Click(Sender: TObject);
begin
  Close;
end;

procedure TEssensausgabe.WebButton2Click(Sender: TObject);
var hst : String;
begin
  hst := WebEdit3.Text;
  if length(hst) = Laenge_ID_No then begin
    Datamodule1.TeilnehmerDataSet.Active := false;
    Datamodule1.TeilnehmerDataSet.AfterOpen := Zeige_Essen;
    DataModule1.TeilnehmerDataSet.QueryString := '$filter=NL_Key eq '+IntToStr(AktFirmaNo)+' AND ChipID eq '
        + ''''+hst+'''';
    DataModule1.TeilnehmerDataSet.Load;


  end
  else begin
    MessageDlg('Ungültige Länge der Nummer von Chip-ID/Karten-Nr.', mtError, [], nil);
    WebEdit3.Text := '';
    WebEdit3.SetFocus;
    exit;
  end;
end;

procedure TEssensausgabe.WebButton3Click(Sender: TObject);
var hst : String;
begin
  hst := Trim(WebEdit4.Text);
  if hst = '' then begin
    MessageDlg('Name muss erfasst werden', mtError, [], nil);
    WebEdit4.Text := '';
    WebEdit4.SetFocus;
    exit;
  end;
  Akt_Suchbegriff := hst;
  STN_Mode := 1;
  Datamodule1.TeilnehmerDataSet.Active := false;
  Datamodule1.TeilnehmerDataSet.AfterOpen := Suchen_Teilnehmer;
  DataModule1.TeilnehmerDataSet.QueryString := '$filter=NL_Key eq '+IntToStr(AktFirmaNo)+' AND Einrichtung eq '
      + IntToStr(AktEinrichtung) + ' and contains(UPC_Name,'+''''+Uppercase(hst)+''''+')';
  DataModule1.TeilnehmerDataSet.Load;


end;

procedure TEssensausgabe.WebButton4Click(Sender: TObject);
var hst : String;
    JJ,MM,TT : word;
    JJ2,MM2,TT2 : String;
    i : integer;
begin
  Decodedate(Date,JJ,MM,TT);
  Str(JJ:4,JJ2);
  Str(MM:2,MM2);
  Str(TT:2,TT2);
  hst := JJ2+'-'+MM2+'-'+TT2;
  for i := 1 to length(hst) do begin
    if hst[i] = ' ' then hst[i] := '0';
  end;

  Datamodule1.EinrichtungSummenDataset.Active := false;
  Datamodule1.EinrichtungSummenDataset.AfterOpen := Zeige_Summen;
  DataModule1.EinrichtungSummenDataset.QueryString := '$filter=ID eq '+IntToStr(AktEinrichtungID)+' AND Datum eq '
      + ''''+hst+'''';
  DataModule1.EinrichtungSummenDataset.Load;

end;

procedure TEssensausgabe.WebButton5Click(Sender: TObject);
var hst : String;
begin
  hst := Trim(WebEdit4.Text);
  if hst = '' then begin
    MessageDlg('Name muss erfasst werden', mtError, [], nil);
    WebEdit4.Text := '';
    WebEdit4.SetFocus;
    exit;
  end;
  STN_Mode := 2;

  Akt_Suchbegriff := hst;
  Datamodule1.TeilnehmerDataSet.Active := false;
  Datamodule1.TeilnehmerDataSet.AfterOpen := Suchen_Teilnehmer;
  DataModule1.TeilnehmerDataSet.QueryString := '$filter=NL_Key eq '+IntToStr(AktFirmaNo)+' AND Einrichtung eq '
      + IntToStr(AktEinrichtung) + ' and contains(UPC_Name,'+''''+Uppercase(hst)+''''+')';
  DataModule1.TeilnehmerDataSet.Load;


end;

procedure TEssensausgabe.WebEdit3Change(Sender: TObject);
var hst : String;
begin
  hst := Trim(WebEdit3.Text);
  if length(hst) = Laenge_ID_No then begin
    Datamodule1.TeilnehmerDataSet.Active := false;
    Datamodule1.TeilnehmerDataSet.AfterOpen := Zeige_Essen;
    DataModule1.TeilnehmerDataSet.QueryString := '$filter=NL_Key eq '+IntToStr(AktFirmaNo)+' AND ChipID eq '
        + ''''+hst+'''';
    DataModule1.TeilnehmerDataSet.Load;
  end;
end;

procedure TEssensausgabe.WebFormClose(Sender: TObject;
  var Action: TCloseAction);
begin
  Zeilen_Liste.Clear;
  Zeilen_Liste.Free;

end;

procedure TEssensausgabe.WebFormCreate(Sender: TObject);
begin
  Form_Aktiv := false;
end;

procedure TEssensausgabe.WebFormResize(Sender: TObject);
begin
  if not Form_Aktiv then exit;

  Resize_Edit_Controls(WF_Edit,self,Zeilen_Liste,WebPanel1,WebPanel2);

end;

procedure TEssensausgabe.WebFormShow(Sender: TObject);
var i,j : integer;
    TT,MM,JJ : word;
    hst,
    hst2,
    hst3 : String;
begin
  Resize_Top_Panel_3(self,WebPanel1,'Akt. Benutzer: '+AktKDName,'Akt. Einrichtung/Schule: '+AktEinrichtungName);
  Resize_Bottom_Panel(self,WebPanel2);
  Zeilen_Liste := TStringList.Create;
  Zeilen_Count := 0;
  Zeilen_Liste_Add_Hinweis(Zeilen_Liste,Zeilen_Count,WebLabel4);
  Zeilen_Liste_Add(Zeilen_Liste,Zeilen_Count,WebLabel2,WebEdit3,nil);
  Zeilen_Liste_Set(Zeilen_Liste,WebEdit3,WebButton2);
  Zeilen_Liste_Add(Zeilen_Liste,Zeilen_Count,WebLabel3,WebEdit4,nil);
  Zeilen_Liste_Set(Zeilen_Liste,WebEdit4,WebButton3);

  Resize_Edit_Controls(WF_Edit,self,Zeilen_Liste,WebPanel1,WebPanel2);
  Form_Aktiv := true;


  WebEdit3.Text := '';
  WebEdit3.SetFocus;
  WebEdit4.Text := '';
  Decodedate(date,JJ,MM,TT);
  Str(JJ:4,hst);
  Str(MM:2,hst2);
  Str(TT:2,hst3);
  hst := hst3 + '.' + hst2 +'.'+hst;
  for i := 1 to length(hst) do begin
    if hst[i] = ' ' then hst[i] := '0';
  end;
  j := DayOfWeek(Date);
  case j of
    1 : hst := 'So, ' + hst;
    2 : hst := 'Mo, ' + hst;
    3 : hst := 'Di, ' + hst;
    4 : hst := 'Mi, ' + hst;
    5 : hst := 'Do, ' + hst;
    6 : hst := 'Fr, ' + hst;
    7 : hst := 'Sa, ' + hst;
  end;
  WebLabel4.Caption := 'Essensausgabe '+hst;
end;

procedure TEssensausgabe.WebTimer1Timer(Sender: TObject);
begin
  WebEdit3.SetFocus;
end;


procedure TEssensausgabe.Suchen_Teilnehmer(DataSet: TDataSet);
var
  newform: TSucheTeilnehmer;

begin
  Auswahl_TID := 0;
  Auswahl_TNo := 0;
  Auswahl_TName := '';

  newform := TSucheTeilnehmer.CreateNew(procedure(AForm: TObject)
    begin
      (AForm as TSucheTeilnehmer).Caption := 'Suchen Essensteilnehmer';
    end
  );
//  newForm.Caption := 'Neues Konto';
  newform.Border := fbSingle;

  newform.ShowModal(procedure(AValue: TModalResult)
  begin
    if Auswahl_TID <> 0 then begin
      if STN_Mode = 1 then begin
        WebEdit4.Text := '';
        Zeige_Essen(Datamodule1.TeilnehmerDataSet);
      end
      else begin
  asm debugger; end;
        WebEdit4.Text := '';
        Erfasse_Chip(Datamodule1.TeilnehmerDataSet);

      end;
    end
    else begin
      WebEdit4.Text := '';
    end;
  end
  );



end;


procedure TEssensausgabe.Zeige_Essen(DataSet: TDataSet);
var JJ,MM,TT : word;
    JJ2,MM2,TT2 : String;
    hst : String;
    i : integer;
begin
  if Datamodule1.TeilnehmerDataSet.FieldByName('ID').AsInteger = 0 then begin
    MessageDlg('Essensteilnehmer mit Chip-ID/Karten-Nr. <'+WebEdit3.Text+'> nicht gefunden', mtError, [], nil);
    WebEdit3.Text := '';
    WebEdit3.SetFocus;
    exit;
  end;
  if Datamodule1.TeilnehmerDataSet.FieldByName('Einrichtung').AsInteger <> AktEinrichtung then begin
    MessageDlg('Diese ID/Nr. ist vergeben für "'+Datamodule1.TeilnehmerDataSet.FieldByName('Name').AsString
                + '" in Einrichtung '+ Datamodule1.TeilnehmerDataSet.FieldByName('Einrichtung').AsString,mtError, [], nil);
    WebEdit3.SetFocus;
    exit;
  end;
  Decodedate(Date,JJ,MM,TT);
  Str(JJ:4,JJ2);
  Str(MM:2,MM2);
  Str(TT:2,TT2);
  hst := JJ2+'-'+MM2+'-'+TT2;
  for i := 1 to length(hst) do begin
    if hst[i] = ' ' then hst[i] := '0';
  end;

  Datamodule1.SpeiseplanDataSet.Active := false;
  Datamodule1.SpeiseplanDataSet.AfterOpen := Zeige_Essen_2;
  DataModule1.SpeiseplanDataSet.QueryString := '$filter=TID eq '+Datamodule1.TeilnehmerDataSet.FieldByName('ID').AsString + ' AND Datum eq '
      + ''''+hst+'''';
  DataModule1.SpeiseplanDataSet.Load;

end;



procedure TEssensausgabe.Zeige_Essen_2(DataSet: TDataSet);
var
  newform: TZeigeEssen;
begin
  newform := TZeigeEssen.CreateNew(procedure(AForm: TObject)
    begin
      (AForm as TZeigeEssen).Caption := 'Essen';
    end
  );
//  newForm.Caption := 'Neues Konto';
  newform.Border := fbSingle;

  newform.ShowModal(procedure(AValue: TModalResult)
  begin
    WebEdit3.Text := '';
    WebEdit3.SetFocus;
  end
  );



end;


procedure TEssensausgabe.Zeige_Summen(DataSet: TDataSet);
var
  newform: TZeigeSummen;
begin
  newform := TZeigeSummen.CreateNew(procedure(AForm: TObject)
    begin
      (AForm as TZeigeSummen).Caption := 'Essen';
    end
  );
//  newForm.Caption := 'Neues Konto';
  newform.Border := fbSingle;

  newform.ShowModal(procedure(AValue: TModalResult)
  begin
    WebEdit3.Text := '';
    WebEdit3.SetFocus;

  end
  );



end;

procedure TEssensausgabe.Erfasse_Chip(DataSet: TDataSet);
begin
  Datamodule1.TeilnehmerDataSet.Active := false;
  Datamodule1.TeilnehmerDataSet.AfterOpen := Erfasse_Chip_2;
  DataModule1.TeilnehmerDataSet.QueryString := '$filter=ID eq '+IntToStr(Auswahl_TID);
  DataModule1.TeilnehmerDataSet.Load;
end;


procedure TEssensausgabe.Erfasse_Chip_2(DataSet: TDataSet);
var
  newform: TUebergabe_Chip;
begin
  newform := TUebergabe_Chip.CreateNew(procedure(AForm: TObject)
    begin
      (AForm as TUebergabe_Chip).Caption := 'Chip-Uebergabe';
    end
  );
//  newForm.Caption := 'Neues Konto';
  newform.Border := fbSingle;

  newform.ShowModal(procedure(AValue: TModalResult)
  begin

  end
  );

end;



procedure TEssensausgabe.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebLabel2 := TLabel.Create(Self);
  WebLabel3 := TLabel.Create(Self);
  WebLabel4 := TLabel.Create(Self);
  WebPanel1 := TPanel.Create(Self);
  WebEdit3 := TEdit.Create(Self);
  WebButton2 := TButton.Create(Self);
  WebButton3 := TButton.Create(Self);
  WebEdit4 := TEdit.Create(Self);
  WebPanel2 := TPanel.Create(Self);
  WebButton4 := TButton.Create(Self);
  WebButton1 := TButton.Create(Self);
  WebButton5 := TButton.Create(Self);
  WebTimer1 := TTimer.Create(Self);

  WebLabel2.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  WebLabel4.BeforeLoadDFMValues;
  WebPanel1.BeforeLoadDFMValues;
  WebEdit3.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  WebButton3.BeforeLoadDFMValues;
  WebEdit4.BeforeLoadDFMValues;
  WebPanel2.BeforeLoadDFMValues;
  WebButton4.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  WebButton5.BeforeLoadDFMValues;
  WebTimer1.BeforeLoadDFMValues;
  try
    Name := 'Essensausgabe';
    Left := 0;
    Top := 0;
    Width := 640;
    Height := 466;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -16;
    Font.Name := 'Arial';
    Font.Style := [];
    TabOrder := 1;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnResize', 'WebFormResize');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    WebLabel2.SetParentComponent(Self);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 6;
    WebLabel2.Top := 223;
    WebLabel2.Width := 101;
    WebLabel2.Height := 16;
    WebLabel2.Caption := 'Chip-ID/Karten-Nr';
    WebLabel2.Color := clBtnFace;
    WebLabel2.Font.Charset := DEFAULT_CHARSET;
    WebLabel2.Font.Color := clWindowText;
    WebLabel2.Font.Height := -13;
    WebLabel2.Font.Name := 'Arial';
    WebLabel2.Font.Style := [];
    WebLabel2.ParentFont := False;
    WebLabel3.SetParentComponent(Self);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Left := 6;
    WebLabel3.Top := 287;
    WebLabel3.Width := 34;
    WebLabel3.Height := 16;
    WebLabel3.Caption := 'Name';
    WebLabel3.Color := clBtnFace;
    WebLabel3.Font.Charset := DEFAULT_CHARSET;
    WebLabel3.Font.Color := clWindowText;
    WebLabel3.Font.Height := -13;
    WebLabel3.Font.Name := 'Arial';
    WebLabel3.Font.Style := [];
    WebLabel3.ParentFont := False;
    WebLabel4.SetParentComponent(Self);
    WebLabel4.Name := 'WebLabel4';
    WebLabel4.Left := 8;
    WebLabel4.Top := 176;
    WebLabel4.Width := 102;
    WebLabel4.Height := 22;
    WebLabel4.Caption := 'WebLabel4';
    WebLabel4.Font.Charset := DEFAULT_CHARSET;
    WebLabel4.Font.Color := clWindowText;
    WebLabel4.Font.Height := -19;
    WebLabel4.Font.Name := 'Arial';
    WebLabel4.Font.Style := [fsBold];
    WebLabel4.ParentFont := False;
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 0;
    WebPanel1.Top := 0;
    WebPanel1.Width := 640;
    WebPanel1.Height := 153;
    WebPanel1.Align := alTop;
    WebPanel1.BorderColor := clSilver;
    WebPanel1.BorderStyle := bsSingle;
    WebPanel1.ChildOrder := 1;
    WebEdit3.SetParentComponent(Self);
    WebEdit3.Name := 'WebEdit3';
    WebEdit3.Left := 8;
    WebEdit3.Top := 245;
    WebEdit3.Width := 153;
    WebEdit3.Height := 22;
    WebEdit3.ChildOrder := 7;
    WebEdit3.ElementClassName := 'form-control';
    WebEdit3.Text := 'WebEdit1';
    SetEvent(WebEdit3, Self, 'OnChange', 'WebEdit3Change');
    WebButton2.SetParentComponent(Self);
    WebButton2.Name := 'WebButton2';
    WebButton2.Left := 182;
    WebButton2.Top := 237;
    WebButton2.Width := 195;
    WebButton2.Height := 42;
    WebButton2.Caption := 'Bestellung anzeigen';
    WebButton2.ChildOrder := 4;
    SetEvent(WebButton2, Self, 'OnClick', 'WebButton2Click');
    WebButton3.SetParentComponent(Self);
    WebButton3.Name := 'WebButton3';
    WebButton3.Left := 182;
    WebButton3.Top := 302;
    WebButton3.Width := 195;
    WebButton3.Height := 41;
    WebButton3.Caption := 'Chip/Karte vergessen';
    WebButton3.ChildOrder := 5;
    SetEvent(WebButton3, Self, 'OnClick', 'WebButton3Click');
    WebEdit4.SetParentComponent(Self);
    WebEdit4.Name := 'WebEdit4';
    WebEdit4.Left := 8;
    WebEdit4.Top := 309;
    WebEdit4.Width := 153;
    WebEdit4.Height := 22;
    WebEdit4.ChildOrder := 7;
    WebEdit4.ElementClassName := 'form-control';
    WebEdit4.Text := 'WebEdit1';
    SetEvent(WebEdit4, Self, 'OnChange', 'WebEdit3Change');
    WebPanel2.SetParentComponent(Self);
    WebPanel2.Name := 'WebPanel2';
    WebPanel2.Left := 0;
    WebPanel2.Top := 406;
    WebPanel2.Width := 640;
    WebPanel2.Height := 60;
    WebPanel2.Align := alBottom;
    WebPanel2.BorderColor := clSilver;
    WebPanel2.BorderStyle := bsSingle;
    WebPanel2.ChildOrder := 8;
    WebButton4.SetParentComponent(WebPanel2);
    WebButton4.Name := 'WebButton4';
    WebButton4.Left := 13;
    WebButton4.Top := 17;
    WebButton4.Width := 94;
    WebButton4.Height := 43;
    WebButton4.Caption := #220'bersicht';
    WebButton4.ChildOrder := 9;
    WebButton4.ElementClassName := 'btn-primary';
    SetEvent(WebButton4, Self, 'OnClick', 'WebButton4Click');
    WebButton1.SetParentComponent(WebPanel2);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 296;
    WebButton1.Top := 17;
    WebButton1.Width := 73;
    WebButton1.Height := 43;
    WebButton1.Caption := 'Zur'#252'ck';
    WebButton1.ChildOrder := 3;
    WebButton1.ElementClassName := 'btn-primary';
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    WebButton5.SetParentComponent(WebPanel2);
    WebButton5.Name := 'WebButton5';
    WebButton5.Left := 113;
    WebButton5.Top := 16;
    WebButton5.Width := 177;
    WebButton5.Height := 41;
    WebButton5.Caption := 'Zuordnung Karte/Chip';
    WebButton5.ChildOrder := 2;
    WebButton5.ElementClassName := 'btn-primary';
    SetEvent(WebButton5, Self, 'OnClick', 'WebButton5Click');
    WebTimer1.SetParentComponent(Self);
    WebTimer1.Name := 'WebTimer1';
    WebTimer1.Enabled := False;
    SetEvent(WebTimer1, Self, 'OnTimer', 'WebTimer1Timer');
    WebTimer1.Left := 384;
    WebTimer1.Top := 200;
  finally
    WebLabel2.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
    WebLabel4.AfterLoadDFMValues;
    WebPanel1.AfterLoadDFMValues;
    WebEdit3.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
    WebButton3.AfterLoadDFMValues;
    WebEdit4.AfterLoadDFMValues;
    WebPanel2.AfterLoadDFMValues;
    WebButton4.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    WebButton5.AfterLoadDFMValues;
    WebTimer1.AfterLoadDFMValues;
  end;
end;

end.
