unit UUebergabe_Chip;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.StdCtrls, WEBLib.DBCtrls,
  UDatamodule,
  UGlobvar, WEBLib.ExtCtrls;

type
  TUebergabe_Chip = class(TForm)
    UEBCHIP_WebLabel2: TLabel;
    UEBCHIP_WebDBEdit2: TDBEdit;
    UEBCHIP_WebLabel4: TLabel;
    UEBCHIP_WebDBEdit3: TDBEdit;
    UEBCHIP_WebLabel5: TLabel;
    UEBCHIP_WebPanel1: TPanel;
    UEBCHIP_WebPanel2: TPanel;
    UEBCHIP_WebButton2: TButton;
    UEBCHIP_WebButton1: TButton;
    UEBCHIP_WebLabel1: TLabel;
    UEBCHIP_WebEdit1: TEdit;
    procedure WebFormShow(Sender: TObject);
    procedure UEBCHIP_WebButton1Click(Sender: TObject);
    procedure UEBCHIP_WebButton2Click(Sender: TObject);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
    procedure WebFormResize(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
  private
    { Private-Deklarationen }
  protected
    procedure LoadDFMValues; override;
  public
    { Public-Deklarationen }


    Zeilen_Liste : TStringList;
    Zeilen_Count : integer;
    Form_Aktiv : boolean;

  end;

var
  Uebergabe_Chip: TUebergabe_Chip;

implementation


procedure TUebergabe_Chip.UEBCHIP_WebButton1Click(Sender: TObject);
begin
  Close;
end;

procedure TUebergabe_Chip.UEBCHIP_WebButton2Click(Sender: TObject);
var hst,hst2 : String;
begin
  hst := Trim(UEBCHIP_WebEdit1.Text);
  UEBCHIP_WebEdit1.Text := hst;
  if hst = '' then begin
    MessageDlg('Chip-ID/Karten-Nr. muß erfasst werden', mtError, [], nil);
    UEBCHIP_WebEdit1.SetFocus;
    exit;
  end;
  try
    DataModule1.TeilnehmerDataSet.Edit;
    DataModule1.TeilnehmerDataSet.Fieldbyname('ChipID').AsString := UEBCHIP_WebEdit1.Text;
    DataModule1.TeilnehmerDataSet.Post;
    DataModule1.TeilnehmerDataSet.ApplyUpdates;
  except
    MessageDlg('Fehler beim Speichern', mtError, [], nil);
    exit;
  end;
  Close;


end;

procedure TUebergabe_Chip.WebFormClose(Sender: TObject; var Action: TCloseAction);
begin
  Zeilen_Liste.Clear;
  Zeilen_Liste.Free;
end;

procedure TUebergabe_Chip.WebFormCreate(Sender: TObject);
begin
  Form_Aktiv := false;
end;

procedure TUebergabe_Chip.WebFormResize(Sender: TObject);
begin
  if not Form_Aktiv then exit;

  Resize_Edit_Controls(WF_Edit,self,Zeilen_Liste,UEBCHIP_WebPanel1,UEBCHIP_WebPanel2);

end;

procedure TUebergabe_Chip.WebFormShow(Sender: TObject);
begin
  UEBCHIP_WebEdit1.Text := '';
  if Trim(DataModule1.TeilnehmerDataSet.Fieldbyname('ChipID').AsString) = '' then begin
    UEBCHIP_WebLabel1.Caption := 'Erst-Übergabe Karte/Chip';
  end
  else begin
    UEBCHIP_WebLabel1.Caption := 'Alte Karte/alter Chip wird ungültig!';

  end;

  Resize_Top_Panel(self,UEBCHIP_WebPanel1);
  Resize_Bottom_Panel(self,UEBCHIP_WebPanel2);
  Zeilen_Liste := TStringList.Create;
  Zeilen_Count := 0;
  Zeilen_Liste_Add(Zeilen_Liste,Zeilen_Count,UEBCHIP_WebLabel2,UEBCHIP_WebDBEdit2,nil);
  Zeilen_Liste_Add(Zeilen_Liste,Zeilen_Count,UEBCHIP_WebLabel4,UEBCHIP_WebDBEdit3,nil);
  Zeilen_Liste_Add_Hinweis(Zeilen_Liste,Zeilen_Count,UEBCHIP_WebLabel1);
  Zeilen_Liste_Add(Zeilen_Liste,Zeilen_Count,UEBCHIP_WebLabel5,UEBCHIP_WebEdit1,nil);

  Resize_Edit_Controls(WF_Edit,self,Zeilen_Liste,UEBCHIP_WebPanel1,UEBCHIP_WebPanel2);
  Form_Aktiv := true;


end;

procedure TUebergabe_Chip.LoadDFMValues;
begin
  inherited LoadDFMValues;

  UEBCHIP_WebLabel2 := TLabel.Create(Self);
  UEBCHIP_WebLabel4 := TLabel.Create(Self);
  UEBCHIP_WebLabel5 := TLabel.Create(Self);
  UEBCHIP_WebLabel1 := TLabel.Create(Self);
  UEBCHIP_WebDBEdit2 := TDBEdit.Create(Self);
  UEBCHIP_WebDBEdit3 := TDBEdit.Create(Self);
  UEBCHIP_WebPanel1 := TPanel.Create(Self);
  UEBCHIP_WebPanel2 := TPanel.Create(Self);
  UEBCHIP_WebButton2 := TButton.Create(Self);
  UEBCHIP_WebButton1 := TButton.Create(Self);
  UEBCHIP_WebEdit1 := TEdit.Create(Self);

  UEBCHIP_WebLabel2.BeforeLoadDFMValues;
  UEBCHIP_WebLabel4.BeforeLoadDFMValues;
  UEBCHIP_WebLabel5.BeforeLoadDFMValues;
  UEBCHIP_WebLabel1.BeforeLoadDFMValues;
  UEBCHIP_WebDBEdit2.BeforeLoadDFMValues;
  UEBCHIP_WebDBEdit3.BeforeLoadDFMValues;
  UEBCHIP_WebPanel1.BeforeLoadDFMValues;
  UEBCHIP_WebPanel2.BeforeLoadDFMValues;
  UEBCHIP_WebButton2.BeforeLoadDFMValues;
  UEBCHIP_WebButton1.BeforeLoadDFMValues;
  UEBCHIP_WebEdit1.BeforeLoadDFMValues;
  try
    Name := 'Uebergabe_Chip';
    Left := 0;
    Top := 0;
    Width := 640;
    Height := 480;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -16;
    Font.Name := 'Arial';
    Font.Style := [];
    TabOrder := 1;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnResize', 'WebFormResize');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    UEBCHIP_WebLabel2.SetParentComponent(Self);
    UEBCHIP_WebLabel2.Name := 'UEBCHIP_WebLabel2';
    UEBCHIP_WebLabel2.Left := 8;
    UEBCHIP_WebLabel2.Top := 98;
    UEBCHIP_WebLabel2.Width := 135;
    UEBCHIP_WebLabel2.Height := 16;
    UEBCHIP_WebLabel2.Caption := 'Name Essenteilnehmer';
    UEBCHIP_WebLabel2.Color := clBtnFace;
    UEBCHIP_WebLabel2.Font.Charset := DEFAULT_CHARSET;
    UEBCHIP_WebLabel2.Font.Color := clWindowText;
    UEBCHIP_WebLabel2.Font.Height := -13;
    UEBCHIP_WebLabel2.Font.Name := 'Arial';
    UEBCHIP_WebLabel2.Font.Style := [];
    UEBCHIP_WebLabel2.ParentFont := False;
    UEBCHIP_WebLabel4.SetParentComponent(Self);
    UEBCHIP_WebLabel4.Name := 'UEBCHIP_WebLabel4';
    UEBCHIP_WebLabel4.Left := 8;
    UEBCHIP_WebLabel4.Top := 151;
    UEBCHIP_WebLabel4.Width := 162;
    UEBCHIP_WebLabel4.Height := 16;
    UEBCHIP_WebLabel4.Caption := 'Bisherige Chip-ID/Karten-Nr.';
    UEBCHIP_WebLabel4.Color := clBtnFace;
    UEBCHIP_WebLabel4.Font.Charset := DEFAULT_CHARSET;
    UEBCHIP_WebLabel4.Font.Color := clWindowText;
    UEBCHIP_WebLabel4.Font.Height := -13;
    UEBCHIP_WebLabel4.Font.Name := 'Arial';
    UEBCHIP_WebLabel4.Font.Style := [];
    UEBCHIP_WebLabel4.ParentFont := False;
    UEBCHIP_WebLabel5.SetParentComponent(Self);
    UEBCHIP_WebLabel5.Name := 'UEBCHIP_WebLabel5';
    UEBCHIP_WebLabel5.Left := 8;
    UEBCHIP_WebLabel5.Top := 239;
    UEBCHIP_WebLabel5.Width := 138;
    UEBCHIP_WebLabel5.Height := 16;
    UEBCHIP_WebLabel5.Caption := 'Neue Chip-ID/Karten-Nr.';
    UEBCHIP_WebLabel5.Color := clBtnFace;
    UEBCHIP_WebLabel5.Font.Charset := DEFAULT_CHARSET;
    UEBCHIP_WebLabel5.Font.Color := clWindowText;
    UEBCHIP_WebLabel5.Font.Height := -13;
    UEBCHIP_WebLabel5.Font.Name := 'Arial';
    UEBCHIP_WebLabel5.Font.Style := [];
    UEBCHIP_WebLabel5.ParentFont := False;
    UEBCHIP_WebLabel1.SetParentComponent(Self);
    UEBCHIP_WebLabel1.Name := 'UEBCHIP_WebLabel1';
    UEBCHIP_WebLabel1.Left := 8;
    UEBCHIP_WebLabel1.Top := 208;
    UEBCHIP_WebLabel1.Width := 79;
    UEBCHIP_WebLabel1.Height := 18;
    UEBCHIP_WebLabel1.Caption := 'Hinweistext';
    UEBCHIP_WebDBEdit2.SetParentComponent(Self);
    UEBCHIP_WebDBEdit2.Name := 'UEBCHIP_WebDBEdit2';
    UEBCHIP_WebDBEdit2.Left := 8;
    UEBCHIP_WebDBEdit2.Top := 115;
    UEBCHIP_WebDBEdit2.Width := 248;
    UEBCHIP_WebDBEdit2.Height := 22;
    UEBCHIP_WebDBEdit2.TabStop := False;
    UEBCHIP_WebDBEdit2.ChildOrder := 28;
    UEBCHIP_WebDBEdit2.ElementClassName := 'form-control';
    UEBCHIP_WebDBEdit2.ReadOnly := True;
    UEBCHIP_WebDBEdit2.Text := 'WebDBEdit1';
    UEBCHIP_WebDBEdit2.DataField := 'NAME';
    UEBCHIP_WebDBEdit2.DataSource := DataModule1.TeilnehmerDataSource;
    UEBCHIP_WebDBEdit3.SetParentComponent(Self);
    UEBCHIP_WebDBEdit3.Name := 'UEBCHIP_WebDBEdit3';
    UEBCHIP_WebDBEdit3.Left := 8;
    UEBCHIP_WebDBEdit3.Top := 170;
    UEBCHIP_WebDBEdit3.Width := 248;
    UEBCHIP_WebDBEdit3.Height := 22;
    UEBCHIP_WebDBEdit3.TabStop := False;
    UEBCHIP_WebDBEdit3.ChildOrder := 28;
    UEBCHIP_WebDBEdit3.ElementClassName := 'form-control';
    UEBCHIP_WebDBEdit3.ReadOnly := True;
    UEBCHIP_WebDBEdit3.Text := 'WebDBEdit1';
    UEBCHIP_WebDBEdit3.DataField := 'CHIPID';
    UEBCHIP_WebDBEdit3.DataSource := DataModule1.TeilnehmerDataSource;
    UEBCHIP_WebPanel1.SetParentComponent(Self);
    UEBCHIP_WebPanel1.Name := 'UEBCHIP_WebPanel1';
    UEBCHIP_WebPanel1.Left := 0;
    UEBCHIP_WebPanel1.Top := 0;
    UEBCHIP_WebPanel1.Width := 640;
    UEBCHIP_WebPanel1.Height := 89;
    UEBCHIP_WebPanel1.Align := alTop;
    UEBCHIP_WebPanel1.BorderColor := clSilver;
    UEBCHIP_WebPanel1.BorderStyle := bsSingle;
    UEBCHIP_WebPanel1.ChildOrder := 1;
    UEBCHIP_WebPanel2.SetParentComponent(Self);
    UEBCHIP_WebPanel2.Name := 'UEBCHIP_WebPanel2';
    UEBCHIP_WebPanel2.Left := 0;
    UEBCHIP_WebPanel2.Top := 420;
    UEBCHIP_WebPanel2.Width := 640;
    UEBCHIP_WebPanel2.Height := 60;
    UEBCHIP_WebPanel2.Align := alBottom;
    UEBCHIP_WebPanel2.BorderColor := clSilver;
    UEBCHIP_WebPanel2.BorderStyle := bsSingle;
    UEBCHIP_WebPanel2.ChildOrder := 15;
    UEBCHIP_WebButton2.SetParentComponent(UEBCHIP_WebPanel2);
    UEBCHIP_WebButton2.Name := 'UEBCHIP_WebButton2';
    UEBCHIP_WebButton2.Left := 8;
    UEBCHIP_WebButton2.Top := 16;
    UEBCHIP_WebButton2.Width := 177;
    UEBCHIP_WebButton2.Height := 41;
    UEBCHIP_WebButton2.Caption := #196'nderungen speichern';
    UEBCHIP_WebButton2.ChildOrder := 13;
    UEBCHIP_WebButton2.ElementClassName := 'btn-primary';
    UEBCHIP_WebButton2.TabOrder := 40;
    SetEvent(UEBCHIP_WebButton2, Self, 'OnClick', 'UEBCHIP_WebButton2Click');
    UEBCHIP_WebButton1.SetParentComponent(UEBCHIP_WebPanel2);
    UEBCHIP_WebButton1.Name := 'UEBCHIP_WebButton1';
    UEBCHIP_WebButton1.Left := 191;
    UEBCHIP_WebButton1.Top := 10;
    UEBCHIP_WebButton1.Width := 90;
    UEBCHIP_WebButton1.Height := 41;
    UEBCHIP_WebButton1.Caption := 'Zur'#252'ck';
    UEBCHIP_WebButton1.ChildOrder := 14;
    UEBCHIP_WebButton1.ElementClassName := 'btn-primary';
    SetEvent(UEBCHIP_WebButton1, Self, 'OnClick', 'UEBCHIP_WebButton1Click');
    UEBCHIP_WebEdit1.SetParentComponent(Self);
    UEBCHIP_WebEdit1.Name := 'UEBCHIP_WebEdit1';
    UEBCHIP_WebEdit1.Left := 8;
    UEBCHIP_WebEdit1.Top := 264;
    UEBCHIP_WebEdit1.Width := 248;
    UEBCHIP_WebEdit1.Height := 22;
    UEBCHIP_WebEdit1.ChildOrder := 8;
    UEBCHIP_WebEdit1.Text := 'UEBCHIP_WebEdit1';
  finally
    UEBCHIP_WebLabel2.AfterLoadDFMValues;
    UEBCHIP_WebLabel4.AfterLoadDFMValues;
    UEBCHIP_WebLabel5.AfterLoadDFMValues;
    UEBCHIP_WebLabel1.AfterLoadDFMValues;
    UEBCHIP_WebDBEdit2.AfterLoadDFMValues;
    UEBCHIP_WebDBEdit3.AfterLoadDFMValues;
    UEBCHIP_WebPanel1.AfterLoadDFMValues;
    UEBCHIP_WebPanel2.AfterLoadDFMValues;
    UEBCHIP_WebButton2.AfterLoadDFMValues;
    UEBCHIP_WebButton1.AfterLoadDFMValues;
    UEBCHIP_WebEdit1.AfterLoadDFMValues;
  end;
end;

end.