unit UNeuesKonto;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
   WEBLib.Dialogs, WEBLib.StdCtrls, XData.Web.Client,
  UGlobvar,
  UDataModule, WEBLib.ExtCtrls,
WEBLib.Forms,
  UNeuesKonto2;

type
  TNeuesKonto = class(TForm)
    UNK_WebLabel_1: TLabel;
    WebEdit1: TEdit;
    WebLabel2: TLabel;
    WebEdit2: TEdit;
    WebLabel3: TLabel;
    WebComboBox1: TComboBox;
    WebLabel4: TLabel;
    WebEdit3: TEdit;
    WebLabel5: TLabel;
    WebEdit4: TEdit;
    WebLabel6: TLabel;
    WebEdit5: TEdit;
    WebButton1: TButton;
    WebButton2: TButton;
    WebLabel11: TLabel;
    WebEdit9: TEdit;
    WebLabel12: TLabel;
    WebLabel13: TLabel;
    WebLabel14: TLabel;
    WebLabel15: TLabel;
    WebLabel16: TLabel;
    WebLabel17: TLabel;
    WebLabel20: TLabel;
    WebPanel1: TPanel;
    WebLabel7: TLabel;
    WebLabel8: TLabel;
    procedure WebButton1Click(Sender: TObject);
    procedure WebButton2Click(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
  private
    { Private-Deklarationen }
  protected
    procedure LoadDFMValues; override;
  public
    { Public-Deklarationen }
  end;

var
  NeuesKonto: TNeuesKonto;



implementation




procedure TNeuesKonto.WebButton1Click(Sender: TObject);
begin
//   MessageDlg('Anrede muß erfasst werden', mtError, [], nil);

  Close;
end;

procedure TNeuesKonto.WebButton2Click(Sender: TObject);
var hst,hst2 : String;
    newform: TNeuesKonto2;

begin
  if WebCombobox1.ItemIndex = -1 then begin
    MessageDlg('Anrede muß erfasst werden', mtError, [], nil);
    WebCombobox1.SetFocus;
    exit;
  end;

  hst := Trim(WebEdit2.Text);
  WebEdit2.Text := hst;
  if hst = '' then begin
    MessageDlg('Vorname muß erfasst werden', mtError, [], nil);
    WebEdit2.SetFocus;
    exit;
  end;


  hst := Trim(WebEdit1.Text);
  WebEdit1.Text := hst;
  if hst = '' then begin
    MessageDlg('Name muß erfasst werden', mtError, [], nil);
    WebEdit1.SetFocus;
    exit;
  end;

  hst := Trim(WebEdit3.Text);
  WebEdit3.Text := hst;
  if hst = '' then begin
    MessageDlg('Strasse muß erfasst werden', mtError, [], nil);
    WebEdit3.SetFocus;
    exit;
  end;

  hst := Trim(WebEdit4.Text);
  WebEdit4.Text := hst;
  if hst = '' then begin
    MessageDlg('Postleitzahl muß erfasst werden', mtError, [], nil);
    WebEdit4.SetFocus;
    exit;
  end;

  hst := Trim(WebEdit5.Text);
  WebEdit5.Text := hst;
  if hst = '' then begin
    MessageDlg('Ort muß erfasst werden', mtError, [], nil);
    WebEdit5.SetFocus;
    exit;
  end;

  NeuKD_Anrede := WebCombobox1.Text;
  NeuKD_Titel := WebEdit9.Text;
  NeuKD_Vname := WebEdit2.Text;
  NeuKD_Name := WebEdit1.Text;
  NeuKD_PLZ := WebEdit4.Text;
  NeuKD_Strasse := WebEdit3.Text;
  NeuKD_Ort := WebEdit5.Text;

  Application.CreateForm(TNeuesKonto2, newform);
  newform.Border := fbSingle;
  newform.ShowModal;




{
  hst := Trim(WebEdit7.Text);
  WebEdit7.Text := hst;
  if hst = '' then begin
    MessageDlg('Email muß erfasst werden', mtError, [], nil);
    WebEdit7.SetFocus;
    exit;
  end;
}
{  if pos('@',hst) < 0 then begin
    MessageDlg('Dies kann keine gültige Email-Adresse sein', mtError, [], nil);
    WebEdit7.SetFocus;
    exit;
  end;
}
{
  hst := Trim(WebEdit8.Text);
  WebEdit8.Text := hst;
  if hst = '' then begin
    MessageDlg('Wiederholung Email muß erfasst werden', mtError, [], nil);
    WebEdit8.SetFocus;
    exit;
  end;

  if WebEdit8.Text <> WebEdit7.Text then begin
    MessageDlg('Email und Wiederholung Email stimmen nicht überein', mtError, [], nil);
    WebEdit8.SetFocus;
    exit;
  end;



  hst := Trim(WebEdit10.Text);
  WebEdit10.Text := hst;
  if hst = '' then begin
    MessageDlg('Kennwort muß erfasst werden', mtError, [], nil);
    WebEdit10.SetFocus;
    exit;
  end;

  if pos(' ',hst) > 0 then begin
    MessageDlg('Leerzeichen im Kennwort nicht erlaubt', mtError, [], nil);
    WebEdit7.SetFocus;
    exit;
  end;

  if length(hst) < 6 then begin
    MessageDlg('Kennwort muß mindestens 6 Zeichen lang sein', mtError, [], nil);
    WebEdit7.SetFocus;
    exit;
  end;


  hst := Trim(WebEdit11.Text);
  WebEdit11.Text := hst;
  if hst = '' then begin
    MessageDlg('Wiederholung Kennwort muß erfasst werden', mtError, [], nil);
    WebEdit11.SetFocus;
    exit;
  end;

  if WebEdit11.Text <> WebEdit10.Text then begin
    MessageDlg('Kennwort und Wiederholung Kennwort stimmen nicht überein', mtError, [], nil);
    WebEdit10.SetFocus;
    exit;
  end;
}

{FROM STD_INSERT_KUNDEN ('P_ID', 'P_NL_KEY', 'P_KDNO', 'P_NAME', 'P_NAME2',
    'P_STRASSE', 'P_PLZ', 'P_ORT', 'P_TELEFON', 'P_HANDY', 'P_EMAILADR', 'P_KENNWORT',

     'P_DATUHR', 'P_CONFIRMED', 'P_UPC_EMAIL', 'P_DATUHR2', 'P_BESTAETIGUNG',
     'P_AKTIV', 'P_STATUS', 'P_ANREDE', 'P_AKTITEL', 'P_IBAN', 'P_BIC',
     'P_BANK', 'P_KZBERECHTIGUNG') p;

SELECT p.NEWID
FROM STD_INSERT_KUNDEN ('P_ID', 'P_NL_KEY', 'P_KDNO', 'P_NAME', 'P_NAME2',
    'P_STRASSE', 'P_PLZ', 'P_ORT', 'P_TELEFON', 'P_HANDY', 'P_EMAILADR',
    'P_KENNWORT', 'P_DATUHR', 'P_CONFIRMED', 'P_UPC_EMAIL', 'P_DATUHR2',
    'P_BESTAETIGUNG', 'P_AKTIV', 'P_STATUS', 'P_ANREDE', 'P_AKTITEL',
    'P_IBAN', 'P_BIC', 'P_BANK', 'P_KZBERECHTIGUNG', 'P_ADMINLEVEL',
    'P_KDGRUPPE', 'P_ZAHLBED', 'P_ABRECHNUNGSART', 'P_CHIPID', 'P_PREISLISTE') p;


}

{
  hst2 := 'Select p.NEWID FROM STD_INSERT_KUNDEN (0,'+IntToStr(AktFirmaNo)+',0,'+AFZ(NeuKD_Name)
    +AFZ(NeuKD_VName)
    +AFZ(NeuKD_Strasse)
    +AFZ(NeuKD_PLZ)
    +AFZ(NeuKD_Ort)
    +AFZ('')
    +AFZ('')
    +AFZ(NeuKD_EMail)
    +AFZ(NeuKD_Kennwort)
    +AFZ_Now('')
    +'NULL,'
    +AFZ(Uppercase(NeuKD_EMail))
    +'NULL,'
    +'NULL,'
    +AFZ('T')
    +'0,'
    +AFZ(WebCombobox1.Text)
    +AFZ(WebEdit9.Text)
    +'NULL,'
    +'NULL,'
    +'NULL,'
    +AFZ2('0')
    +') p';


  DataModule1.XDataClient.RawInvoke('IAuthService.Do_Transaction', [0, hst2],
    procedure(Response: TXDataClientResponse)
    var
      i: integer;
      hst : String;
    begin
      hst := JS.ToString(JS.ToObject(Response.Result)['value']);
      if hst <> '' then begin
        i := StrToInt(hst);
        if i = -1 then begin
          MessageDlg('Kundenkoto mit dieser eMail-Adresse bereits vorhanden', mtError, [], nil);
        end
        else begin
          MessageDlg('Kundenkonto Nr. '+hst+' erstellt. Bitte prüfen Sie Ihr email-Postfach ggf. auch den Spam-Ordner', mtInformation, [], nil);
          Close;
        end;
      end
      else begin
        MessageDlg('Speichern nicht möglich', mtError, [], nil);
      end;

//      window.localStorage.setItem(cStorageTokenName, Token);
//      CheckLogin;
    end
  );

}

end;

procedure TNeuesKonto.WebFormCreate(Sender: TObject);
begin
  WebLabel7.Caption := AktFirmaName;

  NeuKD_Anrede := '';
  NeuKD_Titel := '';
  NeuKD_Vname := '';
  NeuKD_Name := '';
  NeuKD_PLZ := '';
  NeuKD_Strasse := '';
  NeuKD_Ort := '';
  NeuKD_Kennwort := '';
  NeuKD_Kennwort2 := '';
  NeuKD_email := '';
  NeuKD_email2 := '';

end;

procedure TNeuesKonto.WebFormShow(Sender: TObject);
begin
  WebLabel7.Caption := AktFirmaName;

end;

procedure TNeuesKonto.LoadDFMValues;
begin
  inherited LoadDFMValues;

  UNK_WebLabel_1 := TLabel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  WebLabel3 := TLabel.Create(Self);
  WebLabel4 := TLabel.Create(Self);
  WebLabel5 := TLabel.Create(Self);
  WebLabel6 := TLabel.Create(Self);
  WebLabel11 := TLabel.Create(Self);
  WebLabel12 := TLabel.Create(Self);
  WebLabel13 := TLabel.Create(Self);
  WebLabel14 := TLabel.Create(Self);
  WebLabel15 := TLabel.Create(Self);
  WebLabel16 := TLabel.Create(Self);
  WebLabel17 := TLabel.Create(Self);
  WebLabel20 := TLabel.Create(Self);
  WebEdit1 := TEdit.Create(Self);
  WebEdit2 := TEdit.Create(Self);
  WebComboBox1 := TComboBox.Create(Self);
  WebEdit3 := TEdit.Create(Self);
  WebEdit4 := TEdit.Create(Self);
  WebEdit5 := TEdit.Create(Self);
  WebButton1 := TButton.Create(Self);
  WebButton2 := TButton.Create(Self);
  WebEdit9 := TEdit.Create(Self);
  WebPanel1 := TPanel.Create(Self);
  WebLabel7 := TLabel.Create(Self);
  WebLabel8 := TLabel.Create(Self);

  UNK_WebLabel_1.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  WebLabel4.BeforeLoadDFMValues;
  WebLabel5.BeforeLoadDFMValues;
  WebLabel6.BeforeLoadDFMValues;
  WebLabel11.BeforeLoadDFMValues;
  WebLabel12.BeforeLoadDFMValues;
  WebLabel13.BeforeLoadDFMValues;
  WebLabel14.BeforeLoadDFMValues;
  WebLabel15.BeforeLoadDFMValues;
  WebLabel16.BeforeLoadDFMValues;
  WebLabel17.BeforeLoadDFMValues;
  WebLabel20.BeforeLoadDFMValues;
  WebEdit1.BeforeLoadDFMValues;
  WebEdit2.BeforeLoadDFMValues;
  WebComboBox1.BeforeLoadDFMValues;
  WebEdit3.BeforeLoadDFMValues;
  WebEdit4.BeforeLoadDFMValues;
  WebEdit5.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  WebEdit9.BeforeLoadDFMValues;
  WebPanel1.BeforeLoadDFMValues;
  WebLabel7.BeforeLoadDFMValues;
  WebLabel8.BeforeLoadDFMValues;
  try
    Name := 'NeuesKonto';
    Left := 0;
    Top := 0;
    Width := 640;
    Height := 500;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -16;
    Font.Name := 'Arial';
    Font.Style := [];
    TabOrder := 1;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    UNK_WebLabel_1.SetParentComponent(Self);
    UNK_WebLabel_1.Name := 'UNK_WebLabel_1';
    UNK_WebLabel_1.Left := 8;
    UNK_WebLabel_1.Top := 206;
    UNK_WebLabel_1.Width := 62;
    UNK_WebLabel_1.Height := 16;
    UNK_WebLabel_1.Caption := 'Nachname';
    UNK_WebLabel_1.Font.Charset := DEFAULT_CHARSET;
    UNK_WebLabel_1.Font.Color := clWindowText;
    UNK_WebLabel_1.Font.Height := -13;
    UNK_WebLabel_1.Font.Name := 'Arial';
    UNK_WebLabel_1.Font.Style := [];
    UNK_WebLabel_1.ParentFont := False;
    WebLabel2.SetParentComponent(Self);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 8;
    WebLabel2.Top := 156;
    WebLabel2.Width := 51;
    WebLabel2.Height := 16;
    WebLabel2.Caption := 'Vorname';
    WebLabel2.Font.Charset := DEFAULT_CHARSET;
    WebLabel2.Font.Color := clWindowText;
    WebLabel2.Font.Height := -13;
    WebLabel2.Font.Name := 'Arial';
    WebLabel2.Font.Style := [];
    WebLabel2.ParentFont := False;
    WebLabel3.SetParentComponent(Self);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Left := 8;
    WebLabel3.Top := 108;
    WebLabel3.Width := 41;
    WebLabel3.Height := 16;
    WebLabel3.Caption := 'Anrede';
    WebLabel3.Font.Charset := DEFAULT_CHARSET;
    WebLabel3.Font.Color := clWindowText;
    WebLabel3.Font.Height := -13;
    WebLabel3.Font.Name := 'Arial';
    WebLabel3.Font.Style := [];
    WebLabel3.ParentFont := False;
    WebLabel4.SetParentComponent(Self);
    WebLabel4.Name := 'WebLabel4';
    WebLabel4.Left := 8;
    WebLabel4.Top := 255;
    WebLabel4.Width := 151;
    WebLabel4.Height := 16;
    WebLabel4.Caption := 'Strasse und Hausnummer';
    WebLabel4.Font.Charset := DEFAULT_CHARSET;
    WebLabel4.Font.Color := clWindowText;
    WebLabel4.Font.Height := -13;
    WebLabel4.Font.Name := 'Arial';
    WebLabel4.Font.Style := [];
    WebLabel4.ParentFont := False;
    WebLabel5.SetParentComponent(Self);
    WebLabel5.Name := 'WebLabel5';
    WebLabel5.Left := 8;
    WebLabel5.Top := 305;
    WebLabel5.Width := 68;
    WebLabel5.Height := 16;
    WebLabel5.Caption := 'Postleitzahl';
    WebLabel5.Font.Charset := DEFAULT_CHARSET;
    WebLabel5.Font.Color := clWindowText;
    WebLabel5.Font.Height := -13;
    WebLabel5.Font.Name := 'Arial';
    WebLabel5.Font.Style := [];
    WebLabel5.ParentFont := False;
    WebLabel6.SetParentComponent(Self);
    WebLabel6.Name := 'WebLabel6';
    WebLabel6.Left := 8;
    WebLabel6.Top := 355;
    WebLabel6.Width := 18;
    WebLabel6.Height := 16;
    WebLabel6.Caption := 'Ort';
    WebLabel6.Font.Charset := DEFAULT_CHARSET;
    WebLabel6.Font.Color := clWindowText;
    WebLabel6.Font.Height := -13;
    WebLabel6.Font.Name := 'Arial';
    WebLabel6.Font.Style := [];
    WebLabel6.ParentFont := False;
    WebLabel11.SetParentComponent(Self);
    WebLabel11.Name := 'WebLabel11';
    WebLabel11.Left := 120;
    WebLabel11.Top := 108;
    WebLabel11.Width := 24;
    WebLabel11.Height := 16;
    WebLabel11.Caption := 'Titel';
    WebLabel11.Font.Charset := DEFAULT_CHARSET;
    WebLabel11.Font.Color := clWindowText;
    WebLabel11.Font.Height := -13;
    WebLabel11.Font.Name := 'Arial';
    WebLabel11.Font.Style := [];
    WebLabel11.ParentFont := False;
    WebLabel12.SetParentComponent(Self);
    WebLabel12.Name := 'WebLabel12';
    WebLabel12.Left := 95;
    WebLabel12.Top := 130;
    WebLabel12.Width := 11;
    WebLabel12.Height := 18;
    WebLabel12.Caption := '*)';
    WebLabel13.SetParentComponent(Self);
    WebLabel13.Name := 'WebLabel13';
    WebLabel13.Left := 268;
    WebLabel13.Top := 225;
    WebLabel13.Width := 11;
    WebLabel13.Height := 18;
    WebLabel13.Caption := '*)';
    WebLabel14.SetParentComponent(Self);
    WebLabel14.Name := 'WebLabel14';
    WebLabel14.Left := 268;
    WebLabel14.Top := 178;
    WebLabel14.Width := 11;
    WebLabel14.Height := 18;
    WebLabel14.Caption := '*)';
    WebLabel15.SetParentComponent(Self);
    WebLabel15.Name := 'WebLabel15';
    WebLabel15.Left := 268;
    WebLabel15.Top := 276;
    WebLabel15.Width := 11;
    WebLabel15.Height := 18;
    WebLabel15.Caption := '*)';
    WebLabel16.SetParentComponent(Self);
    WebLabel16.Name := 'WebLabel16';
    WebLabel16.Left := 109;
    WebLabel16.Top := 327;
    WebLabel16.Width := 11;
    WebLabel16.Height := 18;
    WebLabel16.Caption := '*)';
    WebLabel17.SetParentComponent(Self);
    WebLabel17.Name := 'WebLabel17';
    WebLabel17.Left := 268;
    WebLabel17.Top := 376;
    WebLabel17.Width := 11;
    WebLabel17.Height := 18;
    WebLabel17.Caption := '*)';
    WebLabel20.SetParentComponent(Self);
    WebLabel20.Name := 'WebLabel20';
    WebLabel20.Left := 8;
    WebLabel20.Top := 409;
    WebLabel20.Width := 81;
    WebLabel20.Height := 16;
    WebLabel20.Caption := '*) = Pflichtfeld';
    WebLabel20.Font.Charset := DEFAULT_CHARSET;
    WebLabel20.Font.Color := clWindowText;
    WebLabel20.Font.Height := -13;
    WebLabel20.Font.Name := 'Arial';
    WebLabel20.Font.Style := [];
    WebLabel20.ParentFont := False;
    WebEdit1.SetParentComponent(Self);
    WebEdit1.Name := 'WebEdit1';
    WebEdit1.Left := 8;
    WebEdit1.Top := 225;
    WebEdit1.Width := 249;
    WebEdit1.Height := 22;
    WebEdit1.ChildOrder := 1;
    WebEdit1.ElementClassName := 'form-control';
    WebEdit1.TabOrder := 3;
    WebEdit2.SetParentComponent(Self);
    WebEdit2.Name := 'WebEdit2';
    WebEdit2.Left := 8;
    WebEdit2.Top := 178;
    WebEdit2.Width := 249;
    WebEdit2.Height := 22;
    WebEdit2.ChildOrder := 1;
    WebEdit2.ElementClassName := 'form-control';
    WebEdit2.TabOrder := 2;
    WebComboBox1.SetParentComponent(Self);
    WebComboBox1.Name := 'WebComboBox1';
    WebComboBox1.Left := 8;
    WebComboBox1.Top := 130;
    WebComboBox1.Width := 81;
    WebComboBox1.Height := 22;
    WebComboBox1.Font.Charset := DEFAULT_CHARSET;
    WebComboBox1.Font.Color := clWindowText;
    WebComboBox1.Font.Height := -16;
    WebComboBox1.Font.Name := 'Arial';
    WebComboBox1.Font.Style := [];
    WebComboBox1.ParentFont := False;
    WebComboBox1.ItemIndex := -1;
    WebComboBox1.Items.BeginUpdate;
    try
      WebComboBox1.Items.Clear;
      WebComboBox1.Items.Add('Herr');
      WebComboBox1.Items.Add('Frau');
    finally
      WebComboBox1.Items.EndUpdate;
    end;
    WebEdit3.SetParentComponent(Self);
    WebEdit3.Name := 'WebEdit3';
    WebEdit3.Left := 8;
    WebEdit3.Top := 275;
    WebEdit3.Width := 249;
    WebEdit3.Height := 22;
    WebEdit3.ChildOrder := 1;
    WebEdit3.ElementClassName := 'form-control';
    WebEdit3.TabOrder := 4;
    WebEdit4.SetParentComponent(Self);
    WebEdit4.Name := 'WebEdit4';
    WebEdit4.Left := 8;
    WebEdit4.Top := 325;
    WebEdit4.Width := 87;
    WebEdit4.Height := 22;
    WebEdit4.ChildOrder := 1;
    WebEdit4.ElementClassName := 'form-control';
    WebEdit4.TabOrder := 5;
    WebEdit5.SetParentComponent(Self);
    WebEdit5.Name := 'WebEdit5';
    WebEdit5.Left := 8;
    WebEdit5.Top := 375;
    WebEdit5.Width := 249;
    WebEdit5.Height := 22;
    WebEdit5.ChildOrder := 1;
    WebEdit5.ElementClassName := 'form-control';
    WebEdit5.TabOrder := 6;
    WebButton1.SetParentComponent(Self);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 251;
    WebButton1.Top := 435;
    WebButton1.Width := 96;
    WebButton1.Height := 41;
    WebButton1.Caption := 'Zur'#252'ck';
    WebButton1.ChildOrder := 12;
    WebButton1.ElementClassName := 'btn-secondary';
    WebButton1.TabOrder := 30;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    WebButton2.SetParentComponent(Self);
    WebButton2.Name := 'WebButton2';
    WebButton2.Left := 4;
    WebButton2.Top := 435;
    WebButton2.Width := 241;
    WebButton2.Height := 41;
    WebButton2.Caption := 'Weiter';
    WebButton2.ChildOrder := 13;
    WebButton2.ElementClassName := 'btn-primary';
    WebButton2.TabOrder := 7;
    SetEvent(WebButton2, Self, 'OnClick', 'WebButton2Click');
    WebEdit9.SetParentComponent(Self);
    WebEdit9.Name := 'WebEdit9';
    WebEdit9.Left := 120;
    WebEdit9.Top := 130;
    WebEdit9.Width := 93;
    WebEdit9.Height := 22;
    WebEdit9.ChildOrder := 22;
    WebEdit9.ElementClassName := 'form-control';
    WebEdit9.TabOrder := 1;
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 0;
    WebPanel1.Top := 0;
    WebPanel1.Width := 640;
    WebPanel1.Height := 89;
    WebPanel1.Align := alTop;
    WebPanel1.BorderColor := clSilver;
    WebPanel1.BorderStyle := bsSingle;
    WebPanel1.ChildOrder := 1;
    WebLabel7.SetParentComponent(WebPanel1);
    WebLabel7.Name := 'WebLabel7';
    WebLabel7.Left := 10;
    WebLabel7.Top := 8;
    WebLabel7.Width := 85;
    WebLabel7.Height := 19;
    WebLabel7.Caption := 'WebLabel7';
    WebLabel7.Font.Charset := DEFAULT_CHARSET;
    WebLabel7.Font.Color := clWindowText;
    WebLabel7.Font.Height := -16;
    WebLabel7.Font.Name := 'Arial';
    WebLabel7.Font.Style := [fsBold];
    WebLabel7.ParentFont := False;
    WebLabel8.SetParentComponent(WebPanel1);
    WebLabel8.Name := 'WebLabel8';
    WebLabel8.Left := 16;
    WebLabel8.Top := 56;
    WebLabel8.Width := 271;
    WebLabel8.Height := 18;
    WebLabel8.Caption := 'Neues Kundenkonto: Schritt 1, Adresse';
  finally
    UNK_WebLabel_1.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
    WebLabel4.AfterLoadDFMValues;
    WebLabel5.AfterLoadDFMValues;
    WebLabel6.AfterLoadDFMValues;
    WebLabel11.AfterLoadDFMValues;
    WebLabel12.AfterLoadDFMValues;
    WebLabel13.AfterLoadDFMValues;
    WebLabel14.AfterLoadDFMValues;
    WebLabel15.AfterLoadDFMValues;
    WebLabel16.AfterLoadDFMValues;
    WebLabel17.AfterLoadDFMValues;
    WebLabel20.AfterLoadDFMValues;
    WebEdit1.AfterLoadDFMValues;
    WebEdit2.AfterLoadDFMValues;
    WebComboBox1.AfterLoadDFMValues;
    WebEdit3.AfterLoadDFMValues;
    WebEdit4.AfterLoadDFMValues;
    WebEdit5.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
    WebEdit9.AfterLoadDFMValues;
    WebPanel1.AfterLoadDFMValues;
    WebLabel7.AfterLoadDFMValues;
    WebLabel8.AfterLoadDFMValues;
  end;
end;

end.
