unit USTD_Suche;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.StdCtrls, DB,

  WEBLib.ExtCtrls, WEBLib.DBCtrls,
  XData.Web.Dataset,
  XData.Web.Client,
  UDatamodule,
  UGlobvar, WEBLib.Imaging.pngimage, WEBLib.DB;



type
  TSTD_Suche = class(TForm)
    WebPanel1: TPanel;
    Suchen_WebLabel_1: TLabel;
    Weblabel301: TLabel;
    WebEdit1: TEdit;
    WebPanel4: TPanel;
    WebImageControl1: TImageControl;
    WebImageControl5: TImageControl;
    WebImageControl6: TImageControl;
    WebImageControl7: TImageControl;
    WebImageControl8: TImageControl;
    WebPanel2: TPanel;
    procedure WebFormShow(Sender: TObject);
    procedure WebImageControl8Click(Sender: TObject);
  private
    { Private-Deklarationen }
  protected
    procedure LoadDFMValues; override;
  public
    { Public-Deklarationen }
    Akt_DS : TXDataWebDataSet;
    Akt_Entity : String;
    Akt_FString,
    Akt_FieldString,
    Akt_EFString : String;

    FA : array[1 .. 100] of TEdit;
    LA : array[1 .. 100] of TLabel;
    FA_Cnt : integer;


  end;

var
  STD_Suche: TSTD_Suche;

procedure Standard_Suchen_Form(var DS : TXDataWebDataSet; EntityName,FString,FieldString,EDitString : String);


implementation



procedure Standard_Suchen_Form(var DS : TXDataWebDataSet; EntityName,FString,FieldString,EDitString : String);
var newForm : TSTD_Suche;
    fn,
    dl,
    dt,
    ro,
    PF,
    hst  : String;
    f : TField;
    i,j,
    fcnt : integer;

begin
{
  Newform := TStd_Suche.CreateNew(procedure(AForm: TObject)
    begin
      (AForm as TStd_Suche).Font.Size := 12;
      (AForm as TStd_Suche).Caption := 'Suchen';
//      (AForm as TStd_Suche).WebDataSource1.DataSet := nil;
//      (AForm as TStd_Suche).Akt_DS := TXDataWebDataSet.Create(Application);
//      (AForm as TStd_Suche).Akt_DS.Connection := Datamodule1.XDataWebConnection1;

      (AForm as TStd_Suche).Akt_DS := DS;
      (AForm as TStd_Suche).Akt_Entity := EntityName;
      (AForm as TStd_Suche).Akt_FString := FString;
      (AForm as TStd_Suche).Akt_FieldString := FieldString;
      (AForm as TStd_Suche).Akt_EFString := EditString;

      hst := EditString;
      (AForm as TStd_Suche).FA_Cnt := 0;
      i := 50;
      repeat
        Parse_Edit_Field(hst,fn,dl,j,DT,RO,PF);
        if fn <> '' then begin
          (AForm as TStd_Suche).FA_Cnt := (AForm as TStd_Suche).FA_Cnt +1;

          (AForm as TStd_Suche).FA[(AForm as TStd_Suche).FA_Cnt] := TWebEdit.Create(TComponent(AForm));
          (AForm as TStd_Suche).FA[(AForm as TStd_Suche).FA_Cnt].Name := 'F_'+IntToStr((AForm as TStd_Suche).FA_Cnt);
          (AForm as TStd_Suche).FA[(AForm as TStd_Suche).FA_Cnt].Parent :=(AForm as TStd_Suche).WebPanel2;

          (AForm as TStd_Suche).LA[(AForm as TStd_Suche).FA_Cnt] := TWebLabel.Create(TComponent(AForm));
          (AForm as TStd_Suche).LA[(AForm as TStd_Suche).FA_Cnt].Name := 'L_'+IntToStr((AForm as TStd_Suche).FA_Cnt);
          (AForm as TStd_Suche).LA[(AForm as TStd_Suche).FA_Cnt].Parent :=(AForm as TStd_Suche).WebPanel2;
          (AForm as TStd_Suche).LA[(AForm as TStd_Suche).FA_Cnt].Left := 15;
          (AForm as TStd_Suche).LA[(AForm as TStd_Suche).FA_Cnt].Top := i;
          (AForm as TStd_Suche).LA[(AForm as TStd_Suche).FA_Cnt].Caption := dl;
          i := i + 20;


          (AForm as TStd_Suche).FA[(AForm as TStd_Suche).FA_Cnt].Left := 15;
          (AForm as TStd_Suche).FA[(AForm as TStd_Suche).FA_Cnt].Top := i;
           i := i + 50;
          (AForm as TStd_Suche).FA[(AForm as TStd_Suche).FA_Cnt].Width := j;


        end;
      until hst = '';
//      (AForm as TStd_Suche).WebDataSource1.DataSet := DS;
    end
  );
  Newform.Border := fbSingle;

  Newform.ShowModal(procedure(AValue: TModalResult)
  begin
//      Close;
  end
  );
}

end;





procedure TSTD_Suche.WebFormShow(Sender: TObject);
begin
  WebEdit1.Text := AktKDName;
  Suchen_WebLabel_1.Caption := AktFirmaName;

end;

procedure TSTD_Suche.WebImageControl8Click(Sender: TObject);
begin
  Close;
end;

procedure TSTD_Suche.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebPanel1 := TPanel.Create(Self);
  Suchen_WebLabel_1 := TLabel.Create(Self);
  Weblabel301 := TLabel.Create(Self);
  WebEdit1 := TEdit.Create(Self);
  WebPanel4 := TPanel.Create(Self);
  WebImageControl1 := TImageControl.Create(Self);
  WebImageControl5 := TImageControl.Create(Self);
  WebImageControl6 := TImageControl.Create(Self);
  WebImageControl7 := TImageControl.Create(Self);
  WebImageControl8 := TImageControl.Create(Self);
  WebPanel2 := TPanel.Create(Self);

  WebPanel1.BeforeLoadDFMValues;
  Suchen_WebLabel_1.BeforeLoadDFMValues;
  Weblabel301.BeforeLoadDFMValues;
  WebEdit1.BeforeLoadDFMValues;
  WebPanel4.BeforeLoadDFMValues;
  WebImageControl1.BeforeLoadDFMValues;
  WebImageControl5.BeforeLoadDFMValues;
  WebImageControl6.BeforeLoadDFMValues;
  WebImageControl7.BeforeLoadDFMValues;
  WebImageControl8.BeforeLoadDFMValues;
  WebPanel2.BeforeLoadDFMValues;
  try
    Name := 'STD_Suche';
    Left := 0;
    Top := 0;
    Width := 640;
    Height := 480;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Arial';
    Font.Style := [];
    TabOrder := 1;
    SetEvent(Self, 'OnShow', 'WebFormShow');
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 0;
    WebPanel1.Top := 0;
    WebPanel1.Width := 640;
    WebPanel1.Height := 89;
    WebPanel1.Align := alTop;
    WebPanel1.BorderColor := clSilver;
    WebPanel1.BorderStyle := bsSingle;
    WebPanel1.ChildOrder := 1;
    Suchen_WebLabel_1.SetParentComponent(WebPanel1);
    Suchen_WebLabel_1.Name := 'Suchen_WebLabel_1';
    Suchen_WebLabel_1.Left := 10;
    Suchen_WebLabel_1.Top := 8;
    Suchen_WebLabel_1.Width := 162;
    Suchen_WebLabel_1.Height := 19;
    Suchen_WebLabel_1.Caption := 'Suchen_WebLabel_1';
    Suchen_WebLabel_1.Font.Charset := DEFAULT_CHARSET;
    Suchen_WebLabel_1.Font.Color := clWindowText;
    Suchen_WebLabel_1.Font.Height := -16;
    Suchen_WebLabel_1.Font.Name := 'Arial';
    Suchen_WebLabel_1.Font.Style := [fsBold];
    Suchen_WebLabel_1.ParentFont := False;
    Weblabel301.SetParentComponent(WebPanel1);
    Weblabel301.Name := 'Weblabel301';
    Weblabel301.Left := 8;
    Weblabel301.Top := 45;
    Weblabel301.Width := 47;
    Weblabel301.Height := 14;
    Weblabel301.Caption := 'Benutzer:';
    Weblabel301.Color := clBtnFace;
    WebEdit1.SetParentComponent(WebPanel1);
    WebEdit1.Name := 'WebEdit1';
    WebEdit1.Left := 162;
    WebEdit1.Top := 42;
    WebEdit1.Width := 248;
    WebEdit1.Height := 22;
    WebEdit1.TabStop := False;
    WebEdit1.ChildOrder := 7;
    WebEdit1.ElementClassName := 'form-control';
    WebEdit1.ReadOnly := True;
    WebEdit1.Text := 'WebEdit1';
    WebPanel4.SetParentComponent(Self);
    WebPanel4.Name := 'WebPanel4';
    WebPanel4.Left := 0;
    WebPanel4.Top := 89;
    WebPanel4.Width := 640;
    WebPanel4.Height := 33;
    WebPanel4.Align := alTop;
    WebPanel4.BorderColor := clSilver;
    WebPanel4.BorderStyle := bsSingle;
    WebPanel4.ChildOrder := 4;
    WebImageControl1.SetParentComponent(WebPanel4);
    WebImageControl1.Name := 'WebImageControl1';
    WebImageControl1.Left := 0;
    WebImageControl1.Top := 0;
    WebImageControl1.Width := 33;
    WebImageControl1.Height := 33;
    WebImageControl1.Align := alLeft;
    WebImageControl1.Picture.LoadFromFile('USTD_Suche.WebPanel4.WebImageControl1.Picture.png');
    WebImageControl5.SetParentComponent(WebPanel4);
    WebImageControl5.Name := 'WebImageControl5';
    WebImageControl5.Left := 33;
    WebImageControl5.Top := 0;
    WebImageControl5.Width := 33;
    WebImageControl5.Height := 33;
    WebImageControl5.Align := alLeft;
    WebImageControl5.ChildOrder := 4;
    WebImageControl6.SetParentComponent(WebPanel4);
    WebImageControl6.Name := 'WebImageControl6';
    WebImageControl6.Left := 66;
    WebImageControl6.Top := 0;
    WebImageControl6.Width := 33;
    WebImageControl6.Height := 33;
    WebImageControl6.Align := alLeft;
    WebImageControl6.ChildOrder := 5;
    WebImageControl7.SetParentComponent(WebPanel4);
    WebImageControl7.Name := 'WebImageControl7';
    WebImageControl7.Left := 99;
    WebImageControl7.Top := 0;
    WebImageControl7.Width := 33;
    WebImageControl7.Height := 33;
    WebImageControl7.Align := alLeft;
    WebImageControl7.ChildOrder := 6;
    WebImageControl8.SetParentComponent(WebPanel4);
    WebImageControl8.Name := 'WebImageControl8';
    WebImageControl8.Left := 132;
    WebImageControl8.Top := 0;
    WebImageControl8.Width := 33;
    WebImageControl8.Height := 33;
    WebImageControl8.Align := alLeft;
    WebImageControl8.ChildOrder := 7;
    SetEvent(WebImageControl8, Self, 'OnClick', 'WebImageControl8Click');
    WebImageControl8.Picture.LoadFromFile('USTD_Suche.WebPanel4.WebImageControl8.Picture.png');
    WebPanel2.SetParentComponent(Self);
    WebPanel2.Name := 'WebPanel2';
    WebPanel2.Left := 0;
    WebPanel2.Top := 122;
    WebPanel2.Width := 640;
    WebPanel2.Height := 358;
    WebPanel2.Align := alClient;
    WebPanel2.BorderColor := clSilver;
    WebPanel2.BorderStyle := bsSingle;
    WebPanel2.ChildOrder := 2;
  finally
    WebPanel1.AfterLoadDFMValues;
    Suchen_WebLabel_1.AfterLoadDFMValues;
    Weblabel301.AfterLoadDFMValues;
    WebEdit1.AfterLoadDFMValues;
    WebPanel4.AfterLoadDFMValues;
    WebImageControl1.AfterLoadDFMValues;
    WebImageControl5.AfterLoadDFMValues;
    WebImageControl6.AfterLoadDFMValues;
    WebImageControl7.AfterLoadDFMValues;
    WebImageControl8.AfterLoadDFMValues;
    WebPanel2.AfterLoadDFMValues;
  end;
end;

end.