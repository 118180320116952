unit UEditTelefon;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.StdCtrls, WEBLib.DBCtrls,
  WEBLib.ExtCtrls,
  UDatamodule,
  UGlobvar;

type
  TEditTelefon = class(TForm)
    WebPanel1: TPanel;
    WebLabel4: TLabel;
    WebDBEdit3: TDBEdit;
    WebLabel2: TLabel;
    WebDBEdit2: TDBEdit;
    WebPanel2: TPanel;
    WebButton2: TButton;
    WebButton1: TButton;
    procedure WebFormShow(Sender: TObject);
    procedure WebButton1Click(Sender: TObject);
    procedure WebButton2Click(Sender: TObject);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
    procedure WebFormCreate(Sender: TObject);
    procedure WebFormResize(Sender: TObject);
  private
    { Private-Deklarationen }
  protected
    procedure LoadDFMValues; override;
  public
    { Public-Deklarationen }

    Zeilen_Liste : TStringList;
    Zeilen_Count : integer;
    Form_Aktiv : boolean;

  end;

var
  EditTelefon: TEditTelefon;


procedure Do_Edit_Telefon;



implementation




procedure Do_Edit_Telefon;
var
  newform: TEditTelefon;
begin

//  if Create_Form(TEditTelefon,newForm) then ;

  newform := TEditTelefon.CreateNew(procedure(AForm: TObject)
    begin
      (AForm as TEditTelefon).Caption := 'Konto bearbeiten';
    end
  );
//  newForm.Caption := 'Neues Konto';
  newform.Border := fbSingle;

  newform.ShowModal(procedure(AValue: TModalResult)
  begin
//
  end
  );



end;






procedure TEditTelefon.WebButton1Click(Sender: TObject);
begin
  DataModule1.KundenDataSet.Cancel;
  Close;

end;

procedure TEditTelefon.WebButton2Click(Sender: TObject);
begin
  try
    DataModule1.KundenDataSet.Post;
    DataModule1.KundenDataSet.ApplyUpdates;
  except
    MessageDlg('Fehler beim Speichern', mtError, [], nil);
    exit;
  end;
  Close;
end;

procedure TEditTelefon.WebFormClose(Sender: TObject; var Action: TCloseAction);
begin
  Zeilen_Liste.Clear;
  Zeilen_Liste.Free;

end;

procedure TEditTelefon.WebFormCreate(Sender: TObject);
begin
  Form_Aktiv := false;
end;

procedure TEditTelefon.WebFormResize(Sender: TObject);
begin
  if not Form_Aktiv then exit;
  Resize_Edit_Controls(WF_Edit,self,Zeilen_Liste,WebPanel1,WebPanel2);

end;

procedure TEditTelefon.WebFormShow(Sender: TObject);
begin
  DataModule1.KundenDataSet.Edit;
  Resize_Top_Panel(self,WebPanel1);
  Resize_Bottom_Panel(self,WebPanel2);
  Zeilen_Liste := TStringList.Create;
  Zeilen_Count := 0;
  Zeilen_Liste_Add(Zeilen_Liste,Zeilen_Count,WebLabel4,WebDBEdit3,nil);
  Zeilen_Liste_Add(Zeilen_Liste,Zeilen_Count,WebLabel2,WebDBEdit2,nil);
  Form_Aktiv := true;

  Resize_Edit_Controls(WF_Edit,self,Zeilen_Liste,WebPanel1,WebPanel2);

end;

procedure TEditTelefon.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebLabel4 := TLabel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  WebPanel1 := TPanel.Create(Self);
  WebDBEdit3 := TDBEdit.Create(Self);
  WebDBEdit2 := TDBEdit.Create(Self);
  WebPanel2 := TPanel.Create(Self);
  WebButton2 := TButton.Create(Self);
  WebButton1 := TButton.Create(Self);

  WebLabel4.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  WebPanel1.BeforeLoadDFMValues;
  WebDBEdit3.BeforeLoadDFMValues;
  WebDBEdit2.BeforeLoadDFMValues;
  WebPanel2.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  try
    Name := 'EditTelefon';
    Left := 0;
    Top := 0;
    Width := 640;
    Height := 480;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Arial';
    Font.Style := [];
    TabOrder := 1;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnResize', 'WebFormResize');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    WebLabel4.SetParentComponent(Self);
    WebLabel4.Name := 'WebLabel4';
    WebLabel4.Left := 10;
    WebLabel4.Top := 104;
    WebLabel4.Width := 142;
    WebLabel4.Height := 16;
    WebLabel4.Caption := 'Telefonnummer Festnetz';
    WebLabel4.Color := clBtnFace;
    WebLabel4.Font.Charset := DEFAULT_CHARSET;
    WebLabel4.Font.Color := clWindowText;
    WebLabel4.Font.Height := -13;
    WebLabel4.Font.Name := 'Arial';
    WebLabel4.Font.Style := [];
    WebLabel4.ParentFont := False;
    WebLabel2.SetParentComponent(Self);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 8;
    WebLabel2.Top := 162;
    WebLabel2.Width := 122;
    WebLabel2.Height := 16;
    WebLabel2.Caption := 'Telefonnummer Mobil';
    WebLabel2.Color := clBtnFace;
    WebLabel2.Font.Charset := DEFAULT_CHARSET;
    WebLabel2.Font.Color := clWindowText;
    WebLabel2.Font.Height := -13;
    WebLabel2.Font.Name := 'Arial';
    WebLabel2.Font.Style := [];
    WebLabel2.ParentFont := False;
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 0;
    WebPanel1.Top := 0;
    WebPanel1.Width := 640;
    WebPanel1.Height := 89;
    WebPanel1.Align := alTop;
    WebPanel1.BorderColor := clSilver;
    WebPanel1.BorderStyle := bsSingle;
    WebPanel1.ChildOrder := 1;
    WebDBEdit3.SetParentComponent(Self);
    WebDBEdit3.Name := 'WebDBEdit3';
    WebDBEdit3.Left := 10;
    WebDBEdit3.Top := 126;
    WebDBEdit3.Width := 248;
    WebDBEdit3.Height := 22;
    WebDBEdit3.ChildOrder := 28;
    WebDBEdit3.ElementClassName := 'form-control';
    WebDBEdit3.TabOrder := 2;
    WebDBEdit3.Text := 'WebDBEdit1';
    WebDBEdit3.DataField := 'TELEFON';
    WebDBEdit3.DataSource := DataModule1.KundenDataSource;
    WebDBEdit2.SetParentComponent(Self);
    WebDBEdit2.Name := 'WebDBEdit2';
    WebDBEdit2.Left := 8;
    WebDBEdit2.Top := 184;
    WebDBEdit2.Width := 248;
    WebDBEdit2.Height := 22;
    WebDBEdit2.ChildOrder := 28;
    WebDBEdit2.ElementClassName := 'form-control';
    WebDBEdit2.TabOrder := 3;
    WebDBEdit2.Text := 'WebDBEdit1';
    WebDBEdit2.DataField := 'HANDY';
    WebDBEdit2.DataSource := DataModule1.KundenDataSource;
    WebPanel2.SetParentComponent(Self);
    WebPanel2.Name := 'WebPanel2';
    WebPanel2.Left := 0;
    WebPanel2.Top := 420;
    WebPanel2.Width := 640;
    WebPanel2.Height := 60;
    WebPanel2.Align := alBottom;
    WebPanel2.BorderColor := clSilver;
    WebPanel2.BorderStyle := bsSingle;
    WebPanel2.ChildOrder := 5;
    WebButton2.SetParentComponent(WebPanel2);
    WebButton2.Name := 'WebButton2';
    WebButton2.Left := 12;
    WebButton2.Top := 12;
    WebButton2.Width := 177;
    WebButton2.Height := 41;
    WebButton2.Caption := #196'nderungen speichern';
    WebButton2.ChildOrder := 13;
    WebButton2.ElementClassName := 'btn-primary';
    WebButton2.TabOrder := 7;
    SetEvent(WebButton2, Self, 'OnClick', 'WebButton2Click');
    WebButton1.SetParentComponent(WebPanel2);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 195;
    WebButton1.Top := 12;
    WebButton1.Width := 96;
    WebButton1.Height := 41;
    WebButton1.Caption := 'Zur'#252'ck';
    WebButton1.ChildOrder := 6;
    WebButton1.ElementClassName := 'btn-primary';
    WebButton1.TabOrder := 8;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
  finally
    WebLabel4.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    WebPanel1.AfterLoadDFMValues;
    WebDBEdit3.AfterLoadDFMValues;
    WebDBEdit2.AfterLoadDFMValues;
    WebPanel2.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
  end;
end;

end.