unit UKontoMenu;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.StdCtrls, DB,

  UDatamodule,
  UGlobvar,
  UEditKonto4,
  UEditTelefon,
  UEditBank,
  UEditPasswort,
  UChangeEMail,
  UTeilnehmer, WEBLib.ExtCtrls,
  UEditSichFragen;

type
  TKontoMenu = class(TForm)
    WebButton1: TButton;
    WebButton2: TButton;
    WebButton3: TButton;
    WebButton4: TButton;
    WebButton5: TButton;
    WebButton7: TButton;
    WebButton8: TButton;
    WebPanel1: TPanel;
    WebButton6: TButton;
    procedure WebButton1Click(Sender: TObject);
    procedure WebButton7Click(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
    procedure WebButton2Click(Sender: TObject);
    procedure WebButton5Click(Sender: TObject);
    procedure WebButton4Click(Sender: TObject);
    procedure WebButton3Click(Sender: TObject);
    procedure Zeige_Teilnehmer(DataSet: TDataSet);
    procedure Enable_Teilnehmer(DataSet: TDataSet);
    procedure WebButton6Click(Sender: TObject);
    procedure WebButton8Click(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
    procedure WebFormResize(Sender: TObject);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
    procedure Zeige_Teilnehmer_2(DataSet: TDataSet);
  private
    { Private-Deklarationen }
  protected
    procedure LoadDFMValues; override;
  public
    { Public-Deklarationen }

    Zeilen_Liste : TStringList;
    Zeilen_Count : integer;
    Form_Aktiv : boolean;

  end;

var
  KontoMenu: TKontoMenu;

implementation


procedure TKontoMenu.WebButton1Click(Sender: TObject);
var
  newform: TEditKonto4;
begin
//  if Create_Form(TEditKonto4,NewForm) then ;


  newform := TEditKonto4.CreateNew(procedure(AForm: TObject)
    begin
      (AForm as TEditKonto4).Caption := 'Konto bearbeiten';
    end
  );
//  newForm.Caption := 'Neues Konto';
  newform.Border := fbSingle;

  newform.ShowModal(procedure(AValue: TModalResult)
  begin
//
  end
  );

end;

procedure TKontoMenu.WebButton2Click(Sender: TObject);
var
  newform: TEditBank;
begin
//  if Create_Form(TEditBank,newForm) then ;



  newform := TEditBank.CreateNew(procedure(AForm: TObject)
    begin
      (AForm as TEditBank).Caption := 'Konto bearbeiten';
    end
  );
//  newForm.Caption := 'Neues Konto';
  newform.Border := fbSingle;

  newform.ShowModal(procedure(AValue: TModalResult)
  begin
//
  end
  );


end;


procedure TKontoMenu.Zeige_Teilnehmer(DataSet: TDataSet);
var Newform: TTeilnehmer;
    i : integer;
begin
//  i := Datamodule1.TeilnehmerDataSet.ServerRecordCount;

//  if Create_Form(TTeilnehmer,NewForm) then ;

    Newform := TTeilnehmer.CreateNew(procedure(AForm: TObject)
      begin
        (AForm as TTeilnehmer).Caption := 'Teilnehmer';
      end
    );
    Newform.Border := fbSingle;

    Newform.ShowModal(procedure(AValue: TModalResult)
    begin
//      Close;
    end
    );

end;



procedure TKontoMenu.WebButton3Click(Sender: TObject);
var Newform: TTeilnehmer;
begin
  Datamodule1.TeilnehmerDataSet.Close;
  Datamodule1.TeilnehmerDataSet.AfterOpen := Zeige_Teilnehmer_2;
  Datamodule1.TeilnehmerDataSet.QueryString :='$filter=NL_Key eq '+IntToStr(AktFirmaNo)+' AND KDID eq '
      + Datamodule1.KundenDataSet.Fieldbyname('ID').AsString;
  Datamodule1.TeilnehmerDataSet.Load;

end;

procedure TKontoMenu.WebButton4Click(Sender: TObject);
var
  newform: TChangeEmail;
begin
//  if Create_Form(TChangeEmail,newForm) then ;
  Email_Geaendert := false;
  newform := TChangeEmail.CreateNew(procedure(AForm: TObject)
    begin
      (AForm as TChangeEmail).Caption := 'Konto bearbeiten';
    end
  );
//  newForm.Caption := 'Neues Konto';
  newform.Border := fbSingle;

  newform.ShowModal(procedure(AValue: TModalResult)
  begin
    if Email_Geaendert then begin
      Close;
    end;
  end
  );

end;

procedure TKontoMenu.WebButton5Click(Sender: TObject);
var
  newform: TEditPasswort;
begin

//  if Create_Form(TEditPasswort,newForm) then ;


  newform := TEditPasswort.CreateNew(procedure(AForm: TObject)
    begin
      (AForm as TEditPasswort).Caption := 'Konto bearbeiten';
    end
  );
//  newForm.Caption := 'Neues Konto';
  newform.Border := fbSingle;

  newform.ShowModal(procedure(AValue: TModalResult)
  begin
//
  end
  );


end;

procedure TKontoMenu.WebButton6Click(Sender: TObject);
var
  newform: TEditTelefon;
begin

//  if Create_Form(TEditTelefon,NewForm) then ;


  newform := TEditTelefon.CreateNew(procedure(AForm: TObject)
    begin
      (AForm as TEditTelefon).Caption := 'Konto bearbeiten';
    end
  );
//  newForm.Caption := 'Neues Konto';
  newform.Border := fbSingle;

  newform.ShowModal(procedure(AValue: TModalResult)
  begin
//
  end
  );

end;

procedure TKontoMenu.WebButton7Click(Sender: TObject);
begin
  Close;
end;

procedure TKontoMenu.WebButton8Click(Sender: TObject);
var newForm : TEditSichFragen;
begin
  SichFragen_Modus := 1;

  newform := TEditSichFragen.CreateNew(procedure(AForm: TObject)
    begin
      (AForm as TEditSichFragen).Caption := 'Konto bearbeiten';
    end
  );
//  newForm.Caption := 'Neues Konto';
  newform.Border := fbSingle;

  newform.ShowModal(procedure(AValue: TModalResult)
  begin
    if Neues_Konto_Erstellt then begin
      Close;
    end;
  end
  );

end;

procedure TKontoMenu.WebFormClose(Sender: TObject; var Action: TCloseAction);
begin
  Zeilen_Liste.Clear;
  Zeilen_Liste.Free;

end;

procedure TKontoMenu.WebFormCreate(Sender: TObject);
begin
  Form_Aktiv := false;
end;

procedure TKontoMenu.WebFormResize(Sender: TObject);
begin
  if not Form_Aktiv then exit;
  Resize_Edit_Controls(WF_Menu,self,Zeilen_Liste,WebPanel1,nil);

end;

procedure TKontoMenu.WebFormShow(Sender: TObject);
begin

  Resize_Top_Panel(self,WebPanel1);
  Zeilen_Liste := TStringList.Create;
  Zeilen_Count := 0;
  Resize_Edit_Controls(WF_Menu,self,Zeilen_Liste,WebPanel1,nil);
  Form_Aktiv := true;


//  WebButton3.Visible := false;


{
  KundenDataSet_Mode := KDDataset_FindKey;
  Datamodule1.XDataWebConnection1.Connected := true;
  DataModule1.TeilnehmerDataset.Active := false;
  DataModule1.TeilnehmerDataset.AfterOpen := Enable_Teilnehmer;
  DataModule1.TeilnehmerDataset.QueryString := '$filter=(NL_Key eq '+IntToStr(AktFirmaNo)+') AND (KDID eq '
    + DataModule1.KundenDataset.FieldByName('ID').AsString+')';
  DataModule1.TeilnehmerDataset.Load;
}
end;


procedure TKontoMenu.Enable_Teilnehmer(DataSet: TDataSet);
begin
//  WebButton3.Visible := true;
end;


procedure TKontoMenu.Zeige_Teilnehmer_2(DataSet: TDataSet);
var Newform: TTeilnehmer;
    i : integer;
begin


    Newform := TTeilnehmer.CreateNew(procedure(AForm: TObject)
      begin
        (AForm as TTeilnehmer).Caption := 'Teilnehmer';
      end
    );
    Newform.Border := fbSingle;

    Newform.ShowModal(procedure(AValue: TModalResult)
    begin
//      Close;
    end
    );

end;





procedure TKontoMenu.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebButton1 := TButton.Create(Self);
  WebButton2 := TButton.Create(Self);
  WebButton3 := TButton.Create(Self);
  WebButton4 := TButton.Create(Self);
  WebButton5 := TButton.Create(Self);
  WebButton7 := TButton.Create(Self);
  WebButton8 := TButton.Create(Self);
  WebPanel1 := TPanel.Create(Self);
  WebButton6 := TButton.Create(Self);

  WebButton1.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  WebButton3.BeforeLoadDFMValues;
  WebButton4.BeforeLoadDFMValues;
  WebButton5.BeforeLoadDFMValues;
  WebButton7.BeforeLoadDFMValues;
  WebButton8.BeforeLoadDFMValues;
  WebPanel1.BeforeLoadDFMValues;
  WebButton6.BeforeLoadDFMValues;
  try
    Name := 'KontoMenu';
    Left := 0;
    Top := 0;
    Width := 322;
    Height := 560;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -16;
    Font.Name := 'Arial';
    Font.Style := [];
    TabOrder := 1;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnResize', 'WebFormResize');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    WebButton1.SetParentComponent(Self);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 24;
    WebButton1.Top := 95;
    WebButton1.Width := 273;
    WebButton1.Height := 51;
    WebButton1.Caption := 'Adresse erfassen/'#228'ndern';
    WebButton1.ChildOrder := 3;
    WebButton1.ElementClassName := 'btn-secondary';
    WebButton1.Font.Charset := DEFAULT_CHARSET;
    WebButton1.Font.Color := clWindowText;
    WebButton1.Font.Height := -16;
    WebButton1.Font.Name := 'Arial';
    WebButton1.Font.Style := [];
    WebButton1.ParentFont := False;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    WebButton2.SetParentComponent(Self);
    WebButton2.Name := 'WebButton2';
    WebButton2.Left := 24;
    WebButton2.Top := 211;
    WebButton2.Width := 273;
    WebButton2.Height := 51;
    WebButton2.Caption := 'Bankverbindung erfassen/'#228'ndern';
    WebButton2.ChildOrder := 1;
    WebButton2.ElementClassName := 'btn-secondary';
    WebButton2.Font.Charset := DEFAULT_CHARSET;
    WebButton2.Font.Color := clWindowText;
    WebButton2.Font.Height := -16;
    WebButton2.Font.Name := 'Arial';
    WebButton2.Font.Style := [];
    WebButton2.ParentFont := False;
    SetEvent(WebButton2, Self, 'OnClick', 'WebButton2Click');
    WebButton3.SetParentComponent(Self);
    WebButton3.Name := 'WebButton3';
    WebButton3.Left := 24;
    WebButton3.Top := 269;
    WebButton3.Width := 273;
    WebButton3.Height := 51;
    WebButton3.Caption := 'Essensteilnehmer/Kinder';
    WebButton3.ChildOrder := 2;
    WebButton3.ElementClassName := 'btn-secondary';
    WebButton3.Font.Charset := DEFAULT_CHARSET;
    WebButton3.Font.Color := clWindowText;
    WebButton3.Font.Height := -16;
    WebButton3.Font.Name := 'Arial';
    WebButton3.Font.Style := [];
    WebButton3.ParentFont := False;
    SetEvent(WebButton3, Self, 'OnClick', 'WebButton3Click');
    WebButton4.SetParentComponent(Self);
    WebButton4.Name := 'WebButton4';
    WebButton4.Left := 24;
    WebButton4.Top := 327;
    WebButton4.Width := 273;
    WebButton4.Height := 51;
    WebButton4.Caption := 'eMail-Adresse '#228'ndern';
    WebButton4.ChildOrder := 3;
    WebButton4.ElementClassName := 'btn-secondary';
    WebButton4.Font.Charset := DEFAULT_CHARSET;
    WebButton4.Font.Color := clWindowText;
    WebButton4.Font.Height := -16;
    WebButton4.Font.Name := 'Arial';
    WebButton4.Font.Style := [];
    WebButton4.ParentFont := False;
    SetEvent(WebButton4, Self, 'OnClick', 'WebButton4Click');
    WebButton5.SetParentComponent(Self);
    WebButton5.Name := 'WebButton5';
    WebButton5.Left := 24;
    WebButton5.Top := 385;
    WebButton5.Width := 273;
    WebButton5.Height := 51;
    WebButton5.Caption := 'Kennwort '#228'ndern';
    WebButton5.ChildOrder := 4;
    WebButton5.ElementClassName := 'btn-secondary';
    WebButton5.Font.Charset := DEFAULT_CHARSET;
    WebButton5.Font.Color := clWindowText;
    WebButton5.Font.Height := -16;
    WebButton5.Font.Name := 'Arial';
    WebButton5.Font.Style := [];
    WebButton5.ParentFont := False;
    SetEvent(WebButton5, Self, 'OnClick', 'WebButton5Click');
    WebButton7.SetParentComponent(Self);
    WebButton7.Name := 'WebButton7';
    WebButton7.Left := 24;
    WebButton7.Top := 502;
    WebButton7.Width := 273;
    WebButton7.Height := 51;
    WebButton7.Caption := 'Zur'#252'ck';
    WebButton7.ChildOrder := 5;
    WebButton7.ElementClassName := 'btn-primary';
    WebButton7.Font.Charset := DEFAULT_CHARSET;
    WebButton7.Font.Color := clWindowText;
    WebButton7.Font.Height := -16;
    WebButton7.Font.Name := 'Arial';
    WebButton7.Font.Style := [];
    WebButton7.ParentFont := False;
    SetEvent(WebButton7, Self, 'OnClick', 'WebButton7Click');
    WebButton8.SetParentComponent(Self);
    WebButton8.Name := 'WebButton8';
    WebButton8.Left := 24;
    WebButton8.Top := 443;
    WebButton8.Width := 273;
    WebButton8.Height := 51;
    WebButton8.Caption := 'Sicherheitsfragen';
    WebButton8.ChildOrder := 5;
    WebButton8.ElementClassName := 'btn-secondary';
    WebButton8.Font.Charset := DEFAULT_CHARSET;
    WebButton8.Font.Color := clWindowText;
    WebButton8.Font.Height := -16;
    WebButton8.Font.Name := 'Arial';
    WebButton8.Font.Style := [];
    WebButton8.ParentFont := False;
    SetEvent(WebButton8, Self, 'OnClick', 'WebButton8Click');
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 0;
    WebPanel1.Top := 0;
    WebPanel1.Width := 322;
    WebPanel1.Height := 89;
    WebPanel1.Align := alTop;
    WebPanel1.BorderColor := clSilver;
    WebPanel1.BorderStyle := bsSingle;
    WebPanel1.ChildOrder := 1;
    WebButton6.SetParentComponent(Self);
    WebButton6.Name := 'WebButton6';
    WebButton6.Left := 24;
    WebButton6.Top := 153;
    WebButton6.Width := 273;
    WebButton6.Height := 51;
    WebButton6.Caption := 'Telefon erfassen/'#228'ndern (freiwillig)';
    WebButton6.ChildOrder := 3;
    WebButton6.ElementClassName := 'btn-secondary';
    WebButton6.Font.Charset := DEFAULT_CHARSET;
    WebButton6.Font.Color := clWindowText;
    WebButton6.Font.Height := -16;
    WebButton6.Font.Name := 'Arial';
    WebButton6.Font.Style := [];
    WebButton6.ParentFont := False;
    SetEvent(WebButton6, Self, 'OnClick', 'WebButton6Click');
  finally
    WebButton1.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
    WebButton3.AfterLoadDFMValues;
    WebButton4.AfterLoadDFMValues;
    WebButton5.AfterLoadDFMValues;
    WebButton7.AfterLoadDFMValues;
    WebButton8.AfterLoadDFMValues;
    WebPanel1.AfterLoadDFMValues;
    WebButton6.AfterLoadDFMValues;
  end;
end;

end.