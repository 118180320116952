unit UAnzArtBilder;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.StdCtrls, WEBLib.DBCtrls,
  UDatamodule,
  UGlobvar, WEBLib.ExtCtrls, WEBLib.Slider;

type
  TAnzArtBilder = class(TForm)
    WebPanel1: TPanel;
    WebPanel2: TPanel;
    WebButton1: TButton;
    ImageSlider: TImageSlider;
    procedure WebFormShow(Sender: TObject);
    procedure WebButton1Click(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
    procedure WebFormResize(Sender: TObject);
  private
    { Private-Deklarationen }
  protected
    procedure LoadDFMValues; override;
  public
    { Public-Deklarationen }
    Zeilen_Liste : TStringList;
    Zeilen_Count : integer;
    Form_Aktiv : boolean;

  end;

var
  AnzArtBilder: TAnzArtBilder;

implementation


procedure TAnzArtBilder.WebButton1Click(Sender: TObject);
begin
  Close;

end;

procedure TAnzArtBilder.WebFormClose(Sender: TObject; var Action: TCloseAction);
begin
  Zeilen_Liste.Clear;
  Zeilen_Liste.Free;

end;

procedure TAnzArtBilder.WebFormCreate(Sender: TObject);
begin
  Form_Aktiv := false;

  ImageSlider.Color := clBlack;
  ImageSlider.Appearance.Buttons.Color := clWhite;


end;

procedure TAnzArtBilder.WebFormResize(Sender: TObject);
begin
  if not Form_Aktiv then exit;
  Resize_Edit_Controls(WF_Edit,self,Zeilen_Liste,WebPanel1,WebPanel2);

end;

procedure TAnzArtBilder.WebFormShow(Sender: TObject);
var i : integer;
begin
  Resize_Top_Panel_2(self,WebPanel1,'Artikel: ' + DataModule1.ArtikelDataSet.Fieldbyname('BezGesamt').AsString);
  Resize_Bottom_Panel(self,WebPanel2);
  Zeilen_Liste := TStringList.Create;
  Zeilen_Count := 0;



  Form_Aktiv := true;

  Resize_Edit_Controls(WF_Edit,self,Zeilen_Liste,WebPanel1,WebPanel2);



  ImageSlider.ImageURLs.Clear;
  for i := 1 to 12 do begin
    ImageSlider.ImageURLs.add(Format('./images/10/1/nature-%d.jpg', [i]));
  end;
  ImageSlider.RefreshImages;


end;

procedure TAnzArtBilder.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebPanel1 := TPanel.Create(Self);
  WebPanel2 := TPanel.Create(Self);
  WebButton1 := TButton.Create(Self);
  ImageSlider := TImageSlider.Create(Self);

  WebPanel1.BeforeLoadDFMValues;
  WebPanel2.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  ImageSlider.BeforeLoadDFMValues;
  try
    Name := 'AnzArtBilder';
    Left := 0;
    Top := 0;
    Width := 640;
    Height := 480;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -16;
    Font.Name := 'Arial';
    Font.Style := [];
    TabOrder := 1;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnResize', 'WebFormResize');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 0;
    WebPanel1.Top := 0;
    WebPanel1.Width := 640;
    WebPanel1.Height := 129;
    WebPanel1.Align := alTop;
    WebPanel1.BorderColor := clSilver;
    WebPanel1.BorderStyle := bsSingle;
    WebPanel1.ChildOrder := 1;
    WebPanel2.SetParentComponent(Self);
    WebPanel2.Name := 'WebPanel2';
    WebPanel2.Left := 0;
    WebPanel2.Top := 420;
    WebPanel2.Width := 640;
    WebPanel2.Height := 60;
    WebPanel2.Align := alBottom;
    WebPanel2.BorderColor := clSilver;
    WebPanel2.BorderStyle := bsSingle;
    WebPanel2.ChildOrder := 2;
    WebButton1.SetParentComponent(WebPanel2);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 191;
    WebButton1.Top := 6;
    WebButton1.Width := 90;
    WebButton1.Height := 41;
    WebButton1.Caption := 'Zur'#252'ck';
    WebButton1.ChildOrder := 14;
    WebButton1.ElementClassName := 'btn-primary';
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    ImageSlider.SetParentComponent(Self);
    ImageSlider.Name := 'ImageSlider';
    ImageSlider.Left := 0;
    ImageSlider.Top := 129;
    ImageSlider.Width := 640;
    ImageSlider.Height := 291;
    ImageSlider.ImageURLs.BeginUpdate;
    try
      ImageSlider.ImageURLs.Clear;
      ImageSlider.ImageURLs.Add('./images/10/1/nature-1.jpg');
      ImageSlider.ImageURLs.Add('./images/10/1/nature-2.jpg');
    finally
      ImageSlider.ImageURLs.EndUpdate;
    end;
    ImageSlider.Appearance.NavigationStyle := navsThumbnails;
    ImageSlider.Appearance.TransitionEffect := tefSlide;
    ImageSlider.Appearance.Bullets.Opacity := 1.000000000000000000;
    ImageSlider.Appearance.Thumbnails.NumDisplayed := 8;
    ImageSlider.Appearance.Thumbnails.Opacity := 0.500000000000000000;
    ImageSlider.Appearance.Thumbnails.OpacityActive := 1.000000000000000000;
    ImageSlider.Align := alClient;
    ImageSlider.Color := clWhite;
  finally
    WebPanel1.AfterLoadDFMValues;
    WebPanel2.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    ImageSlider.AfterLoadDFMValues;
  end;
end;

end.