unit App.Config;

interface

uses
  JS,
  XData.Web.Connection,
  XData.Web.Request,
  XData.Web.Response,
  WEBLib.Dialogs,
 WEBLib.Storage,
  UGlobvar;

type
  TAppConfig = class
  private
    FAuthUrl: string;
    FApiUrl: string;
    FFirmaNo : integer;
    FFirmaName : String;
    FLogo : String;
    FPrgName : String;

  public
    constructor Create;
    property AuthUrl: string read FAuthUrl write FAuthUrl;
    property ApiUrl: string read FApiUrl write FApiUrl;
    property FirmaNo : integer read FFirmaNo write FFirmaNo;
    property FirmaName : String read FFirmaName write FFirmaName;
    property Logo : String read FLogo write FLogo;
    property PrgName : String read FPrgName write FPrgName;

  end;

  TConfigLoadedProc = reference to procedure(Config: TAppConfig);

  procedure LoadConfig(LoadProc: TConfigLoadedProc);

procedure Init_Prog;


implementation




 procedure ConfigLoaded(Config: TAppConfig);
  begin
    if Config.AuthUrl <> '' then AUTH_URL := Config.AuthUrl;
    if Config.ApiUrl <> '' then API_URL := Config.ApiUrl;
    if Config.Logo <> '' then LogoURL := Config.Logo;
    if Config.FirmaNo <> 0 then AktFirmaNo := Config.FirmaNo;
    if Config.FirmaName <> '' then AktFirmaName := Config.FirmaName;
    if Config.PrgName <> '' then AktPrgName := Config.PrgName;
  end;

procedure LoadConfig(LoadProc: TConfigLoadedProc);

  procedure OnSuccess(Response: IHttpResponse);
  var
    Obj: TJSObject;
    Config: TAppConfig;
    hst : String;
    i,j : integer;
  begin
    Config := TAppConfig.Create;
    try
      if Response.StatusCode = 200 then begin
        Obj := TJSObject(TJSJSON.parse(Response.ContentAsText));

        if JS.toString(Obj['AuthUrl']) <> '' then
          Config.AuthUrl := JS.toString(Obj['AuthUrl']);

        if JS.toString(Obj['ApiUrl']) <> '' then
          Config.ApiUrl := JS.toString(Obj['ApiUrl']);

        if JS.toString(Obj['FirmaNo']) <> '' then begin
          hst := JS.toString(Obj['FirmaNo']);
          i := 0;
          j := 0;
          val(hst,j,i);
          Config.FirmaNo := j;
        end;
        if JS.toString(Obj['Logo']) <> '' then
          Config.Logo := JS.toString(Obj['Logo']);
        if JS.toString(Obj['FirmaName']) <> '' then
          Config.FirmaName := JS.toString(Obj['FirmaName']);

        if JS.toString(Obj['PrgName']) <> '' then
          Config.PrgName := JS.toString(Obj['PrgName']);


      end
      else begin
        Str(Response.StatusCode:10,hst);
        MessageDlg('Fehler beim Laden der Config-Datei: '+hst, mtError, [], nil);

      end;
   finally
      LoadProc(Config);
      Config.Free;
    end;
  end;

  procedure OnError;
  var
    Config: TAppConfig;
  begin
    Config := TAppConfig.Create;
    try
      LoadProc(Config);
    finally
      Config.Free;
    end;
  end;

var
  Conn: TXDataWebConnection;
  hst : String;
  R : THttpRequest;
begin
  Conn := TXDataWebConnection.Create(nil);
  try
    hst := Conn.URL;
    if hst = '' then begin
//      MessageDlg(hst, mtError, [], nil);
    end;
    R := THttpRequest.Create;
    R.Uri := 'config/config1.json';
    hst := R.Uri;

    Conn.SendRequest(R, THttpResponseProc(@OnSuccess), nil);
//    Conn.SendRequest(THttpRequest.Create('config/config.json'), @OnSuccess, @OnError);
  finally
    Conn.Free;
  end;
end;

{ TAppConfig }

constructor TAppConfig.Create;
begin
  FAuthUrl := '';
  FApiUrl := '';
  FFirmaNo := 0;
  FFirmaName := '';
  FLogo := '';
  FPrgName := '';

end;


procedure Init_Prog;
begin
  LoadConfig(TConfigLoadedProc(@ConfigLoaded));
end;

{
procedure Init_Prog;
var
  ALocalStorage: TLocalStorage;
begin
  ALocalStorage := TLocalStorage.Create;
  ALocalStorage.Values['key'] := 'yourvalue';
  ALocalStorage.Free;
end;
}
{
procedure Init_Prog;
var
  ini : TInifile;
  st : String;
begin
  system.Assign('test.txt');
  system.Rewrite(f);
  writeln(f,'test test test');
  system.Close(f);
end;

}





end.
