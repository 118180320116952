unit UStd_EditForm;

interface


uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.StdCtrls, DB,

  WEBLib.ExtCtrls, WEBLib.DBCtrls,
  XData.Web.Dataset,
  XData.Web.Client,
  UDatamodule,
  UGlobvar, WEBLib.Imaging.pngimage, WEBLib.DB;



type
  TStd_Editform = class(TForm)
    WebPanel1: TPanel;
    Edit2_WebLabel_1: TLabel;
    Weblabel301: TLabel;
    WebEdit1: TEdit;
    WebPanel4: TPanel;
    WebImageControl1: TImageControl;
    WebImageControl5: TImageControl;
    WebImageControl6: TImageControl;
    WebImageControl7: TImageControl;
    WebImageControl8: TImageControl;
    WebDataSource1: TDataSource;
    WebPanel2: TPanel;
    procedure WebFormShow(Sender: TObject);
    procedure WebImageControl8Click(Sender: TObject);
    procedure WebImageControl1Click(Sender: TObject);
  private
    { Private-Deklarationen }
  protected
    procedure LoadDFMValues; override;
  public
    { Public-Deklarationen }
    Akt_DS : TXDataWebDataSet;
    Akt_Entity : String;
    Akt_FString,
    Akt_FieldString,
    Akt_EFString : String;
    FA : array[1 .. 100] of TDBEdit;
    LA : array[1 .. 100] of TLabel;
    FA_Cnt : integer;
  end;

var
  Std_Editform: TStd_Editform;


procedure Standard_Edit_Form2(var DS : TXDataWebDataSet; EntityName,FString,FieldString,EDitString : String);



implementation



procedure Standard_Edit_Form2(var DS : TXDataWebDataSet; EntityName,FString,FieldString,EDitString : String);
var newForm : TSTD_Editform;
    fn,
    dl,
    dt,
    RO,
    PF,
    hst  : String;
    f : TField;
    i,j,
    fcnt : integer;

begin

{
  Newform := TSTD_Editform.CreateNew(procedure(AForm: TObject)
    begin
      (AForm as TSTD_Editform).Font.Size := 12;
      (AForm as TSTD_Editform).Caption := 'Einrichtungen';
      (AForm as TSTD_Editform).WebDataSource1.DataSet := nil;
//      (AForm as TSTD_Editform).Akt_DS := TXDataWebDataSet.Create(Application);
//      (AForm as TSTD_Editform).Akt_DS.Connection := Datamodule1.XDataWebConnection1;

      (AForm as TSTD_Editform).Akt_DS := DS;
      (AForm as TSTD_Editform).Akt_Entity := EntityName;
      (AForm as TSTD_Editform).Akt_FString := FString;
      (AForm as TSTD_Editform).Akt_FieldString := FieldString;
      (AForm as TSTD_Editform).Akt_EFString := EditString;

      hst := EditString;
      (AForm as TSTD_Editform).FA_Cnt := 0;
      i := 50;
      repeat
        Parse_Edit_Field(hst,fn,dl,j,dt,RO,PF);
        if fn <> '' then begin
          (AForm as TSTD_Editform).FA_Cnt := (AForm as TSTD_Editform).FA_Cnt +1;

          (AForm as TSTD_Editform).FA[(AForm as TSTD_Editform).FA_Cnt] := TWebDBEdit.Create(TComponent(AForm));
          (AForm as TSTD_Editform).FA[(AForm as TSTD_Editform).FA_Cnt].Name := 'F_'+IntToStr((AForm as TSTD_Editform).FA_Cnt);
          (AForm as TSTD_Editform).FA[(AForm as TSTD_Editform).FA_Cnt].Parent :=(AForm as TSTD_Editform).WebPanel2;

          (AForm as TSTD_Editform).LA[(AForm as TSTD_Editform).FA_Cnt] := TWebLabel.Create(TComponent(AForm));
          (AForm as TSTD_Editform).LA[(AForm as TSTD_Editform).FA_Cnt].Name := 'L_'+IntToStr((AForm as TSTD_Editform).FA_Cnt);
          (AForm as TSTD_Editform).LA[(AForm as TSTD_Editform).FA_Cnt].Parent :=(AForm as TSTD_Editform).WebPanel2;
          (AForm as TSTD_Editform).LA[(AForm as TSTD_Editform).FA_Cnt].Left := 15;
          (AForm as TSTD_Editform).LA[(AForm as TSTD_Editform).FA_Cnt].Top := i;
          (AForm as TSTD_Editform).LA[(AForm as TSTD_Editform).FA_Cnt].Caption := dl;
          i := i + 20;


          (AForm as TSTD_Editform).FA[(AForm as TSTD_Editform).FA_Cnt].DataSource := (AForm as TSTD_Editform).WebDataSource1;
          (AForm as TSTD_Editform).FA[(AForm as TSTD_Editform).FA_Cnt].Datafield := fn;
          (AForm as TSTD_Editform).FA[(AForm as TSTD_Editform).FA_Cnt].Left := 15;
          (AForm as TSTD_Editform).FA[(AForm as TSTD_Editform).FA_Cnt].Top := i;
           i := i + 50;
          (AForm as TSTD_Editform).FA[(AForm as TSTD_Editform).FA_Cnt].Width := j;


        end;
      until hst = '';
      (AForm as TSTD_Editform).WebDataSource1.DataSet := DS;
    end
  );
  Newform.Border := fbSingle;

  Newform.ShowModal(procedure(AValue: TModalResult)
  begin
//      Close;
  end
  );
}

end;



procedure TStd_Editform.WebFormShow(Sender: TObject);
begin
  WebEdit1.Text := AktKDName;
  Edit2_WebLabel_1.Caption := AktFirmaName;

end;



procedure TStd_Editform.WebImageControl1Click(Sender: TObject);
var hst,hst2 : String;
    i,j : integer;
begin
{
  hst := Trim(WebDBEdit1.Text);
  i := 0;
  j := 0;
  val(hst,i,j);
  if (i<=0) or (j<>0) then begin
    MessageDlg('Nummer ist ungültig', mtError, [], nil);
    WebDBEdit1.SetFocus;
    exit;
  end;
  hst := Trim(WebDBEdit2.Text);
  WebDBEdit2.Text := hst;
  if hst = '' then begin
    MessageDlg('Bezeichnung muß erfasst werden', mtError, [], nil);
    WebDBEdit2.SetFocus;
    exit;
  end;

  if not Einrichtung_Insert_Mode then begin
    try
      Datamodule1.EinrichtungDataSet.Post;
      Datamodule1.EinrichtungDataSet.ApplyUpdates;
    except
      MessageDlg('Fehler beim Speichern', mtError, [], nil);
      exit;
    end;
  end
  else begin

//SELECT p.NEWID
//FROM STD_INSERT_TEILNEHMER ('P_ID', 'P_NL_KEY', 'P_KDID', 'P_LFDNO', 'P_NAME',
//    'P_EINRICHTUNG', 'P_GESCHLECHT', 'P_GEBURTSDATUM', 'P_LKZ', 'P_LOESCHUNG', 'P_PLAN1', 'P_PLAN2', 'P_PLAN3', 'P_PLAN4', 'P_PLAN5', 'P_PLAN6', 'P_PLAN7') p;

// SELECT p.NEWID FROM STD_INSERT_EINRICHTUNGEN ('P_ID', 'P_NL_KEY', 'P_NUMMER', 'P_BEZEICHNUNG',
//    'P_EMAIL', 'P_TELEFON') p;


    hst2 := 'Select p.NEWID FROM STD_INSERT_Einrichtungen(0,'+IntToStr(AktFirmaNo)+','
    +AFZ(WebDBEdit1.Text)
    +AFZ(WebDBEdit2.Text)
    +AFZ('')
    +AFZ2('')
    +') p';


  DataModule1.XDataClient.RawInvoke('IAuthService.Do_Transaction', [0, hst2],
    procedure(Response: TXDataClientResponse)
    var
      i: integer;
      hst : String;
    begin
      hst := JS.ToString(JS.ToObject(Response.Result)['value']);
      if hst <> '' then begin
        i := StrToInt(hst);
        if i = -1 then begin
          MessageDlg('Einrichtung nicht gespeichert', mtError, [], nil);
        end
        else begin
          Letzte_ID := i;

          Datamodule1.EinrichtungDataSet.Close;
          Datamodule1.EinrichtungDataSet.AfterOpen := After_Insert_Einrichtung;
          if Lade_Daten_2(Datamodule1.EinrichtungDataSet,'EINRICHTUNGEN','$filter=(NL_Key eq '+IntToStr(AktFirmaNo) + ')') then begin
          end;
//          if Datamodule1.TeilnehmerDataSet.Locate('ID',i,[]) then ;
          Close;
        end;
      end
      else begin
        MessageDlg('Speichern nicht möglich', mtError, [], nil);
      end;

//      window.localStorage.setItem(cStorageTokenName, Token);
//      CheckLogin;
    end
  );

  end;



  Close;

}

end;

procedure TStd_Editform.WebImageControl8Click(Sender: TObject);
begin
  Close;
end;

procedure TStd_Editform.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebPanel1 := TPanel.Create(Self);
  Edit2_WebLabel_1 := TLabel.Create(Self);
  Weblabel301 := TLabel.Create(Self);
  WebEdit1 := TEdit.Create(Self);
  WebPanel4 := TPanel.Create(Self);
  WebImageControl1 := TImageControl.Create(Self);
  WebImageControl5 := TImageControl.Create(Self);
  WebImageControl6 := TImageControl.Create(Self);
  WebImageControl7 := TImageControl.Create(Self);
  WebImageControl8 := TImageControl.Create(Self);
  WebPanel2 := TPanel.Create(Self);
  WebDataSource1 := TDataSource.Create(Self);

  WebPanel1.BeforeLoadDFMValues;
  Edit2_WebLabel_1.BeforeLoadDFMValues;
  Weblabel301.BeforeLoadDFMValues;
  WebEdit1.BeforeLoadDFMValues;
  WebPanel4.BeforeLoadDFMValues;
  WebImageControl1.BeforeLoadDFMValues;
  WebImageControl5.BeforeLoadDFMValues;
  WebImageControl6.BeforeLoadDFMValues;
  WebImageControl7.BeforeLoadDFMValues;
  WebImageControl8.BeforeLoadDFMValues;
  WebPanel2.BeforeLoadDFMValues;
  WebDataSource1.BeforeLoadDFMValues;
  try
    Name := 'Std_Editform';
    Left := 0;
    Top := 0;
    Width := 640;
    Height := 480;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Arial';
    Font.Style := [];
    TabOrder := 1;
    SetEvent(Self, 'OnShow', 'WebFormShow');
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 0;
    WebPanel1.Top := 0;
    WebPanel1.Width := 640;
    WebPanel1.Height := 89;
    WebPanel1.Align := alTop;
    WebPanel1.BorderColor := clSilver;
    WebPanel1.BorderStyle := bsSingle;
    WebPanel1.ChildOrder := 1;
    Edit2_WebLabel_1.SetParentComponent(WebPanel1);
    Edit2_WebLabel_1.Name := 'Edit2_WebLabel_1';
    Edit2_WebLabel_1.Left := 10;
    Edit2_WebLabel_1.Top := 8;
    Edit2_WebLabel_1.Width := 142;
    Edit2_WebLabel_1.Height := 19;
    Edit2_WebLabel_1.Caption := 'Edit2_WebLabel_1';
    Edit2_WebLabel_1.Font.Charset := DEFAULT_CHARSET;
    Edit2_WebLabel_1.Font.Color := clWindowText;
    Edit2_WebLabel_1.Font.Height := -16;
    Edit2_WebLabel_1.Font.Name := 'Arial';
    Edit2_WebLabel_1.Font.Style := [fsBold];
    Edit2_WebLabel_1.ParentFont := False;
    Weblabel301.SetParentComponent(WebPanel1);
    Weblabel301.Name := 'Weblabel301';
    Weblabel301.Left := 8;
    Weblabel301.Top := 45;
    Weblabel301.Width := 47;
    Weblabel301.Height := 14;
    Weblabel301.Caption := 'Benutzer:';
    Weblabel301.Color := clBtnFace;
    WebEdit1.SetParentComponent(WebPanel1);
    WebEdit1.Name := 'WebEdit1';
    WebEdit1.Left := 162;
    WebEdit1.Top := 42;
    WebEdit1.Width := 248;
    WebEdit1.Height := 22;
    WebEdit1.TabStop := False;
    WebEdit1.ChildOrder := 7;
    WebEdit1.ElementClassName := 'form-control';
    WebEdit1.ReadOnly := True;
    WebEdit1.Text := 'WebEdit1';
    WebPanel4.SetParentComponent(Self);
    WebPanel4.Name := 'WebPanel4';
    WebPanel4.Left := 0;
    WebPanel4.Top := 89;
    WebPanel4.Width := 640;
    WebPanel4.Height := 33;
    WebPanel4.Align := alTop;
    WebPanel4.BorderColor := clSilver;
    WebPanel4.BorderStyle := bsSingle;
    WebPanel4.ChildOrder := 4;
    WebImageControl1.SetParentComponent(WebPanel4);
    WebImageControl1.Name := 'WebImageControl1';
    WebImageControl1.Left := 0;
    WebImageControl1.Top := 0;
    WebImageControl1.Width := 33;
    WebImageControl1.Height := 33;
    WebImageControl1.Align := alLeft;
    SetEvent(WebImageControl1, Self, 'OnClick', 'WebImageControl1Click');
    WebImageControl1.Picture.LoadFromFile('UStd_EditForm.WebPanel4.WebImageControl1.Picture.png');
    WebImageControl5.SetParentComponent(WebPanel4);
    WebImageControl5.Name := 'WebImageControl5';
    WebImageControl5.Left := 33;
    WebImageControl5.Top := 0;
    WebImageControl5.Width := 33;
    WebImageControl5.Height := 33;
    WebImageControl5.Align := alLeft;
    WebImageControl5.ChildOrder := 4;
    WebImageControl6.SetParentComponent(WebPanel4);
    WebImageControl6.Name := 'WebImageControl6';
    WebImageControl6.Left := 66;
    WebImageControl6.Top := 0;
    WebImageControl6.Width := 33;
    WebImageControl6.Height := 33;
    WebImageControl6.Align := alLeft;
    WebImageControl6.ChildOrder := 5;
    WebImageControl7.SetParentComponent(WebPanel4);
    WebImageControl7.Name := 'WebImageControl7';
    WebImageControl7.Left := 99;
    WebImageControl7.Top := 0;
    WebImageControl7.Width := 33;
    WebImageControl7.Height := 33;
    WebImageControl7.Align := alLeft;
    WebImageControl7.ChildOrder := 6;
    WebImageControl8.SetParentComponent(WebPanel4);
    WebImageControl8.Name := 'WebImageControl8';
    WebImageControl8.Left := 132;
    WebImageControl8.Top := 0;
    WebImageControl8.Width := 33;
    WebImageControl8.Height := 33;
    WebImageControl8.Align := alLeft;
    WebImageControl8.ChildOrder := 7;
    SetEvent(WebImageControl8, Self, 'OnClick', 'WebImageControl8Click');
    WebImageControl8.Picture.LoadFromFile('UStd_EditForm.WebPanel4.WebImageControl8.Picture.png');
    WebPanel2.SetParentComponent(Self);
    WebPanel2.Name := 'WebPanel2';
    WebPanel2.Left := 0;
    WebPanel2.Top := 122;
    WebPanel2.Width := 640;
    WebPanel2.Height := 358;
    WebPanel2.Align := alClient;
    WebPanel2.BorderColor := clSilver;
    WebPanel2.BorderStyle := bsSingle;
    WebPanel2.ChildOrder := 2;
    WebDataSource1.SetParentComponent(Self);
    WebDataSource1.Name := 'WebDataSource1';
    WebDataSource1.Left := 360;
    WebDataSource1.Top := 352;
  finally
    WebPanel1.AfterLoadDFMValues;
    Edit2_WebLabel_1.AfterLoadDFMValues;
    Weblabel301.AfterLoadDFMValues;
    WebEdit1.AfterLoadDFMValues;
    WebPanel4.AfterLoadDFMValues;
    WebImageControl1.AfterLoadDFMValues;
    WebImageControl5.AfterLoadDFMValues;
    WebImageControl6.AfterLoadDFMValues;
    WebImageControl7.AfterLoadDFMValues;
    WebImageControl8.AfterLoadDFMValues;
    WebPanel2.AfterLoadDFMValues;
    WebDataSource1.AfterLoadDFMValues;
  end;
end;

end.