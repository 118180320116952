unit UGetBewertung;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.StdCtrls, WEBLib.DBCtrls,
  WEBLib.ExtCtrls,
  UDatamodule,
  UGlobvar;

type
  TGetBewertung = class(TForm)
    WebPanel1: TPanel;
    WebLabel4: TLabel;
    WebLabel2: TLabel;
    WebPanel2: TPanel;
    WebButton2: TButton;
    WebButton1: TButton;
    WebComboBox4: TComboBox;
    WebComboBox1: TComboBox;
    BewWebLabel1: TLabel;
    WebComboBox2: TComboBox;
    procedure WebFormShow(Sender: TObject);
    procedure WebButton1Click(Sender: TObject);
    procedure WebButton2Click(Sender: TObject);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
    procedure WebFormCreate(Sender: TObject);
    procedure WebFormResize(Sender: TObject);
  private
    { Private-Deklarationen }
  protected
    procedure LoadDFMValues; override;
  public
    { Public-Deklarationen }

    Zeilen_Liste : TStringList;
    Zeilen_Count : integer;
    Form_Aktiv : boolean;

  end;

var
  GetBewertung: TGetBewertung;


procedure Do_Bewertung;



implementation




procedure Do_Bewertung;
var
  newform: TGetBewertung;
begin

//  if Create_Form(TEditTelefon,newForm) then ;

  newform := TGetBewertung.CreateNew(procedure(AForm: TObject)
    begin
      (AForm as TGetBewertung).Caption := 'Bewertung';
    end
  );
//  newForm.Caption := 'Neues Konto';
  newform.Border := fbSingle;

  newform.ShowModal(procedure(AValue: TModalResult)
  begin
//
  end
  );



end;






procedure TGetBewertung.WebButton1Click(Sender: TObject);
begin
  DataModule1.SpeiseplanDataSet.Cancel;
  Close;

end;

procedure TGetBewertung.WebButton2Click(Sender: TObject);
begin
  try

    DataModule1.SpeiseplanDataSet.Fieldbyname('Bewertung1').AsInteger := WebComboBox4.ItemIndex;
    DataModule1.SpeiseplanDataSet.Fieldbyname('Bewertung2').AsInteger := WebComboBox1.ItemIndex;
    DataModule1.SpeiseplanDataSet.Fieldbyname('Bewertung3').AsInteger := WebComboBox2.ItemIndex;


    DataModule1.SpeiseplanDataSet.Post;
    DataModule1.SpeiseplanDataSet.ApplyUpdates;
  except
    MessageDlg('Fehler beim Speichern', mtError, [], nil);
    exit;
  end;
  Close;
end;

procedure TGetBewertung.WebFormClose(Sender: TObject; var Action: TCloseAction);
begin
  Zeilen_Liste.Clear;
  Zeilen_Liste.Free;

end;

procedure TGetBewertung.WebFormCreate(Sender: TObject);
begin
  Form_Aktiv := false;
end;

procedure TGetBewertung.WebFormResize(Sender: TObject);
begin
  if not Form_Aktiv then exit;
  Resize_Edit_Controls(WF_Edit,self,Zeilen_Liste,WebPanel1,WebPanel2);

end;

procedure TGetBewertung.WebFormShow(Sender: TObject);
var i : integer;
begin
  DataModule1.SpeiseplanDataSet.Edit;
  Resize_Top_Panel_3(self,WebPanel1,'Akt. Teilnehmer: '+AktTeilnehmerName,
    'Artikel: '+DataModule1.SpeiseplanDataSet.Fieldbyname('Bezeichnung').AsString);
  Resize_Bottom_Panel(self,WebPanel2);
  Zeilen_Liste := TStringList.Create;
  Zeilen_Count := 0;
  Zeilen_Liste_Add(Zeilen_Liste,Zeilen_Count,WebLabel4,WebComboBox4,nil);
  Zeilen_Liste_Add(Zeilen_Liste,Zeilen_Count,WebLabel2,WebComboBox1,nil);
  Zeilen_Liste_Add(Zeilen_Liste,Zeilen_Count,BewWebLabel1,WebComboBox2,nil);

  Form_Aktiv := true;

  Resize_Edit_Controls(WF_Edit,self,Zeilen_Liste,WebPanel1,WebPanel2);
  WebComboBox4.Items.Clear;
  WebComboBox4.Items.Add('-----------');
  WebComboBox1.Items.Clear;
  WebComboBox1.Items.Add('-----------');
  WebComboBox2.Items.Clear;
  WebComboBox2.Items.Add('-----------');
  for i := 1 to 6 do begin
    if Bewertung1[i] <> '' then WebComboBox4.Items.Add(Bewertung1[i]);
    if Bewertung2[i] <> '' then WebComboBox1.Items.Add(Bewertung2[i]);
    if Bewertung3[i] <> '' then WebComboBox2.Items.Add(Bewertung3[i]);
  end;
  WebComboBox4.ItemIndex := DataModule1.SpeiseplanDataSet.Fieldbyname('Bewertung1').AsInteger;
  WebComboBox1.ItemIndex := DataModule1.SpeiseplanDataSet.Fieldbyname('Bewertung2').AsInteger;
  WebComboBox2.ItemIndex := DataModule1.SpeiseplanDataSet.Fieldbyname('Bewertung3').AsInteger;






end;

procedure TGetBewertung.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebLabel4 := TLabel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  BewWebLabel1 := TLabel.Create(Self);
  WebPanel1 := TPanel.Create(Self);
  WebPanel2 := TPanel.Create(Self);
  WebButton2 := TButton.Create(Self);
  WebButton1 := TButton.Create(Self);
  WebComboBox4 := TComboBox.Create(Self);
  WebComboBox1 := TComboBox.Create(Self);
  WebComboBox2 := TComboBox.Create(Self);

  WebLabel4.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  BewWebLabel1.BeforeLoadDFMValues;
  WebPanel1.BeforeLoadDFMValues;
  WebPanel2.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  WebComboBox4.BeforeLoadDFMValues;
  WebComboBox1.BeforeLoadDFMValues;
  WebComboBox2.BeforeLoadDFMValues;
  try
    Name := 'GetBewertung';
    Left := 0;
    Top := 0;
    Width := 640;
    Height := 480;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Arial';
    Font.Style := [];
    TabOrder := 1;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnResize', 'WebFormResize');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    WebLabel4.SetParentComponent(Self);
    WebLabel4.Name := 'WebLabel4';
    WebLabel4.Left := 10;
    WebLabel4.Top := 104;
    WebLabel4.Width := 135;
    WebLabel4.Height := 16;
    WebLabel4.Caption := 'Bewertung Geschmack';
    WebLabel4.Color := clBtnFace;
    WebLabel4.Font.Charset := DEFAULT_CHARSET;
    WebLabel4.Font.Color := clWindowText;
    WebLabel4.Font.Height := -13;
    WebLabel4.Font.Name := 'Arial';
    WebLabel4.Font.Style := [];
    WebLabel4.ParentFont := False;
    WebLabel2.SetParentComponent(Self);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 8;
    WebLabel2.Top := 162;
    WebLabel2.Width := 147;
    WebLabel2.Height := 16;
    WebLabel2.Caption := 'Bewertung Portionsgr'#246#223'e';
    WebLabel2.Color := clBtnFace;
    WebLabel2.Font.Charset := DEFAULT_CHARSET;
    WebLabel2.Font.Color := clWindowText;
    WebLabel2.Font.Height := -13;
    WebLabel2.Font.Name := 'Arial';
    WebLabel2.Font.Style := [];
    WebLabel2.ParentFont := False;
    BewWebLabel1.SetParentComponent(Self);
    BewWebLabel1.Name := 'BewWebLabel1';
    BewWebLabel1.Left := 8;
    BewWebLabel1.Top := 218;
    BewWebLabel1.Width := 122;
    BewWebLabel1.Height := 16;
    BewWebLabel1.Caption := 'Bewertung Aussehen';
    BewWebLabel1.Color := clBtnFace;
    BewWebLabel1.Font.Charset := DEFAULT_CHARSET;
    BewWebLabel1.Font.Color := clWindowText;
    BewWebLabel1.Font.Height := -13;
    BewWebLabel1.Font.Name := 'Arial';
    BewWebLabel1.Font.Style := [];
    BewWebLabel1.ParentFont := False;
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 0;
    WebPanel1.Top := 0;
    WebPanel1.Width := 640;
    WebPanel1.Height := 89;
    WebPanel1.Align := alTop;
    WebPanel1.BorderColor := clSilver;
    WebPanel1.BorderStyle := bsSingle;
    WebPanel1.ChildOrder := 1;
    WebPanel2.SetParentComponent(Self);
    WebPanel2.Name := 'WebPanel2';
    WebPanel2.Left := 0;
    WebPanel2.Top := 420;
    WebPanel2.Width := 640;
    WebPanel2.Height := 60;
    WebPanel2.Align := alBottom;
    WebPanel2.BorderColor := clSilver;
    WebPanel2.BorderStyle := bsSingle;
    WebPanel2.ChildOrder := 5;
    WebButton2.SetParentComponent(WebPanel2);
    WebButton2.Name := 'WebButton2';
    WebButton2.Left := 12;
    WebButton2.Top := 12;
    WebButton2.Width := 177;
    WebButton2.Height := 41;
    WebButton2.Caption := #196'nderungen speichern';
    WebButton2.ChildOrder := 13;
    WebButton2.ElementClassName := 'btn-primary';
    WebButton2.TabOrder := 7;
    SetEvent(WebButton2, Self, 'OnClick', 'WebButton2Click');
    WebButton1.SetParentComponent(WebPanel2);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 195;
    WebButton1.Top := 12;
    WebButton1.Width := 96;
    WebButton1.Height := 41;
    WebButton1.Caption := 'Zur'#252'ck';
    WebButton1.ChildOrder := 6;
    WebButton1.ElementClassName := 'btn-primary';
    WebButton1.TabOrder := 8;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    WebComboBox4.SetParentComponent(Self);
    WebComboBox4.Name := 'WebComboBox4';
    WebComboBox4.Left := 11;
    WebComboBox4.Top := 130;
    WebComboBox4.Width := 247;
    WebComboBox4.Height := 22;
    WebComboBox4.Font.Charset := DEFAULT_CHARSET;
    WebComboBox4.Font.Color := clWindowText;
    WebComboBox4.Font.Height := -16;
    WebComboBox4.Font.Name := 'Arial';
    WebComboBox4.Font.Style := [];
    WebComboBox4.ParentFont := False;
    WebComboBox4.Text := '--';
    WebComboBox4.ItemIndex := 0;
    WebComboBox4.Items.BeginUpdate;
    try
      WebComboBox4.Items.Clear;
      WebComboBox4.Items.Add('--');
      WebComboBox4.Items.Add('Januar');
      WebComboBox4.Items.Add('Februar');
      WebComboBox4.Items.Add('M'#228'rz');
      WebComboBox4.Items.Add('April');
      WebComboBox4.Items.Add('Mai');
      WebComboBox4.Items.Add('Juni');
      WebComboBox4.Items.Add('Juli');
      WebComboBox4.Items.Add('August');
      WebComboBox4.Items.Add('September');
      WebComboBox4.Items.Add('Oktober');
      WebComboBox4.Items.Add('November');
      WebComboBox4.Items.Add('Dezember');
    finally
      WebComboBox4.Items.EndUpdate;
    end;
    WebComboBox1.SetParentComponent(Self);
    WebComboBox1.Name := 'WebComboBox1';
    WebComboBox1.Left := 8;
    WebComboBox1.Top := 184;
    WebComboBox1.Width := 247;
    WebComboBox1.Height := 22;
    WebComboBox1.Font.Charset := DEFAULT_CHARSET;
    WebComboBox1.Font.Color := clWindowText;
    WebComboBox1.Font.Height := -16;
    WebComboBox1.Font.Name := 'Arial';
    WebComboBox1.Font.Style := [];
    WebComboBox1.ParentFont := False;
    WebComboBox1.Text := '--';
    WebComboBox1.ItemIndex := 0;
    WebComboBox1.Items.BeginUpdate;
    try
      WebComboBox1.Items.Clear;
      WebComboBox1.Items.Add('--');
      WebComboBox1.Items.Add('Januar');
      WebComboBox1.Items.Add('Februar');
      WebComboBox1.Items.Add('M'#228'rz');
      WebComboBox1.Items.Add('April');
      WebComboBox1.Items.Add('Mai');
      WebComboBox1.Items.Add('Juni');
      WebComboBox1.Items.Add('Juli');
      WebComboBox1.Items.Add('August');
      WebComboBox1.Items.Add('September');
      WebComboBox1.Items.Add('Oktober');
      WebComboBox1.Items.Add('November');
      WebComboBox1.Items.Add('Dezember');
    finally
      WebComboBox1.Items.EndUpdate;
    end;
    WebComboBox2.SetParentComponent(Self);
    WebComboBox2.Name := 'WebComboBox2';
    WebComboBox2.Left := 8;
    WebComboBox2.Top := 240;
    WebComboBox2.Width := 247;
    WebComboBox2.Height := 22;
    WebComboBox2.Font.Charset := DEFAULT_CHARSET;
    WebComboBox2.Font.Color := clWindowText;
    WebComboBox2.Font.Height := -16;
    WebComboBox2.Font.Name := 'Arial';
    WebComboBox2.Font.Style := [];
    WebComboBox2.ParentFont := False;
    WebComboBox2.Text := '--';
    WebComboBox2.ItemIndex := 0;
    WebComboBox2.Items.BeginUpdate;
    try
      WebComboBox2.Items.Clear;
      WebComboBox2.Items.Add('--');
      WebComboBox2.Items.Add('Januar');
      WebComboBox2.Items.Add('Februar');
      WebComboBox2.Items.Add('M'#228'rz');
      WebComboBox2.Items.Add('April');
      WebComboBox2.Items.Add('Mai');
      WebComboBox2.Items.Add('Juni');
      WebComboBox2.Items.Add('Juli');
      WebComboBox2.Items.Add('August');
      WebComboBox2.Items.Add('September');
      WebComboBox2.Items.Add('Oktober');
      WebComboBox2.Items.Add('November');
      WebComboBox2.Items.Add('Dezember');
    finally
      WebComboBox2.Items.EndUpdate;
    end;
  finally
    WebLabel4.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    BewWebLabel1.AfterLoadDFMValues;
    WebPanel1.AfterLoadDFMValues;
    WebPanel2.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    WebComboBox4.AfterLoadDFMValues;
    WebComboBox1.AfterLoadDFMValues;
    WebComboBox2.AfterLoadDFMValues;
  end;
end;

end.