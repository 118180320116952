unit UConfirmEmail;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs,
  UDatamodule,
  UGlobvar, WEBLib.DBCtrls, WEBLib.StdCtrls, WEBLib.ExtCtrls;


type
  TConfirmEmail = class(TForm)
    WebLabel2: TLabel;
    WebEdit2: TEdit;
    WebButton2: TButton;
    WebButton1: TButton;
    WebPanel1: TPanel;
    Best_WebLabel_1: TLabel;
    Weblabel301: TLabel;
    WebEdit1: TEdit;
    procedure WebFormShow(Sender: TObject);
    procedure WebButton1Click(Sender: TObject);
    procedure WebButton2Click(Sender: TObject);
  private
    { Private-Deklarationen }
  protected
    procedure LoadDFMValues; override;
  public
    { Public-Deklarationen }
  end;

var
  ConfirmEmail: TConfirmEmail;

implementation


procedure TConfirmEmail.WebButton1Click(Sender: TObject);
begin
  Close;
end;

procedure TConfirmEmail.WebButton2Click(Sender: TObject);
var hst,hst2 : String;
begin
  hst := Trim(WebEdit2.Text);
  WebEdit2.Text := hst;
  if hst = '' then begin
    MessageDlg('Bestätigungscode muß erfasst werden', mtError, [], nil);
    WebEdit2.SetFocus;
    exit;
  end;
  if WebEdit2.Text <> DataModule1.KundenDataSet.FieldByName('Bestaetigung').AsString then begin
    MessageDlg('Bestätigungscode ist ungültig', mtError, [], nil);
    WebEdit2.SetFocus;
    exit;
  end;


  try
    DataModule1.KundenDataSet.Edit;
    DataModule1.KundenDataSet.FieldByName('Status').AsInteger := 1;
    DataModule1.KundenDataSet.FieldByName('Confirmed').AsDateTime := now;
    DataModule1.KundenDataSet.Post;
    DataModule1.KundenDataSet.ApplyUpdates;
  except
    MessageDlg('Fehler beim Speichern', mtError, [], nil);
    exit;
  end;
  Close;

end;

procedure TConfirmEmail.WebFormShow(Sender: TObject);
begin
  WebEdit1.Text := AktKDName;
  Best_WebLabel_1.Caption := AktFirmaName;

end;

procedure TConfirmEmail.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebLabel2 := TLabel.Create(Self);
  WebEdit2 := TEdit.Create(Self);
  WebButton2 := TButton.Create(Self);
  WebButton1 := TButton.Create(Self);
  WebPanel1 := TPanel.Create(Self);
  Best_WebLabel_1 := TLabel.Create(Self);
  Weblabel301 := TLabel.Create(Self);
  WebEdit1 := TEdit.Create(Self);

  WebLabel2.BeforeLoadDFMValues;
  WebEdit2.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  WebPanel1.BeforeLoadDFMValues;
  Best_WebLabel_1.BeforeLoadDFMValues;
  Weblabel301.BeforeLoadDFMValues;
  WebEdit1.BeforeLoadDFMValues;
  try
    Name := 'ConfirmEmail';
    Left := 0;
    Top := 0;
    Width := 640;
    Height := 480;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -16;
    Font.Name := 'Arial';
    Font.Style := [];
    TabOrder := 1;
    SetEvent(Self, 'OnShow', 'WebFormShow');
    WebLabel2.SetParentComponent(Self);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 8;
    WebLabel2.Top := 121;
    WebLabel2.Width := 104;
    WebLabel2.Height := 16;
    WebLabel2.Caption := 'Best'#228'tigungscode';
    WebLabel2.Color := clBtnFace;
    WebLabel2.Font.Charset := DEFAULT_CHARSET;
    WebLabel2.Font.Color := clWindowText;
    WebLabel2.Font.Height := -13;
    WebLabel2.Font.Name := 'Arial';
    WebLabel2.Font.Style := [];
    WebLabel2.ParentFont := False;
    WebEdit2.SetParentComponent(Self);
    WebEdit2.Name := 'WebEdit2';
    WebEdit2.Left := 8;
    WebEdit2.Top := 145;
    WebEdit2.Width := 177;
    WebEdit2.Height := 22;
    WebEdit2.ChildOrder := 8;
    WebEdit2.ElementClassName := 'form-control';
    WebButton2.SetParentComponent(Self);
    WebButton2.Name := 'WebButton2';
    WebButton2.Left := 8;
    WebButton2.Top := 249;
    WebButton2.Width := 241;
    WebButton2.Height := 41;
    WebButton2.Caption := 'eMail-Adresse best'#228'tigen';
    WebButton2.ChildOrder := 13;
    WebButton2.ElementClassName := 'btn-primary';
    WebButton2.TabOrder := 40;
    SetEvent(WebButton2, Self, 'OnClick', 'WebButton2Click');
    WebButton1.SetParentComponent(Self);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 265;
    WebButton1.Top := 249;
    WebButton1.Width := 96;
    WebButton1.Height := 41;
    WebButton1.Caption := 'Zur'#252'ck';
    WebButton1.ChildOrder := 14;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 0;
    WebPanel1.Top := 0;
    WebPanel1.Width := 640;
    WebPanel1.Height := 89;
    WebPanel1.Align := alTop;
    WebPanel1.BorderColor := clSilver;
    WebPanel1.BorderStyle := bsSingle;
    WebPanel1.ChildOrder := 1;
    Best_WebLabel_1.SetParentComponent(WebPanel1);
    Best_WebLabel_1.Name := 'Best_WebLabel_1';
    Best_WebLabel_1.Left := 10;
    Best_WebLabel_1.Top := 8;
    Best_WebLabel_1.Width := 138;
    Best_WebLabel_1.Height := 19;
    Best_WebLabel_1.Caption := 'Best_WebLabel_1';
    Best_WebLabel_1.Font.Charset := DEFAULT_CHARSET;
    Best_WebLabel_1.Font.Color := clWindowText;
    Best_WebLabel_1.Font.Height := -16;
    Best_WebLabel_1.Font.Name := 'Arial';
    Best_WebLabel_1.Font.Style := [fsBold];
    Best_WebLabel_1.ParentFont := False;
    Weblabel301.SetParentComponent(WebPanel1);
    Weblabel301.Name := 'Weblabel301';
    Weblabel301.Left := 10;
    Weblabel301.Top := 33;
    Weblabel301.Width := 80;
    Weblabel301.Height := 16;
    Weblabel301.Caption := 'Akt. Benutzer';
    Weblabel301.Color := clBtnFace;
    Weblabel301.Font.Charset := DEFAULT_CHARSET;
    Weblabel301.Font.Color := clWindowText;
    Weblabel301.Font.Height := -13;
    Weblabel301.Font.Name := 'Arial';
    Weblabel301.Font.Style := [];
    Weblabel301.ParentFont := False;
    WebEdit1.SetParentComponent(WebPanel1);
    WebEdit1.Name := 'WebEdit1';
    WebEdit1.Left := 10;
    WebEdit1.Top := 55;
    WebEdit1.Width := 271;
    WebEdit1.Height := 22;
    WebEdit1.TabStop := False;
    WebEdit1.ChildOrder := 7;
    WebEdit1.ElementClassName := 'form-control';
    WebEdit1.ReadOnly := True;
    WebEdit1.Text := 'WebEdit1';
  finally
    WebLabel2.AfterLoadDFMValues;
    WebEdit2.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    WebPanel1.AfterLoadDFMValues;
    Best_WebLabel_1.AfterLoadDFMValues;
    Weblabel301.AfterLoadDFMValues;
    WebEdit1.AfterLoadDFMValues;
  end;
end;

end.