unit UTeilnehmer;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs,
  UDatamodule,
  UGlobvar, WEBLib.DBCtrls, WEBLib.StdCtrls, DB,
  WEBLib.ExtCtrls, WEBLib.Grids,
  WEBLib.CDS,
  UEditTeilnehmer,
  UVWAllergien,
  UWochenplan,
  UEditSubAccount,
  UTeilnMenu, WEBLib.Menus  ;

type
  TTeilnehmer = class(TForm)
    WebPanel1: TPanel;
    WebDBGrid1: TDBGrid;
    WebPanel2: TPanel;
    WebButton3: TButton;
    WebPanel3: TPanel;
    WebButton1: TButton;
    WebButton11: TButton;
    WebTimer1: TTimer;
    procedure WebFormShow(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
    procedure WebButton1Click(Sender: TObject);
    procedure WebButton3Click(Sender: TObject);
    procedure WebButton2Click(Sender: TObject);
    procedure After_Insert_Teilnehmer(DataSet: TDataSet);
    procedure WebButton6Click(Sender: TObject);
    procedure Zeige_Allergie_Fenster(DataSet: TDataSet);
    procedure WebButton7Click(Sender: TObject);
    procedure Zeige_Wochenplan(DataSet: TDataSet);
    procedure WebButton4Click(Sender: TObject);
    procedure WebButton5Click(Sender: TObject);
    procedure WebButton8Click(Sender: TObject);
    procedure WebButton9Click(Sender: TObject);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
    procedure WebFormResize(Sender: TObject);
    procedure WebButton10Click(Sender: TObject);
    procedure WebButton11Click(Sender: TObject);
    procedure Zeige_Teilnehmer_Menu;
    procedure WebTimer1Timer(Sender: TObject);
  private
    { Private-Deklarationen }
  protected
    procedure LoadDFMValues; override;
  public
    { Public-Deklarationen }

    Zeilen_Liste : TStringList;
    Zeilen_Count : integer;
    Form_Aktiv : boolean;

  end;

var
  Teilnehmer: TTeilnehmer;

implementation





procedure TTeilnehmer.WebButton10Click(Sender: TObject);
var newForm : TEditTeilnehmer;
begin
  if Datamodule1.TeilnehmerDataSet.Fieldbyname('ID').AsInteger = 0 then exit;
  if Datamodule1.TeilnehmerDataSet.Fieldbyname('AKtiv').AsInteger = 0 then begin
    MessageDlg('Teilnehmer ist inaktiv gesetzt, ggf. erst aktivieren', mtError, [], nil);
    exit;
  end;

  Teilnehmer_Insert_Mode := false;
  Teilnehmer_Delete_Mode := false;
  Teilnehmer_Activate_Mode := false;
  Teilnehmer_Deactivate_Mode := false;
  Teilnehmer_EChange_Mode := true;

  Datamodule1.TeilnehmerDataSet.Edit;

//  if Create_Form(TEditTeilnehmer,newForm) then ;

  newform := TEditTeilnehmer.CreateNew(procedure(AForm: TObject)
    begin
      (AForm as TEditTeilnehmer).Caption := 'Teilnehmer bearbeiten';
    end
  );
//  newForm.Caption := 'Neues Konto';
  newform.Border := fbSingle;

  newform.ShowModal(procedure(AValue: TModalResult)
  begin
//
  end
  );

end;

procedure TTeilnehmer.WebButton11Click(Sender: TObject);
begin
  if Datamodule1.TeilnehmerDataSet.Fieldbyname('ID').AsInteger = 0 then exit;
  AktTeilnehmer := Datamodule1.TeilnehmerDataSet.FieldByName('ID').AsInteger;
  AktTeilnehmerName := Datamodule1.TeilnehmerDataSet.FieldByName('Name').AsString;

  Zeige_Teilnehmer_Menu;

end;

procedure TTeilnehmer.WebButton1Click(Sender: TObject);
begin
  Datamodule1.TeilnehmerDataSet.Close;
  Close;
end;

procedure TTeilnehmer.WebButton2Click(Sender: TObject);
var
  newform: TEditTeilnehmer;

begin
  if Datamodule1.TeilnehmerDataSet.Fieldbyname('ID').AsInteger = 0 then exit;
  if Datamodule1.TeilnehmerDataSet.Fieldbyname('AKtiv').AsInteger = 0 then begin
    MessageDlg('Teilnehmer ist inaktiv gesetzt, ggf. erst aktivieren', mtError, [], nil);
    exit;
  end;

  Teilnehmer_Insert_Mode := false;
  Teilnehmer_Delete_Mode := false;
  Teilnehmer_Activate_Mode := false;
  Teilnehmer_Deactivate_Mode := false;
  Teilnehmer_EChange_Mode := false;

  Datamodule1.TeilnehmerDataSet.Edit;

//  if Create_Form(TEditTeilnehmer,newForm) then ;

  newform := TEditTeilnehmer.CreateNew(procedure(AForm: TObject)
    begin
      (AForm as TEditTeilnehmer).Caption := 'Teilnehmer bearbeiten';
    end
  );
//  newForm.Caption := 'Neues Konto';
  newform.Border := fbSingle;

  newform.ShowModal(procedure(AValue: TModalResult)
  begin
//
  end
  );

end;

procedure TTeilnehmer.WebButton3Click(Sender: TObject);
var i : integer;
  newform: TEditTeilnehmer;
begin
  Teilnehmer_Insert_Mode := true;
  Teilnehmer_Delete_Mode := false;
  Teilnehmer_Activate_Mode := false;
  Teilnehmer_Deactivate_Mode := false;
  Teilnehmer_EChange_Mode := false;

  Datamodule1.TeilnehmerDataSet.Last;
  i := Datamodule1.TeilnehmerDataSet.FieldByName('lfdno').AsInteger;
  Datamodule1.TeilnehmerDataSet.Insert;
  Datamodule1.TeilnehmerDataSet.FieldByName('ID').AsInteger := 0;
  Datamodule1.TeilnehmerDataSet.FieldByName('NL_Key').AsInteger := AktFirmaNo;
  Datamodule1.TeilnehmerDataSet.FieldByName('KDID').AsInteger := Datamodule1.KundenDataSet.Fieldbyname('ID').AsInteger;
  Datamodule1.TeilnehmerDataSet.FieldByName('lfdno').AsInteger := i + 1;

//  if Create_Form(TEditTeilnehmer,newForm) then ;

  newform := TEditTeilnehmer.CreateNew(procedure(AForm: TObject)
    begin
      (AForm as TEditTeilnehmer).Caption := 'Teilnehmer bearbeiten';
    end
  );
//  newForm.Caption := 'Neues Konto';
  newform.Border := fbSingle;

  newform.ShowModal(procedure(AValue: TModalResult)
  begin
//    Datamodule1.TeilnehmerDataSet.AfterOpen := After_Insert_Teilnehmer;
//    if Lade_Daten(Datamodule1.TeilnehmerDataSet,'Teilnehmer','$filter=((NL_Key eq '+IntToStr(AktFirmaNo)+' AND (KDID eq '
//      + Datamodule1.KundenDataSet.Fieldbyname('ID').AsString+')))') then begin
//    end;
//
  end
  );



end;

procedure TTeilnehmer.WebButton4Click(Sender: TObject);
var
  newform: TEditTeilnehmer;

begin
  if Datamodule1.TeilnehmerDataSet.Fieldbyname('ID').AsInteger = 0 then exit;

  Teilnehmer_Insert_Mode := false;
  Teilnehmer_Delete_Mode := true;
  Teilnehmer_Activate_Mode := false;
  Teilnehmer_Deactivate_Mode := false;
  Teilnehmer_EChange_Mode := false;


  Datamodule1.TeilnehmerDataSet.Edit;

//  if Create_Form(TEditTeilnehmer,newForm) then ;


  newform := TEditTeilnehmer.CreateNew(procedure(AForm: TObject)
    begin
      (AForm as TEditTeilnehmer).Caption := 'Teilnehmer löschen';
    end
  );
//  newForm.Caption := 'Neues Konto';
  newform.Border := fbSingle;

  newform.ShowModal(procedure(AValue: TModalResult)
  begin
//
  end
  );


{
Teilnehmer_Delete_Mode
  if Meldungs_Fenster('Aktuellen Datensatz löschen?',mtConfirmation) = MrYes  then ;
    Datamodule1.TeilnehmerDataSet.Delete;
    DataModule1.TeilnehmerDataSet.ApplyUpdates;
}
end;

procedure TTeilnehmer.WebButton5Click(Sender: TObject);
var
  newform: TEditTeilnehmer;

begin
  if Datamodule1.TeilnehmerDataSet.Fieldbyname('ID').AsInteger = 0 then exit;
  if Datamodule1.TeilnehmerDataSet.Fieldbyname('AKtiv').AsInteger = 0 then begin
    MessageDlg('Teilnehmer ist bereits inaktiv', mtError, [], nil);
    exit;
  end;
  Teilnehmer_Insert_Mode := false;
  Teilnehmer_Delete_Mode := false;
  Teilnehmer_Activate_Mode := false;
  Teilnehmer_Deactivate_Mode := true;
  Teilnehmer_EChange_Mode := false;

  Datamodule1.TeilnehmerDataSet.Edit;

//  if create_Form(TEditTeilnehmer,newForm) then ;

  newform := TEditTeilnehmer.CreateNew(procedure(AForm: TObject)
    begin
      (AForm as TEditTeilnehmer).Caption := 'Teilnehmer bearbeiten';
    end
  );
//  newForm.Caption := 'Neues Konto';
  newform.Border := fbSingle;

  newform.ShowModal(procedure(AValue: TModalResult)
  begin
//
  end
  );


end;

procedure TTeilnehmer.WebButton6Click(Sender: TObject);

begin
  if Datamodule1.TeilnehmerDataSet.Fieldbyname('ID').AsInteger = 0 then exit;

  AktTeilnehmer := Datamodule1.TeilnehmerDataSet.FieldByName('ID').AsInteger;
  AktTeilnehmerName := Datamodule1.TeilnehmerDataSet.FieldByName('Name').AsString;
  Datamodule1.AllergieDataSet.AfterOpen := Zeige_Allergie_Fenster;
  if Lade_Daten(Datamodule1.AllergieDataSet,'TEILNEHMER_ALLERGIEN','$filter=TEILNEHMERID eq '+IntToStr(AktTeilnehmer)) then begin
  end;

end;

procedure TTeilnehmer.WebButton7Click(Sender: TObject);

begin
  if Datamodule1.TeilnehmerDataSet.Fieldbyname('ID').AsInteger = 0 then exit;

  AktTeilnehmer := Datamodule1.TeilnehmerDataSet.FieldByName('ID').AsInteger;
  AktTeilnehmerName := Datamodule1.TeilnehmerDataSet.FieldByName('Name').AsString;
  Datamodule1.VerpflegungsArtDataSet.AfterOpen := Zeige_Wochenplan;
  if Lade_Daten(Datamodule1.VerpflegungsArtDataSet,'EINRICHTUNGENVERPFLEGUNG','$filter=NL_Key eq '+IntToStr(AktFirmaNo)) then begin
  end;



end;

procedure TTeilnehmer.WebButton8Click(Sender: TObject);
var
  newform: TEditTeilnehmer;

begin
  if Datamodule1.TeilnehmerDataSet.Fieldbyname('ID').AsInteger = 0 then exit;
  if Datamodule1.TeilnehmerDataSet.Fieldbyname('AKtiv').AsInteger = 1 then begin
    MessageDlg('Teilnehmer ist bereits aktiv', mtError, [], nil);
    exit;
  end;
  Teilnehmer_Insert_Mode := false;
  Teilnehmer_Delete_Mode := false;
  Teilnehmer_Activate_Mode := true;
  Teilnehmer_Deactivate_Mode := false;
  Teilnehmer_EChange_Mode := false;

  Datamodule1.TeilnehmerDataSet.Edit;

//  if Create_Form(TEditTeilnehmer,newForm) then ;

  newform := TEditTeilnehmer.CreateNew(procedure(AForm: TObject)
    begin
      (AForm as TEditTeilnehmer).Caption := 'Teilnehmer bearbeiten';
    end
  );
//  newForm.Caption := 'Neues Konto';
  newform.Border := fbSingle;

  newform.ShowModal(procedure(AValue: TModalResult)
  begin
//
  end
  );


end;

procedure TTeilnehmer.WebButton9Click(Sender: TObject);
var
  newform: TEditSubAccount;

begin
  if Datamodule1.TeilnehmerDataSet.Fieldbyname('ID').AsInteger = 0 then exit;
  if Datamodule1.TeilnehmerDataSet.Fieldbyname('AKtiv').AsInteger = 0 then begin
    MessageDlg('Teilnehmer ist inaktiv gesetzt, ggf. erst aktivieren', mtError, [], nil);
    exit;
  end;

  Datamodule1.TeilnehmerDataSet.Edit;

  newform := TEditSubAccount.CreateNew(procedure(AForm: TObject)
    begin
      (AForm as TEditSubAccount).Caption := 'Teilnehmer bearbeiten';
    end
  );
//  newForm.Caption := 'Neues Konto';
  newform.Border := fbSingle;

  newform.ShowModal(procedure(AValue: TModalResult)
  begin
//
  end
  );

end;

procedure TTeilnehmer.WebFormClose(Sender: TObject; var Action: TCloseAction);
begin
  Zeilen_Liste.Clear;
  Zeilen_Liste.Free;

end;

procedure TTeilnehmer.WebFormCreate(Sender: TObject);
begin
  Form_Aktiv := false;

end;

procedure TTeilnehmer.WebFormResize(Sender: TObject);
begin
  if not Form_Aktiv then exit;
  Resize_Edit_Controls(WF_Grid,self,Zeilen_Liste,WebPanel1,WebPanel3);

end;

procedure TTeilnehmer.WebFormShow(Sender: TObject);
begin

  Resize_Top_Panel(self,WebPanel1);
  Resize_Bottom_Panel(self,WebPanel3);
  Zeilen_Liste := TStringList.Create;
  Zeilen_Count := 0;
  Form_Aktiv := true;
  Resize_Edit_Controls(WF_Grid,self,Zeilen_Liste,WebPanel1,WebPanel3);

  WebDBGrid1.Datasource := DataModule1.TeilnehmerDataSource;


  if not Datamodule1.EinrichtungDataSet.Active then begin
    if Lade_Daten(Datamodule1.EinrichtungDataSet,'Einrichtungen','$filter=NL_Key eq '+IntToStr(AktFirmaNo)) then begin
    end;
  end;
  if not Datamodule1.VariantenDataSet.Active then begin
    if Lade_Daten(Datamodule1.VariantenDataSet,'ESSENSVARIANTEN','$filter=NL_Key eq '+IntToStr(AktFirmaNo)) then begin
    end;
  end;



//  Datamodule1.TeilnehmerDataSet.Active := false;
//  Datamodule1.TeilnehmerDataSet.Active := true;

end;



procedure TTeilnehmer.WebTimer1Timer(Sender: TObject);
begin
  WebTimer1.Enabled := false;
  case TeilnehmerMenuAuswahl of
    1 : WebButton2Click(Self);
    2 : WebButton10Click(Self);
    3 : WebButton8Click(Self);
    4 : WebButton5Click(Self);
    5 : WebButton6Click(Self);
    6 : WebButton7Click(Self);
    7 : WebButton9Click(Self);
    8 : WebButton4Click(Self);

  end;

end;

procedure TTeilnehmer.After_Insert_Teilnehmer(DataSet: TDataSet);
begin
  Datamodule1.TeilnehmerDataSet.Last;
end;


procedure TTeilnehmer.Zeige_Allergie_Fenster(DataSet: TDataSet);
var newform : TVWAllergien;
begin
//  if Create_Form(TVWAllergien,newForm) then ;


  newform := TVWAllergien.CreateNew(procedure(AForm: TObject)
    begin
      (AForm as TVWAllergien).Caption := 'Teilnehmer bearbeiten';
    end
  );
  newform.Border := fbSingle;
  newform.ShowModal(procedure(AValue: TModalResult)
  begin
//
  end
  );



end;

procedure TTeilnehmer.Zeige_Wochenplan(DataSet: TDataSet);
var newform : TWochenplan;
begin

//  if Create_Form(TWochenplan,newForm) then ;

  newform := TWochenplan.CreateNew(procedure(AForm: TObject)
    begin
      (AForm as TWochenplan).Caption := 'Teilnehmer bearbeiten';
    end
  );
  newform.Border := fbSingle;
  newform.ShowModal(procedure(AValue: TModalResult)
  begin
//
  end
  );



end;


procedure TTeilnehmer.Zeige_Teilnehmer_Menu;
var
    Newform: TTeilnMenu;

begin
  TeilnehmerMenuAuswahl := -1;
  Newform := TTeilnMenu.CreateNew(procedure(AForm: TObject)
    begin
      (AForm as TTeilnMenu).Caption := 'Teilnehmer';
    end
  );
  Newform.Border := fbSingle;
  Newform.ShowModal(procedure(AValue: TModalResult)
  begin
    WebTimer1.Enabled := true;
  end
  );
end;





procedure TTeilnehmer.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebPanel1 := TPanel.Create(Self);
  WebDBGrid1 := TDBGrid.Create(Self);
  WebPanel2 := TPanel.Create(Self);
  WebButton3 := TButton.Create(Self);
  WebButton11 := TButton.Create(Self);
  WebPanel3 := TPanel.Create(Self);
  WebButton1 := TButton.Create(Self);
  WebTimer1 := TTimer.Create(Self);

  WebPanel1.BeforeLoadDFMValues;
  WebDBGrid1.BeforeLoadDFMValues;
  WebPanel2.BeforeLoadDFMValues;
  WebButton3.BeforeLoadDFMValues;
  WebButton11.BeforeLoadDFMValues;
  WebPanel3.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  WebTimer1.BeforeLoadDFMValues;
  try
    Name := 'Teilnehmer';
    Left := 0;
    Top := 0;
    Width := 403;
    Height := 500;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -16;
    Font.Name := 'Arial';
    Font.Style := [];
    TabOrder := 1;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnResize', 'WebFormResize');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 0;
    WebPanel1.Top := 0;
    WebPanel1.Width := 403;
    WebPanel1.Height := 89;
    WebPanel1.Align := alTop;
    WebPanel1.BorderColor := clSilver;
    WebPanel1.BorderStyle := bsSingle;
    WebPanel1.ChildOrder := 1;
    WebDBGrid1.SetParentComponent(Self);
    WebDBGrid1.Name := 'WebDBGrid1';
    WebDBGrid1.Left := 0;
    WebDBGrid1.Top := 153;
    WebDBGrid1.Width := 403;
    WebDBGrid1.Height := 287;
    WebDBGrid1.Align := alClient;
    WebDBGrid1.Columns.Clear;
    with WebDBGrid1.Columns.Add do
    begin
      DataField := 'AKTIV';
      DataType := cdCheck;
      Title := 'Aktiv';
      Width := 40;
    end;
    with WebDBGrid1.Columns.Add do
    begin
      DataField := 'NAME';
      Title := 'Name';
      Width := 239;
    end;
    with WebDBGrid1.Columns.Add do
    begin
      DataField := 'KZ_SUBACCOUNT';
      DataType := cdCheck;
      Title := 'Unterkonto';
      Width := 88;
    end;
    WebDBGrid1.FixedCols := 1;
    WebDBGrid1.Options := [goFixedVertLine,goFixedHorzLine,goVertLine,goHorzLine,goRangeSelect,goTabs,goRowSelect];
    WebDBGrid1.TabOrder := 1;
    WebDBGrid1.ShowIndicator := False;
    WebDBGrid1.ColWidths[0] := 20;
    WebDBGrid1.ColWidths[1] := 40;
    WebDBGrid1.ColWidths[2] := 239;
    WebDBGrid1.ColWidths[3] := 88;
    WebDBGrid1.RowHeights[0] := 22;
    WebDBGrid1.RowHeights[1] := 22;
    WebDBGrid1.RowHeights[2] := 22;
    WebDBGrid1.RowHeights[3] := 22;
    WebDBGrid1.RowHeights[4] := 22;
    WebPanel2.SetParentComponent(Self);
    WebPanel2.Name := 'WebPanel2';
    WebPanel2.Left := 0;
    WebPanel2.Top := 89;
    WebPanel2.Width := 403;
    WebPanel2.Height := 64;
    WebPanel2.Align := alTop;
    WebPanel2.BorderColor := clSilver;
    WebPanel2.BorderStyle := bsSingle;
    WebPanel2.ChildOrder := 2;
    WebButton3.SetParentComponent(WebPanel2);
    WebButton3.Name := 'WebButton3';
    WebButton3.Left := 3;
    WebButton3.Top := 16;
    WebButton3.Width := 166;
    WebButton3.Height := 25;
    WebButton3.Caption := 'Neuer Teilnehmer';
    WebButton3.ChildOrder := 3;
    SetEvent(WebButton3, Self, 'OnClick', 'WebButton3Click');
    WebButton11.SetParentComponent(WebPanel2);
    WebButton11.Name := 'WebButton11';
    WebButton11.Left := 175;
    WebButton11.Top := 16;
    WebButton11.Width := 178;
    WebButton11.Height := 25;
    WebButton11.Caption := 'Teilnehmer bearbeiten';
    WebButton11.ChildOrder := 6;
    SetEvent(WebButton11, Self, 'OnClick', 'WebButton11Click');
    WebPanel3.SetParentComponent(Self);
    WebPanel3.Name := 'WebPanel3';
    WebPanel3.Left := 0;
    WebPanel3.Top := 440;
    WebPanel3.Width := 403;
    WebPanel3.Height := 60;
    WebPanel3.Align := alBottom;
    WebPanel3.BorderColor := clSilver;
    WebPanel3.BorderStyle := bsSingle;
    WebPanel3.ChildOrder := 3;
    WebButton1.SetParentComponent(WebPanel3);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 117;
    WebButton1.Top := 6;
    WebButton1.Width := 81;
    WebButton1.Height := 39;
    WebButton1.Caption := 'Zur'#252'ck';
    WebButton1.ChildOrder := 3;
    WebButton1.ElementClassName := 'btn-primary';
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    WebTimer1.SetParentComponent(Self);
    WebTimer1.Name := 'WebTimer1';
    WebTimer1.Enabled := False;
    WebTimer1.Interval := 50;
    SetEvent(WebTimer1, Self, 'OnTimer', 'WebTimer1Timer');
    WebTimer1.Left := 32;
    WebTimer1.Top := 24;
  finally
    WebPanel1.AfterLoadDFMValues;
    WebDBGrid1.AfterLoadDFMValues;
    WebPanel2.AfterLoadDFMValues;
    WebButton3.AfterLoadDFMValues;
    WebButton11.AfterLoadDFMValues;
    WebPanel3.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    WebTimer1.AfterLoadDFMValues;
  end;
end;

end.