unit UHMenu;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.StdCtrls, DB,
  UDatamodule,
  UGlobvar,
  UAnzSpeiseplan,
  UGetAuswahl,
  UKontoMenu,
  UConfirmEmail, WEBLib.ExtCtrls;

type
  THMenu = class(TForm)
    WebButton1: TButton;
    WebButton4: TButton;
    WebButton6: TButton;
    WebPanel1: TPanel;
    WebButton2: TButton;
    procedure WebButton1Click(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
    procedure WebButton4Click(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
    procedure WebButton6Click(Sender: TObject);
    procedure Zeige_Speiseplan(DataSet: TDataSet);
    procedure WebFormResize(Sender: TObject);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
    procedure WebButton2Click(Sender: TObject);
    procedure Zeige_Konto_Menu(DataSet: TDataSet);
  private
    { Private-Deklarationen }
  protected
    procedure LoadDFMValues; override;
  public
    { Public-Deklarationen }
    Confirm_Modus : boolean;

    Zeilen_Liste : TStringList;
    Zeilen_Count : integer;
    Form_Aktiv : boolean;

  end;

var
  HMenu: THMenu;



// procedure Zeige_Speiseplan(DataSet: TDataSet);


implementation


procedure THMenu.Zeige_Speiseplan(DataSet: TDataSet);
var
  newform: TAnzSpeiseplan;

begin
  if Datamodule1.TeilnehmerDataSet.RecordCount = 0 then begin
    MessageDlg('Keine Essensteilnehmer erfasst (Menü "Konto bearbeiten")', mtError, [], nil);
    exit;
  end;
//  if Create_Form(TAnzSpeiseplan,newForm) then ;



  newform := TAnzSpeiseplan.CreateNew(procedure(AForm: TObject)
    begin
      (AForm as TAnzSpeiseplan).Caption := 'Speiseplan';
    end
  );
//  newForm.Caption := 'Neues Konto';
  newform.Border := fbSingle;

  newform.ShowModal(procedure(AValue: TModalResult)
  begin
//    ShowMessage('Form 2 closed with new value:');
//    WebEdit1.Text := newform.frm2Edit.Text;
  end
  );


end;


procedure THMenu.WebButton1Click(Sender: TObject);
var
  newform: TConfirmEMail;

begin
  if Confirm_Modus then begin
//    if Create_Form(TConfirmEMail,NewForm) then ;

    newform := TConfirmEMail.CreateNew(procedure(AForm: TObject)
    begin
      (AForm as TConfirmEMail).Caption := 'Email bestätigen';
    end
    );
    newform.Border := fbSingle;
    newform.ShowModal(procedure(AValue: TModalResult)
    begin
    //
    end
    );


  end
  else begin
    Abbestell_Modus := 0;
    Datamodule1.TeilnehmerDataSet.Close;
    Datamodule1.TeilnehmerDataSet.AfterOpen := Zeige_Speiseplan;
    Datamodule1.TeilnehmerDataSet.QueryString := '$filter=KDID eq '+IntToStr(AktKDID);
    Datamodule1.TeilnehmerDataSet.Load;
  end;
end;

procedure THMenu.WebButton2Click(Sender: TObject);
begin
  MessageDlg('Modul nicht installiert', mtError, [], nil);
  exit;

end;

procedure THMenu.WebButton4Click(Sender: TObject);
begin
  Datamodule1.EinrichtungDataSet.Close;
  Datamodule1.EinrichtungDataSet.AfterOpen := Zeige_Konto_Menu;
  Datamodule1.EinrichtungDataSet.QueryString :='$filter=NL_Key eq '+IntToStr(AktFirmaNo);
  Datamodule1.EinrichtungDataSet.Load;

end;

procedure THMenu.WebButton6Click(Sender: TObject);
begin
  window.localStorage.setItem(cStorageTokenName, '');
  Akt_JWToken := '';
  AktKDName := '';
  AktKDID := 0;
  AktTeilnehmerID := 0;
  AktEinrichtungID := 0;
  AktTeilnehmer := 0;
  AktTeilnehmerName := '';
  AktEinrichtung := 0;
  AktEinrichtungName := '';
  Datamodule1.XDataWebConnection1.Connected := false;
  Datamodule1.XDataWebConnection2.Connected := false;
  Datamodule1.XDataWebConnection3.Connected := false;


//  Datamodule1.XDataWebConnection1.Connected := false;
//  Datamodule1.XDataWebConnection3.Connected := false;

  Close;
end;

procedure THMenu.WebFormClose(Sender: TObject; var Action: TCloseAction);
begin
  Zeilen_Liste.Clear;
  Zeilen_Liste.Free;

end;

procedure THMenu.WebFormCreate(Sender: TObject);
begin
  Form_Aktiv := false;

end;

procedure THMenu.WebFormResize(Sender: TObject);
begin
  if not Form_Aktiv then exit;
  Resize_Edit_Controls(WF_Menu,self,Zeilen_Liste,WebPanel1,nil);

end;

procedure THMenu.WebFormShow(Sender: TObject);
var b : boolean;
begin

  Resize_Top_Panel(self,WebPanel1);
  Zeilen_Liste := TStringList.Create;
  Zeilen_Count := 0;
  Resize_Edit_Controls(WF_Menu,self,Zeilen_Liste,WebPanel1,nil);
  Form_Aktiv := true;



  Confirm_Modus := false;
  b := DataModule1.KundenDataSet.FieldByName('Status').AsInteger > 0;
  if not b then begin
    Confirm_Modus := true;
    WebButton1.Caption := 'Email-Adresse bestätigen';
    WebButton4.Caption := 'Email nochmals anfordern';
    WebButton1.Visible := true;
    WebButton4.Visible := true;


  end
  else begin
    WebButton1.Visible := b;
    WebButton4.Visible := b;
  end;
  if UnterKonto_Aktiv then begin
    WebButton4.Visible := false;
  end;
end;



procedure THMenu.Zeige_Konto_Menu(DataSet: TDataSet);
var
  newform: TKontoMenu;
begin
//  if Create_Form(TKontoMenu,NewForm) then ;


  newform := TKontoMenu.CreateNew(procedure(AForm: TObject)
    begin
      (AForm as TKontoMenu).Caption := 'Konto bearbeiten';
    end
  );
  newform.Border := fbSingle;

  newform.ShowModal(procedure(AValue: TModalResult)
  begin
    if EMail_Geaendert then begin
      window.localStorage.setItem(cStorageTokenName, '');
      Akt_JWToken := '';
      AktKDName := '';
      AktKDID := 0;
      AktTeilnehmerID := 0;
      AktEinrichtungID := 0;
      AktTeilnehmer := 0;
      AktTeilnehmerName := '';
      AktEinrichtung := 0;
      AktEinrichtungName := '';
      Close;
    end;

//
  end
  );



end;





procedure THMenu.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebButton1 := TButton.Create(Self);
  WebButton4 := TButton.Create(Self);
  WebButton6 := TButton.Create(Self);
  WebPanel1 := TPanel.Create(Self);
  WebButton2 := TButton.Create(Self);

  WebButton1.BeforeLoadDFMValues;
  WebButton4.BeforeLoadDFMValues;
  WebButton6.BeforeLoadDFMValues;
  WebPanel1.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  try
    Name := 'HMenu';
    Left := 0;
    Top := 0;
    Width := 640;
    Height := 523;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Arial';
    Font.Style := [];
    TabOrder := 1;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnResize', 'WebFormResize');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    WebButton1.SetParentComponent(Self);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 8;
    WebButton1.Top := 103;
    WebButton1.Width := 273;
    WebButton1.Height := 73;
    WebButton1.Caption := 'Speiseplan, Essen ausw'#228'hlen oder stornieren, Bewertungen';
    WebButton1.ElementClassName := 'btn btn-primary';
    WebButton1.Font.Charset := DEFAULT_CHARSET;
    WebButton1.Font.Color := clWindowText;
    WebButton1.Font.Height := -16;
    WebButton1.Font.Name := 'Arial';
    WebButton1.Font.Style := [];
    WebButton1.ParentFont := False;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    WebButton4.SetParentComponent(Self);
    WebButton4.Name := 'WebButton4';
    WebButton4.Left := 8;
    WebButton4.Top := 197;
    WebButton4.Width := 273;
    WebButton4.Height := 73;
    WebButton4.Caption := 'Konto bearbeiten';
    WebButton4.ChildOrder := 3;
    WebButton4.ElementClassName := 'btn btn-primary';
    WebButton4.Font.Charset := DEFAULT_CHARSET;
    WebButton4.Font.Color := clWindowText;
    WebButton4.Font.Height := -16;
    WebButton4.Font.Name := 'Arial';
    WebButton4.Font.Style := [];
    WebButton4.ParentFont := False;
    SetEvent(WebButton4, Self, 'OnClick', 'WebButton4Click');
    WebButton6.SetParentComponent(Self);
    WebButton6.Name := 'WebButton6';
    WebButton6.Left := 8;
    WebButton6.Top := 385;
    WebButton6.Width := 273;
    WebButton6.Height := 73;
    WebButton6.Caption := 'Programm beenden';
    WebButton6.ChildOrder := 5;
    WebButton6.ElementClassName := 'btn btn-primary';
    WebButton6.Font.Charset := DEFAULT_CHARSET;
    WebButton6.Font.Color := clWindowText;
    WebButton6.Font.Height := -16;
    WebButton6.Font.Name := 'Arial';
    WebButton6.Font.Style := [];
    WebButton6.ParentFont := False;
    SetEvent(WebButton6, Self, 'OnClick', 'WebButton6Click');
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 0;
    WebPanel1.Top := 0;
    WebPanel1.Width := 640;
    WebPanel1.Height := 89;
    WebPanel1.Align := alTop;
    WebPanel1.BorderColor := clSilver;
    WebPanel1.BorderStyle := bsSingle;
    WebPanel1.ChildOrder := 1;
    WebButton2.SetParentComponent(Self);
    WebButton2.Name := 'WebButton2';
    WebButton2.Left := 8;
    WebButton2.Top := 291;
    WebButton2.Width := 273;
    WebButton2.Height := 73;
    WebButton2.Caption := 'Guthaben aufladen (Paypal, Apple Pay, Google Pay etc.)';
    WebButton2.ChildOrder := 4;
    WebButton2.ElementClassName := 'btn btn-primary';
    WebButton2.Enabled := False;
    WebButton2.Font.Charset := DEFAULT_CHARSET;
    WebButton2.Font.Color := clWindowText;
    WebButton2.Font.Height := -16;
    WebButton2.Font.Name := 'Arial';
    WebButton2.Font.Style := [];
    WebButton2.ParentFont := False;
    SetEvent(WebButton2, Self, 'OnClick', 'WebButton2Click');
  finally
    WebButton1.AfterLoadDFMValues;
    WebButton4.AfterLoadDFMValues;
    WebButton6.AfterLoadDFMValues;
    WebPanel1.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
  end;
end;

end.