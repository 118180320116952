unit UGlobvar;

interface

uses
  SysUtils, Classes,  WEBLib.StdCtrls, WEBLib.Controls,JS, Web, WEBLib.Graphics, WEBLib.Forms, WEBLib.Dialogs;

type
  TGlobvar = class(TForm)
  private
    { Private-Deklarationen }
  protected
    procedure LoadDFMValues; override;
  public
    { Public-Deklarationen }
  end;



const
  cStorageTokenName = 'KWSpeiseplan-token';
  cStorageEmailAdresse = 'KWSpeiseplan_Email';



      maxAllergene = 14;

      Action_New = 1;
      Action_Edit = 2;
      Action_Delete = 3;
      Action_First = 4;
      Action_Last = 5;
      Action_Next = 6;
      Action_Prior = 7;
      Action_PgUp = 8;
      Action_PgDn = 9;
      Action_Scan = 10;
      Action_Home = 11;
      Action_Copy = 12;
      Action_Close = 13;
      Action_Cancel = 14;
      Action_Save = 15;
      Action_Print = 16;
      Action_Filter = 17;
      Action_Help = 18;
      Action_Tools = 19;
      Action_Menu = 20;
      Action_Refresh = 21;

      Action_Detail_Plus = 22;
      Action_Detail_Minus = 23;
      Action_Detail_Search = 24;
      Action_Detail_OK = 25;
      Action_Detail_Cancel = 26;
      Action_Search = 27;
      Action_SelectAll = 28;
      Action_UnSelectAll = 29;
      Action_DeleteAll = 30;
      Action_Detail_Menu = 31;
      Action_Einfuegen_Vor = 32;
      Action_Einfuegen_Nach = 33;
      Action_Anhaengen = 34;
      Action_Ersetzen = 35;
      Action_Kein_Bild = 36;
      Action_Ganz_Hoch = 37;
      Action_Hoch = 38;
      Action_Runter = 39;
      Action_Ganz_Runter = 40;
      Action_Verlauf = 41;
      Action_Add_Detail_Plus = 42;
      Action_Add_Detail_Minus = 43;
      Action_Add_Detail_Edit = 44;
      Action_Kalender = 45;
      Action_JahrMinus = 46;
      Action_JahrPlus = 47;
      Action_MonatPlus = 48;
      Action_MonatMinus = 49;
      Action_WocheMinus = 50;
      Action_WochePlus = 51;
      Action_KalenderEdit = 52;
      Action_KalenderLoeschen = 53;
      Action_Publish = 54;

      OS_Unknown = -1;
      OS_IOS = 1;
      OS_OSX = 2;
      OS_Android = 3;
      OS_Windows = 4;

      Unknown_Device = -1;
      IPhone_4 = 1;
      IPhone_5 = 2;
      IPhone_7_8 = 3;
      IPhone_7_8_Plus = 4;
      IPhone_X = 5;
      IPhone_X_max = 6;
      IPhone_X_R = 7;

      Android_320_512 = 101;
      Android_undef = 199;

      Windows_1600_900 = 201;
      Windows_undef = 299;


var
  HauptFenster : TForm;
  Globvar: TGlobvar;
  LogoURL,
  AktPrgName,
  AktFirmaName,
  Akt_JWToken,
  AktKDName : String;
  Laenge_ID_No,
  AktFirmaNo,
  AktKDID,
  AktTeilnehmerID,
  AktEinrichtungID : integer;
  AktTeilnehmer : integer;
  AktTeilnehmerName : String;
  AktEinrichtung : integer;
  AktEinrichtungName : String;
  Akt_Suchbegriff,
  UK_EMail : String;
  UnterKonto_ID,
  VBKDGruppe,
  VBPreisListe : integer;

  Akt_Device_Typ : integer;
  Akt_OS : integer;

  TeilnehmerMenuAuswahl,
  Auswahl_TID,
  Auswahl_TNo : integer;
  Auswahl_TName : String;


  UnterKonto_Aktiv,
  Email_Geaendert : boolean;


  AllergenBez : array[1 .. maxAllergene] of String;
  AllergenID : array[1 .. maxAllergene] of integer;
  SFA : array[1 .. 10] of String;
  Bewertung1 : array[1 .. 6] of String;
  Bewertung2 : array[1 .. 6] of String;
  Bewertung3 : array[1 .. 6] of String;



  NeuKD_Anrede,
  NeuKD_Titel,
  NeuKD_Vname,
  NeuKD_Name,
  NeuKD_PLZ,
  NeuKD_Strasse,
  NeuKD_Ort,
  NeuKD_Kennwort,
  NeuKD_Kennwort2,
  NeuKD_email,
  NeuKD_email2,
  API_URL,
  AUTH_URL : String;
  Akt_SFrage : String;

  Caption_Font_Size,
  Caption_Height,
  Edit_Font_Size,
  Edit_Height,
  Combo_Font_Size,
  Combo_Height     : integer;
  Dist_Cap_Edit : integer;
  Dist_Edit_Cap : integer;
  Bottom_Panel_Height,
  BP_Btn_Height,
  BP_Btn_Font_Size,

  Top_Panel1_Height,
  Top_Panel2_Height,
  Top_Panel3_Height,
  Top_Panel4_Height,
  Top_Panel5_Height,
  Firma_Font_Size,
  Kunden_Font_Size,
  Top_Panel_Zusatz_Font_Size : integer;
  Hinweis_Font_Size : integer;


procedure Init_Globvar;


function Meldungs_Fenster(msgtxt : String;Art : TMsgDlgType) : TModalResult;


procedure Parse_Grid_Field(var AString,FName,DLabel : String;var DWidth : integer);


procedure Parse_Edit_Field(var AString,FName,DLabel : String;var DWidth : integer;var DT,RO,PF : String);


implementation


function Meldungs_Fenster(msgtxt : String;Art : TMsgDlgType) : TModalResult;

 procedure DialogProc(AValue: TModalResult);
 begin
   Meldungs_Fenster := AValue;
 end;

begin
  case Art of
    mtWarning : MessageDlg(msgtxt, mtWarning, [], @DialogProc);
    mtError : MessageDlg(msgtxt, mtError, [], @DialogProc);
    mtInformation : MessageDlg(msgtxt, mtInformation, [], @DialogProc);
    mtConfirmation: MessageDlg(msgtxt, mtConfirmation, [mbYes, mbNo, mbCancel], @DialogProc);
    mtCustom : MessageDlg(msgtxt, mtCustom, [mbAbort, mbRetry, mbClose], @DialogProc);
  end;
end;




procedure Init_Globvar;
var hst : String;

begin
  AktPrgName := '';
  AktFirmaName := '';
  Akt_JWToken := '';
  AktKDName := '';
  AktFirmaNo := 0;
  AktKDID := 0;
  AktTeilnehmerID := 0;
  AktEinrichtungID := 0;
  AktTeilnehmer := 0;
  AktTeilnehmerName := '';
  AktEinrichtung := 0;
  AktEinrichtungName := '';
  LogoURL := '';
  VBKDGruppe := 0;
  VBPreisListe := 0;
  Laenge_ID_No := 10;

  Akt_Device_Typ := Unknown_Device;


  Auswahl_TID := 0;
  Auswahl_TNo := 0;
  Auswahl_TName := '';



  AllergenBez[1] := 'Glutenhaltiges Getreide';
  AllergenBez[2] := 'Krebstiere und daraus gewonnene Erzeugnisse';
  AllergenBez[3] := 'Eier und daraus gewonnene Erzeugnisse';
  AllergenBez[4] := 'Fische und daraus gewonnene Erzeugnisse';
  AllergenBez[5] := 'Erdnüsse und daraus gewonnene Erzeugnisse';
  AllergenBez[6] := 'Sojabohnen und daraus gewonnene Erzeugnisse';
  AllergenBez[7] := 'Milch und daraus gewonnene Erzeugnisse (einschließlich Laktose)';
  AllergenBez[8] := 'Schalenfrüchte';
  AllergenBez[9] := 'Sellerie und daraus gewonnene Erzeugnisse';
  AllergenBez[10] := 'Senf und daraus gewonnene Erzeugnisse';
  AllergenBez[11] := 'Sesamsamen und daraus gewonnene Erzeugnisse';
  AllergenBez[12] := 'Schwefeldioxid und Sulfite';
  AllergenBez[13] := 'Lupinen und daraus gewonnene Erzeugnisse';
  AllergenBez[14] := 'Weichtiere und daraus gewonnene Erzeugnisse';
  AllergenID[1] := 1;
  AllergenID[2] := 2;
  AllergenID[3] := 3;
  AllergenID[4] := 4;
  AllergenID[5] := 5;
  AllergenID[6] := 6;
  AllergenID[7] := 7;
  AllergenID[8] := 8;
  AllergenID[9] := 9;
  AllergenID[10] := 10;
  AllergenID[11] := 11;
  AllergenID[12] := 12;
  AllergenID[13] := 13;
  AllergenID[14] := 14;


  SFA[1] := 'Wie war der Mädchenname Ihrer Mutter?';
  SFA[2] := 'Wie hieß Ihr erstes Haustier?';
  SFA[3] := 'Wie hieß Ihr Lieblingslehrer in der 1. Klasse?';
  SFA[4] := 'Wo verbrachten Sie Ihren ersten Urlaub?';
  SFA[5] := 'Wem wären Sie am liebsten nie begegnet?';
  SFA[6] := '';
  SFA[7] := '';
  SFA[8] := '';
  SFA[9] := '';
  SFA[10] := '';

  Bewertung1[1] := 'Hervorragend';
  Bewertung1[2] := 'Gut';
  Bewertung1[3] := 'Mittelmäßig';
  Bewertung1[4] := 'Nicht gut';
  Bewertung1[5] := 'Schlecht';
  Bewertung1[6] := '';

  Bewertung2[1] := 'Zuviel';
  Bewertung2[2] := 'Ausreichend';
  Bewertung2[3] := 'Genau richtig';
  Bewertung2[4] := 'Zu wenig';
  Bewertung2[5] := '';
  Bewertung2[6] := '';

  Bewertung3[1] := 'Ansprechend';
  Bewertung3[2] := 'In Ordnung';
  Bewertung3[3] := 'Geht so';
  Bewertung3[4] := 'Nicht gut';
  Bewertung3[5] := '';
  Bewertung3[6] := '';




  NeuKD_Anrede := '';
  NeuKD_Titel := '';
  NeuKD_Vname := '';
  NeuKD_Name := '';
  NeuKD_PLZ := '';
  NeuKD_Strasse := '';
  NeuKD_Ort := '';
  NeuKD_Kennwort := '';
  NeuKD_Kennwort2 := '';
  NeuKD_email := '';
  NeuKD_email2 := '';

  API_URL := '';
  AUTH_URL := '';
  Akt_SFrage := '';
  Email_Geaendert := false;
  UnterKonto_Aktiv := false;
  UnterKonto_ID := 0;
  UK_EMail := '';
  Akt_Suchbegriff := '';


  Caption_Font_Size := 10;
  Caption_Height := 16;
  Edit_Font_Size := 12;
  Edit_Height := 22;
  Combo_Font_Size := 12;
  Combo_Height     := 22;
  Dist_Cap_Edit := 3;
  Dist_Edit_Cap := 6;

  Bottom_Panel_Height := 120;
  BP_Btn_Height := 55;
  Top_Panel1_Height := 120;
  Top_Panel2_Height := 120;
  Top_Panel3_Height := 120;
  Top_Panel4_Height := 120;
  Top_Panel5_Height := 120;
  Firma_Font_Size := 14;
  Kunden_Font_Size := 13;
  Top_Panel_Zusatz_Font_Size := 12;
  BP_Btn_Font_Size := 12;
  Hinweis_Font_Size := 16;



end;


procedure Parse_Grid_Field(var AString,FName,DLabel : String;var DWidth : integer);
var hst : String;
    i,j : integer;
begin
  AString := Trim(AString);
  i := pos(';',AString);
  FName := Copy(AString,1,i-1);
  system.Delete(AString,1,i);
  i := pos(';',AString);
  DLabel := Copy(AString,1,i-1);
  system.Delete(AString,1,i);
  i := pos(';',AString);
  hst := Copy(AString,1,i-1);
  system.Delete(AString,1,i);
  DWidth := StrToInt(hst);
end;

procedure Parse_Edit_Field(var AString,FName,DLabel : String;var DWidth : integer;var DT,RO,PF : String);
var hst : String;
    i,j : integer;
begin
  AString := Trim(AString);
  i := pos(';',AString);
  FName := Copy(AString,1,i-1);
  system.Delete(AString,1,i);
  i := pos(';',AString);
  DLabel := Copy(AString,1,i-1);
  system.Delete(AString,1,i);
  i := pos(';',AString);
  hst := Copy(AString,1,i-1);
  system.Delete(AString,1,i);
  DWidth := StrToInt(hst);

  i := pos(';',AString);
  DT := Copy(AString,1,i-1);
  system.Delete(AString,1,i);

  i := pos(';',AString);
  RO := Copy(AString,1,i-1);
  system.Delete(AString,1,i);

  i := pos(';',AString);
  PF := Copy(AString,1,i-1);
  system.Delete(AString,1,i);


end;



procedure TGlobvar.LoadDFMValues;
begin
  inherited LoadDFMValues;


  try
    Name := 'Globvar';
    Left := 0;
    Top := 0;
    Width := 640;
    Height := 480;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Arial';
    Font.Style := [];
    TabOrder := 1;
  finally
  end;
end;

end.