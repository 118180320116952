unit UResetPassword;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.StdCtrls, UGlobvar,
  UDataModule;

type
  TResetPassword = class(TForm)
    Reset_WebLabel_1: TLabel;
    Benutzer: TLabel;
    WebEdit1: TEdit;
    WebLabel2: TLabel;
    WebButton1: TButton;
    procedure WebButton1Click(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
  private
    { Private-Deklarationen }
  protected
    procedure LoadDFMValues; override;
  public
    { Public-Deklarationen }
  end;

var
  ResetPassword: TResetPassword;


procedure Do_ResetPassword;

implementation



procedure Do_ResetPassword;
var
  newform: TResetPassword;
begin

//  if Create_Form(TResetPassword,newForm) then ;

  newform := TResetPassword.CreateNew(procedure(AForm: TObject)
    begin
      (AForm as TResetPassword).Caption := 'Kennwort vergessen';
    end
  );
//  newForm.Caption := 'Neues Konto';
  newform.Border := fbSingle;

  newform.ShowModal(procedure(AValue: TModalResult)
  begin
//
  end
  );


end;




procedure TResetPassword.WebButton1Click(Sender: TObject);
begin
  Close;
end;

procedure TResetPassword.WebFormCreate(Sender: TObject);
begin
  WebEdit1.Text := AktKDName;
  Reset_WebLabel_1.Caption := AktFirmaName;

end;

procedure TResetPassword.LoadDFMValues;
begin
  inherited LoadDFMValues;

  Reset_WebLabel_1 := TLabel.Create(Self);
  Benutzer := TLabel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  WebEdit1 := TEdit.Create(Self);
  WebButton1 := TButton.Create(Self);

  Reset_WebLabel_1.BeforeLoadDFMValues;
  Benutzer.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  WebEdit1.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  try
    Name := 'ResetPassword';
    Left := 0;
    Top := 0;
    Width := 640;
    Height := 480;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Arial';
    Font.Style := [];
    TabOrder := 1;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    Reset_WebLabel_1.SetParentComponent(Self);
    Reset_WebLabel_1.Name := 'Reset_WebLabel_1';
    Reset_WebLabel_1.Left := 24;
    Reset_WebLabel_1.Top := 8;
    Reset_WebLabel_1.Width := 147;
    Reset_WebLabel_1.Height := 19;
    Reset_WebLabel_1.Caption := 'Reset_WebLabel_1';
    Reset_WebLabel_1.Font.Charset := DEFAULT_CHARSET;
    Reset_WebLabel_1.Font.Color := clWindowText;
    Reset_WebLabel_1.Font.Height := -16;
    Reset_WebLabel_1.Font.Name := 'Arial';
    Reset_WebLabel_1.Font.Style := [fsBold];
    Reset_WebLabel_1.ParentFont := False;
    Benutzer.SetParentComponent(Self);
    Benutzer.Name := 'Benutzer';
    Benutzer.Left := 24;
    Benutzer.Top := 53;
    Benutzer.Width := 44;
    Benutzer.Height := 14;
    Benutzer.Caption := 'Benutzer';
    WebLabel2.SetParentComponent(Self);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 29;
    WebLabel2.Top := 116;
    WebLabel2.Width := 249;
    WebLabel2.Height := 14;
    WebLabel2.Caption := 'Eine Email mit Ihren Anmeldedaten wurde verschickt';
    WebEdit1.SetParentComponent(Self);
    WebEdit1.Name := 'WebEdit1';
    WebEdit1.Left := 88;
    WebEdit1.Top := 50;
    WebEdit1.Width := 209;
    WebEdit1.Height := 22;
    WebEdit1.TabStop := False;
    WebEdit1.ChildOrder := 7;
    WebEdit1.ReadOnly := True;
    WebEdit1.Text := 'WebEdit1';
    WebButton1.SetParentComponent(Self);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 64;
    WebButton1.Top := 168;
    WebButton1.Width := 96;
    WebButton1.Height := 25;
    WebButton1.Caption := 'Zur'#252'ck';
    WebButton1.ChildOrder := 4;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
  finally
    Reset_WebLabel_1.AfterLoadDFMValues;
    Benutzer.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    WebEdit1.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
  end;
end;

end.