unit ULogin;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.StdCtrls, DB,
  XData.Web.JsonDataset,
  XData.Web.Dataset,
  XData.Web.Client,
  XData.Web.Connection,

  WEBLib.DB,

  WEBLib.ExtCtrls,
  UDataModule, WEBLib.DBCtrls,
  Types,
  UGlobvar,
  UNeuesKonto,
  UNeuesKonto2,
//  UMain_form,
  UHMenu,
  UHMenu2,
  UResetPassword,
  UCheckSichFrage,
  UWaitConnect,
  UWaitConnect2,
  UWaitConnect3,
  UAnzPDF,
  App.Config;



type

  TLoginProc = reference to function(const UserName, Password: string): Boolean;


  TLoginForm = class(TForm)
    Login_WebLabel1: TLabel;
    WebEdit1: TEdit;
    WebLabel2: TLabel;
    WebEdit2: TEdit;
    WebImageControl1: TImageControl;
    WebButton3: TButton;
    WebPanel1: TPanel;
    WebButton1: TButton;
    WebButton2: TButton;
    WebLabel4: TLabel;
    WebLabel5: TLabel;
    WebLabel6: TLabel;
    WebLabel7: TLabel;
    WebLabel9: TLabel;
    WebLabel8: TLabel;
    WebLabel1: TLabel;
    WebLabel3: TLabel;
    procedure WebButton1Click(Sender: TObject);
    procedure WebButton2Click(Sender: TObject);
    procedure WebButton3Click(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
    procedure XDataWebConnection1Request(Args: TXDataWebConnectionRequest);
    function Check_Email_Reset : boolean;
    procedure Zeige_Haupt_Menu(DataSet: TDataSet);
    procedure Zeige_Haupt_Menu_2(DataSet: TDataSet);
    procedure Connected_to_Server(Sender: TObject);
    procedure Connected_to_Server_1(Sender: TObject);
    procedure Connected_to_Server_3(Sender: TObject);
    procedure Connect_APIServer;
    procedure WebButton4Click(Sender: TObject);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
    procedure ServerError_1(Error: TXDataWebConnectionError);
    procedure ServerError_2(Error: TXDataWebConnectionError);
    procedure ServerError_3(Error: TXDataWebConnectionError);
    procedure WebFormResize(Sender: TObject);
    procedure WebLabel8Click(Sender: TObject);
  private
    { Private-Deklarationen }
    FOnLogin: TLoginProc;
    FOnCreateAccount: TLoginProc;

    procedure CheckLogin;
    procedure DoLogout;
    procedure UpdateButtons;
    procedure DisableTasks;
    procedure EnableTasks;
    function GetToken: string;
  protected
    procedure LoadDFMValues; override;

  public
    { Public-Deklarationen
    class function DoLogin(LoginProc, CreateAccountProc: TLoginProc): Boolean;
    property OnLogin: TLoginProc read FOnLogin write FOnLogin;
    property OnCreateAccount: TLoginProc read FOnCreateAccount write FOnCreateAccount;
}
    KDID,
    TNID : integer;

    Zeilen_Liste : TStringList;
    Zeilen_Count : integer;
    Form_Aktiv : boolean;


  end;

var
  LoginForm: TLoginForm;

implementation





procedure TLoginForm.DisableTasks;
begin
//  pnlTasks.Visible := False;
//  pnlLogin.Visible := True;
//  pnlTasks.Top := pnlLogin.Top + pnlLogin.Height + 8;
//  Datamodule1.XDataWebDataset.Close;
end;

procedure TLoginForm.DoLogout;
begin
  window.localStorage.RemoveItem(cStorageTokenName);
  CheckLogin;
end;

procedure TLoginForm.EnableTasks;
begin
//  pnlTasks.Visible := True;
//  pnlLogin.Visible := False;
//  pnlTasks.Top := pnlLogin.Top;
//  XDataWebDataset.Close;
//  XDataWebDataset.Load;
end;

function TLoginForm.GetToken: string;
begin
  if not JS.IsNull(window.localStorage.getItem(cStorageTokenName)) then
    Result := window.localStorage.getItem(cStorageTokenName)
  else
    Result := '';
end;



procedure TLoginForm.UpdateButtons;
begin
{
  if XDataWebDataset.Active then
  begin
    btUpdateTask.Enabled := XDataWebDataset.State in [dsEdit, dsInsert];
    btCancel.Enabled := XDataWebDataset.State in [dsInsert, dsEdit];
    btDelete.Enabled := not XDataWebDataset.IsEmpty;
    btNewTask.Enabled := XDataWebDataset.State in [dsBrowse];
  end
  else
  begin
    btUpdateTask.Enabled := false;
    btCancel.Enabled := false;
    btDelete.Enabled := false;
    btNewTask.Enabled := false;
  end;
}
end;



procedure TLoginForm.WebButton1Click(Sender: TObject);
var NLK,hst,
    hst2,hst3 : String;
    Values: TJSArray;
//    Values2: TJSValueDynArray;
    Menuform: THMenu;
    i,j,UKA: integer;
begin
  UnterKonto_Aktiv := false;
  UK_EMail := '';
  UnterKonto_ID := 0;
  hst := Trim(WebEdit1.Text);
  WebEdit1.Text := hst;
  if hst = '' then begin
    MessageDlg('Email-Adresse muß erfasst werden', mtError, [], nil);
    exit;
  end;
  hst2 := Trim(WebEdit2.Text);
  WebEdit2.Text := hst2;
  if hst2 = '' then begin
    MessageDlg('Kennwort muß erfasst werden', mtError, [], nil);
    exit;
  end;
  hst := Uppercase(hst);


  NLK := IntToStr(AktFirmaNo);

  DataModule1.XDataWebClientAuth.RawInvoke('ILoginService.Login', [NLK,hst, hst2],
      procedure(Response: TXDataClientResponse)
      var
        Token: string;
      begin
        Token := JS.ToString(JS.ToObject(Response.Result)['value']);
        if Token <> '' then begin
          i := pos(';',Token);
          hst3 := Copy(Token,1,i-1);
          UKA := StrToInt(hst3);
          system.Delete(Token,1,i);

          i := pos(';',Token);
          hst3 := Copy(Token,1,i-1);
          KDID := StrToInt(hst3);
          system.Delete(Token,1,i);

          i := pos(';',Token);
          hst3 := Copy(Token,1,i-1);
          TNID := StrToInt(hst3);
          system.Delete(Token,1,i);

          if UKA = 1 then begin
            UnterKonto_Aktiv := true;
          end;
          Akt_JWToken := Token;
          AktKDName := WebEdit1.Text;
          window.localStorage.setItem(cStorageEmailAdresse, AktKDName);
          Connect_APIServer;
        end;
      end,

      procedure(Error: TXDataClientError)
      begin
        MessageDlg('Email-Adresse oder Kennwort falsch', mtError, [], nil);
      end
     );


end;

procedure TLoginForm.WebButton2Click(Sender: TObject);
var
  newform: TNeuesKonto2;

begin

//  if Create_Form(TNeuesKonto2,newForm) then ;

  newform := TNeuesKonto2.CreateNew(procedure(AForm: TObject)
    begin
      (AForm as TNeuesKonto2).Caption := 'Neues Konto';
    end
  );
  newform.Border := fbSingle;
  newform.ShowModal(procedure(AValue: TModalResult)
  begin
//
  end
  );


end;


procedure TLoginForm.WebButton3Click(Sender: TObject);
var NLK,hst : String;
    i : integer;
begin
  hst := Trim(WebEdit1.Text);
  WebEdit1.Text := hst;
  if hst = '' then begin
    MessageDlg('Email-Adresse muß erfasst werden', mtError, [], nil);
    exit;
  end;


  NLK := IntToStr(AktFirmaNo);
  Akt_SFrage := '';
  DataModule1.XDataWebClientAuth.RawInvoke('ILoginService.GetSicherheitsfrage', [NLK,hst],
      procedure(Response: TXDataClientResponse)
      var
        F: string;
      begin
        F := JS.ToString(JS.ToObject(Response.Result)['value']);
        if F <> '' then begin
          Akt_SFrage := F;
          AktKDName := WebEdit1.Text;
          if Check_Email_Reset then ;
        end
        else begin
          MessageDlg('Email-Adresse ungültig oder keine Sicherheitsfragen erfasst', mtError, [], nil);
        end;
      end,

      procedure(Error: TXDataClientError)
      begin
        MessageDlg('Keine Verbindung zum Server', mtError, [], nil);

      end
     );

end;

procedure TLoginForm.WebButton4Click(Sender: TObject);
begin
  close;
end;

procedure TLoginForm.WebFormClose(Sender: TObject; var Action: TCloseAction);
begin
//  Datamodule1.XDataWebConnection1.Connected := false;
//  Datamodule1.XDataWebConnection2.Connected := false;
//  Datamodule1.XDataWebConnection3.Connected := false;
  Zeilen_Liste.Clear;
  Zeilen_Liste.Free;

end;

procedure TLoginForm.WebFormCreate(Sender: TObject);
begin
  Application.OnImageCacheReady := WebFormShow;
  Akt_JWToken := '';
  Form_Aktiv := false;
end;

procedure TLoginForm.WebFormResize(Sender: TObject);
begin
  WebLabel1.caption := window.navigator.userAgent;
  WebLabel3.caption := string(window.screen['width'])+':'+string(window.screen['height']);
  if not Form_Aktiv then exit;

  Resize_Edit_Controls(WF_Login,self,Zeilen_Liste,WebImageControl1,WebPanel1);

end;

procedure TLoginForm.WebFormShow(Sender: TObject);
var hst : String;
    newform: TWaitConnect;

begin
  HauptFenster := self;
  application.OnImageCacheReady := nil;
  Akt_JWToken := '';
  WebImageControl1.Url := LogoURL; // 'images/haustein2.bmp';
  hst := window.localStorage.getItem(cStorageEmailAdresse);
  if hst <> '' then begin
    WebEdit1.Text := hst;
  end;
  WebLabel1.caption := window.navigator.userAgent;
  WebLabel3.caption := string(window.screen['width'])+':'+string(window.screen['height']);

  Resize_Top_Panel_5(self,WebImageControl1);
//  Resize_Bottom_Panel(self,WebPanel1);
  Zeilen_Liste := TStringList.Create;
  Zeilen_Count := 0;

  Zeilen_Liste_Add(Zeilen_Liste,Zeilen_Count,WebLabel1,nil,nil);
  Zeilen_Liste_Add(Zeilen_Liste,Zeilen_Count,WebLabel3,nil,nil);

  Zeilen_Liste_Add(Zeilen_Liste,Zeilen_Count,Login_WebLabel1,WebEdit1,nil);
  Zeilen_Liste_Add(Zeilen_Liste,Zeilen_Count,WebLabel2,WebEdit2,nil);
  Zeilen_Liste_Set(Zeilen_Liste,WebEdit2,WebButton3);

  Resize_Edit_Controls(WF_Login,self,Zeilen_Liste,WebImageControl1,WebPanel1);
  Form_Aktiv := true;



//  WebLabel1.caption := window.navigator.userAgent;
//  WebLabel3.caption := string(window.screen['width'])+':'+string(window.screen['height']);

  Datamodule1.XDataWebConnection2.OnConnect := Connected_to_Server;
  Datamodule1.XDataWebConnection2.OnError := ServerError_2;
  Datamodule1.XDataWebConnection2.Connected := True;

  newform := TWaitConnect.CreateNew(procedure(AForm: TObject)
    begin
    end
  );
  newform.Border := fbSingle;
  newform.ShowModal(procedure(AValue: TModalResult)
  begin
  end
  );



end;

procedure TLoginForm.WebLabel8Click(Sender: TObject);
begin
  Anzeige_PDF('Datenschutzerklärung','Datenschutzerklaerung.pdf');
end;

procedure TLoginForm.CheckLogin;
begin
  if GetToken = '' then
    DisableTasks
  else begin

  end;
end;

procedure TLoginForm.XDataWebConnection1Request(Args: TXDataWebConnectionRequest);
begin
  if GetToken <> '' then
    Args.Request.Headers.SetValue('Authorization', 'Bearer ' + GetToken);
end;






procedure TLoginForm.Zeige_Haupt_Menu(DataSet: TDataSet);
var Menuform: THMenu;
    NewForm : THMenu2;

    i : integer;
begin
  if Akt_JWToken = '' then exit;
//  i := DataModule1.KundenDataSet.ServerRecordCount;
  if DataModule1.KundenDataSet.RecordCount = 0 then exit;
  AktKDID := DataModule1.KundenDataset.Fieldbyname('ID').AsInteger;
  if UnterKonto_Aktiv then begin
    AktKDName := DataModule1.KundenDataset.Fieldbyname('EMailAdr').AsString;
  end;
//  ShowMessage(IntToStr(AktKDID));

  if DataModule1.KundenDataSet.Fieldbyname('AdminLevel').AsInteger > 0 then begin
    WebEdit1.Text := '';
    WebEdit2.Text := '';

//    if Create_Form(THMenu2,NewForm) then ;

    Newform := THMenu2.CreateNew(procedure(AForm: TObject)
      begin
        (AForm as THMenu2).Caption := 'Hauptmenü';
      end
    );
    Newform.Border := fbSingle;

    Newform.ShowModal(procedure(AValue: TModalResult)
    begin
      Close;
    end
    );


  end
  else begin
    WebEdit1.Text := '';
    WebEdit2.Text := '';

//    if Create_Form(THMenu,MenuForm) then ;

    Menuform := THMenu.CreateNew(procedure(AForm: TObject)
      begin
        (AForm as THMenu).Caption := 'Hauptmenü';
      end
    );
    Menuform.Border := fbSingle;

    Menuform.ShowModal(procedure(AValue: TModalResult)
    begin
      Close;
    end
    );

  end;
end;



procedure TLoginForm.Zeige_Haupt_Menu_2(DataSet: TDataSet);
var Newform: THMenu2;
    i : integer;
begin
  if Akt_JWToken = '' then exit;
//  i := DataModule1.KundenDataSet.ServerRecordCount;
  if DataModule1.KundenDataSet.RecordCount = 0 then exit;

    WebEdit1.Text := '';
    WebEdit2.Text := '';

//    if Create_Form(THMenu2,NewForm) then ;

    Newform := THMenu2.CreateNew(procedure(AForm: TObject)
      begin
        (AForm as THMenu2).Caption := 'Hauptmenü';
      end
    );
    Newform.Border := fbSingle;

    Newform.ShowModal(procedure(AValue: TModalResult)
    begin
      Close;
    end
    );

end;



function TLoginForm.Check_Email_Reset : boolean;
var newForm : TCheckSichFrage;
begin
  newform := TCheckSichFrage.CreateNew(procedure(AForm: TObject)
      begin
        (AForm as TCheckSichFrage).Caption := 'Sicherheitsfrage';
      end
    );
    newform.Border := fbSingle;

    newform.ShowModal(procedure(AValue: TModalResult)
    begin
    end
    );
end;

procedure TLoginForm.Connected_to_Server(Sender: TObject);
begin
  WebButton1.Enabled := true;
  WebButton2.Enabled := true;
  WebButton3.Enabled := true;
  WebButton1.Visible := true;
  WebButton2.Visible := true;
  WebButton3.Visible := true;

end;


procedure TLoginForm.Connected_to_Server_1(Sender: TObject);
var hst : String;
    newform : TWaitConnect3;
begin
//  Datamodule1.XDataWebConnection3.Connected := True;
//  Connected_to_Server_3(Self);
//  exit;

  Datamodule1.XDataWebConnection3.OnConnect := Connected_to_Server_3;
  Datamodule1.XDataWebConnection3.OnError := ServerError_3;
  Datamodule1.XDataWebConnection3.Connected := True;
  newform := TWaitConnect3.CreateNew(procedure(AForm: TObject)
    begin
    end
  );
  newform.Border := fbSingle;
  newform.ShowModal(procedure(AValue: TModalResult)
  begin
  end
  );

end;

procedure TLoginForm.Connected_to_Server_3(Sender: TObject);
var hst : String;
begin
  DataModule1.KundenDataset.Active := false;
  DataModule1.KundenDataset.AfterOpen := Zeige_Haupt_Menu;
  hst := Trim(Uppercase(WebEdit1.Text));
  if UnterKonto_Aktiv then begin
    DataModule1.KundenDataset.QueryString := '$filter=(NL_Key eq '+IntToStr(AktFirmaNo)+') AND (ID eq '+IntToStr(KDID)+')';
    UnterKonto_ID := TNID;
  end
  else begin
    DataModule1.KundenDataset.QueryString := '$filter=(NL_Key eq '+IntToStr(AktFirmaNo)+') AND (UPC_EMAIL eq '+''''+hst+''''+')';
  end;
  DataModule1.KundenDataset.Load;

end;

procedure TLoginForm.Connect_APIServer;
var
  newform : TWaitConnect2;

begin
  Datamodule1.XDataWebConnection1.OnConnect := Connected_to_Server_1;
  Datamodule1.XDataWebConnection1.OnError := ServerError_1;
  Datamodule1.XDataWebConnection1.Connected := true;

  newform := TWaitConnect2.CreateNew(procedure(AForm: TObject)
    begin
    end
  );
  newform.Border := fbSingle;
  newform.ShowModal(procedure(AValue: TModalResult)
  begin
  end
  );
end;



procedure TLoginForm.ServerError_3(Error: TXDataWebConnectionError);
var hst : String;
begin
  hst := 'Server 3 Fehler: '+Error.ErrorMessage;
  MessageDlg(hst, mtError, [], nil);
end;


procedure TLoginForm.ServerError_2(Error: TXDataWebConnectionError);
var hst : String;
begin
  hst := 'Server 2 Fehler: '+Error.ErrorMessage;
  MessageDlg(hst, mtError, [], nil);
end;

procedure TLoginForm.ServerError_1(Error: TXDataWebConnectionError);
var hst : String;
begin
  hst := 'Server 1 Fehler: '+Error.ErrorMessage;
  MessageDlg(hst, mtError, [], nil);
end;


procedure TLoginForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  Login_WebLabel1 := TLabel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  WebImageControl1 := TImageControl.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  WebLabel3 := TLabel.Create(Self);
  WebEdit1 := TEdit.Create(Self);
  WebEdit2 := TEdit.Create(Self);
  WebButton3 := TButton.Create(Self);
  WebPanel1 := TPanel.Create(Self);
  WebLabel4 := TLabel.Create(Self);
  WebLabel5 := TLabel.Create(Self);
  WebLabel6 := TLabel.Create(Self);
  WebLabel7 := TLabel.Create(Self);
  WebLabel9 := TLabel.Create(Self);
  WebLabel8 := TLabel.Create(Self);
  WebButton1 := TButton.Create(Self);
  WebButton2 := TButton.Create(Self);

  Login_WebLabel1.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  WebImageControl1.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  WebEdit1.BeforeLoadDFMValues;
  WebEdit2.BeforeLoadDFMValues;
  WebButton3.BeforeLoadDFMValues;
  WebPanel1.BeforeLoadDFMValues;
  WebLabel4.BeforeLoadDFMValues;
  WebLabel5.BeforeLoadDFMValues;
  WebLabel6.BeforeLoadDFMValues;
  WebLabel7.BeforeLoadDFMValues;
  WebLabel9.BeforeLoadDFMValues;
  WebLabel8.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  try
    Name := 'LoginForm';
    Left := 0;
    Top := 0;
    Width := 320;
    Height := 500;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Arial';
    Font.Style := [];
    TabOrder := 1;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnResize', 'WebFormResize');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    Login_WebLabel1.SetParentComponent(Self);
    Login_WebLabel1.Name := 'Login_WebLabel1';
    Login_WebLabel1.Left := 16;
    Login_WebLabel1.Top := 239;
    Login_WebLabel1.Width := 83;
    Login_WebLabel1.Height := 16;
    Login_WebLabel1.Caption := 'eMail-Adresse';
    Login_WebLabel1.Font.Charset := DEFAULT_CHARSET;
    Login_WebLabel1.Font.Color := clWindowText;
    Login_WebLabel1.Font.Height := -13;
    Login_WebLabel1.Font.Name := 'Arial';
    Login_WebLabel1.Font.Style := [];
    Login_WebLabel1.ParentFont := False;
    WebLabel2.SetParentComponent(Self);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 16;
    WebLabel2.Top := 289;
    WebLabel2.Width := 54;
    WebLabel2.Height := 16;
    WebLabel2.Caption := 'Kennwort';
    WebLabel2.Font.Charset := DEFAULT_CHARSET;
    WebLabel2.Font.Color := clWindowText;
    WebLabel2.Font.Height := -13;
    WebLabel2.Font.Name := 'Arial';
    WebLabel2.Font.Style := [];
    WebLabel2.ParentFont := False;
    WebImageControl1.SetParentComponent(Self);
    WebImageControl1.Name := 'WebImageControl1';
    WebImageControl1.Left := 8;
    WebImageControl1.Top := 8;
    WebImageControl1.Width := 305;
    WebImageControl1.Height := 185;
    WebImageControl1.ChildOrder := 5;
    WebImageControl1.Picture.LoadFromFile('ULogin.WebImageControl1.Picture.bmp');
    WebLabel1.SetParentComponent(Self);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 16;
    WebLabel1.Top := 200;
    WebLabel1.Width := 54;
    WebLabel1.Height := 14;
    WebLabel1.Caption := 'WebLabel1';
    WebLabel3.SetParentComponent(Self);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Left := 16;
    WebLabel3.Top := 220;
    WebLabel3.Width := 54;
    WebLabel3.Height := 14;
    WebLabel3.Caption := 'WebLabel3';
    WebEdit1.SetParentComponent(Self);
    WebEdit1.Name := 'WebEdit1';
    WebEdit1.Left := 16;
    WebEdit1.Top := 259;
    WebEdit1.Width := 289;
    WebEdit1.Height := 22;
    WebEdit1.AutoCompletion := acUserName;
    WebEdit1.ChildOrder := 1;
    WebEdit1.Font.Charset := DEFAULT_CHARSET;
    WebEdit1.Font.Color := clWindowText;
    WebEdit1.Font.Height := -16;
    WebEdit1.Font.Name := 'Arial';
    WebEdit1.Font.Style := [];
    WebEdit1.ParentFont := False;
    WebEdit2.SetParentComponent(Self);
    WebEdit2.Name := 'WebEdit2';
    WebEdit2.Left := 16;
    WebEdit2.Top := 309;
    WebEdit2.Width := 129;
    WebEdit2.Height := 22;
    WebEdit2.AutoCompletion := acCurrentPassword;
    WebEdit2.ChildOrder := 3;
    WebEdit2.ElementClassName := 'form-control';
    WebEdit2.Font.Charset := DEFAULT_CHARSET;
    WebEdit2.Font.Color := clWindowText;
    WebEdit2.Font.Height := -16;
    WebEdit2.Font.Name := 'Arial';
    WebEdit2.Font.Style := [];
    WebEdit2.ParentFont := False;
    WebEdit2.PasswordChar := '*';
    WebButton3.SetParentComponent(Self);
    WebButton3.Name := 'WebButton3';
    WebButton3.Left := 151;
    WebButton3.Top := 301;
    WebButton3.Width := 154;
    WebButton3.Height := 38;
    WebButton3.Caption := 'Kennwort vergessen';
    WebButton3.ChildOrder := 8;
    WebButton3.ElementClassName := 'btn-secondary';
    WebButton3.Enabled := False;
    WebButton3.Font.Charset := DEFAULT_CHARSET;
    WebButton3.Font.Color := clWindowText;
    WebButton3.Font.Height := -15;
    WebButton3.Font.Name := 'Arial';
    WebButton3.Font.Style := [];
    WebButton3.ParentFont := False;
    WebButton3.Visible := False;
    SetEvent(WebButton3, Self, 'OnClick', 'WebButton3Click');
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 0;
    WebPanel1.Top := 348;
    WebPanel1.Width := 320;
    WebPanel1.Height := 152;
    WebPanel1.Align := alBottom;
    WebPanel1.BorderColor := clSilver;
    WebPanel1.BorderStyle := bsSingle;
    WebPanel1.ChildOrder := 8;
    WebLabel4.SetParentComponent(WebPanel1);
    WebLabel4.Name := 'WebLabel4';
    WebLabel4.Left := 9;
    WebLabel4.Top := 54;
    WebLabel4.Width := 281;
    WebLabel4.Height := 14;
    WebLabel4.Caption := 'Um unsere Webseite f'#252'r Sie optimal zu gestalten und fort- ';
    WebLabel5.SetParentComponent(WebPanel1);
    WebLabel5.Name := 'WebLabel5';
    WebLabel5.Left := 9;
    WebLabel5.Top := 69;
    WebLabel5.Width := 276;
    WebLabel5.Height := 14;
    WebLabel5.Caption := 'laufend verbessern zu k'#246'nnen, verwenden wir Cookies. ';
    WebLabel6.SetParentComponent(WebPanel1);
    WebLabel6.Name := 'WebLabel6';
    WebLabel6.Left := 9;
    WebLabel6.Top := 84;
    WebLabel6.Width := 275;
    WebLabel6.Height := 14;
    WebLabel6.Caption := 'Durch die weitere Nutzung der Webseite stimmen Sie der';
    WebLabel7.SetParentComponent(WebPanel1);
    WebLabel7.Name := 'WebLabel7';
    WebLabel7.Left := 9;
    WebLabel7.Top := 98;
    WebLabel7.Width := 269;
    WebLabel7.Height := 14;
    WebLabel7.Caption := 'Verwendung von Cookies zu. Weitere Informationen zu ';
    WebLabel9.SetParentComponent(WebPanel1);
    WebLabel9.Name := 'WebLabel9';
    WebLabel9.Left := 9;
    WebLabel9.Top := 113;
    WebLabel9.Width := 153;
    WebLabel9.Height := 14;
    WebLabel9.Caption := 'Cookies erhalten Sie in unserer ';
    WebLabel8.SetParentComponent(WebPanel1);
    WebLabel8.Name := 'WebLabel8';
    WebLabel8.Left := 168;
    WebLabel8.Top := 113;
    WebLabel8.Width := 122;
    WebLabel8.Height := 14;
    WebLabel8.Caption := 'Datenschutzerkl'#228'rung';
    WebLabel8.Font.Charset := DEFAULT_CHARSET;
    WebLabel8.Font.Color := clBlue;
    WebLabel8.Font.Height := -11;
    WebLabel8.Font.Name := 'Arial';
    WebLabel8.Font.Style := [fsBold,fsUnderline];
    WebLabel8.ParentFont := False;
    SetEvent(WebLabel8, Self, 'OnClick', 'WebLabel8Click');
    WebButton1.SetParentComponent(WebPanel1);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 8;
    WebButton1.Top := 9;
    WebButton1.Width := 121;
    WebButton1.Height := 41;
    WebButton1.Caption := 'Anmelden';
    WebButton1.ChildOrder := 4;
    WebButton1.ElementClassName := 'btn btn-primary';
    WebButton1.Enabled := False;
    WebButton1.Font.Charset := DEFAULT_CHARSET;
    WebButton1.Font.Color := clWindowText;
    WebButton1.Font.Height := -15;
    WebButton1.Font.Name := 'Arial';
    WebButton1.Font.Style := [];
    WebButton1.ParentFont := False;
    WebButton1.Visible := False;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    WebButton2.SetParentComponent(WebPanel1);
    WebButton2.Name := 'WebButton2';
    WebButton2.Left := 135;
    WebButton2.Top := 9;
    WebButton2.Width := 170;
    WebButton2.Height := 41;
    WebButton2.Caption := 'Neues Konto erstellen';
    WebButton2.ChildOrder := 7;
    WebButton2.ElementClassName := 'btn-secondary';
    WebButton2.Enabled := False;
    WebButton2.Font.Charset := DEFAULT_CHARSET;
    WebButton2.Font.Color := clWindowText;
    WebButton2.Font.Height := -15;
    WebButton2.Font.Name := 'Arial';
    WebButton2.Font.Style := [];
    WebButton2.ParentFont := False;
    WebButton2.Visible := False;
    SetEvent(WebButton2, Self, 'OnClick', 'WebButton2Click');
  finally
    Login_WebLabel1.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    WebImageControl1.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
    WebEdit1.AfterLoadDFMValues;
    WebEdit2.AfterLoadDFMValues;
    WebButton3.AfterLoadDFMValues;
    WebPanel1.AfterLoadDFMValues;
    WebLabel4.AfterLoadDFMValues;
    WebLabel5.AfterLoadDFMValues;
    WebLabel6.AfterLoadDFMValues;
    WebLabel7.AfterLoadDFMValues;
    WebLabel9.AfterLoadDFMValues;
    WebLabel8.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
  end;
end;

end.
