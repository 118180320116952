unit UGetAuswahl;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.StdCtrls, UGlobvar;

type
  TGetAuswahl = class(TForm)
    GA_WebLabel_1: TLabel;
    Benutzer: TLabel;
    WebEdit1: TEdit;
    WebButton1: TButton;
    WebButton2: TButton;
    WebButton3: TButton;
    WebButton4: TButton;
    WebButton5: TButton;
    WebButton6: TButton;
    WebButton7: TButton;
    WebEdit2: TEdit;
    procedure WebButton7Click(Sender: TObject);
    procedure WebButton1Click(Sender: TObject);
    procedure WebButton2Click(Sender: TObject);
    procedure WebButton3Click(Sender: TObject);
    procedure WebButton4Click(Sender: TObject);
    procedure WebButton5Click(Sender: TObject);
    procedure WebButton6Click(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
  private
    { Private-Deklarationen }
  protected
    procedure LoadDFMValues; override;
  public
    { Public-Deklarationen }
  end;

var
  GetAuswahl: TGetAuswahl;

function Get_Auswahl(st : String) : integer;


implementation



function Get_Auswahl(st : String) : integer;
var
  newform: TGetAuswahl;
  hst,
  hst2 : String;
  i,j : integer;
  a : array[1 .. 10] of String;
begin
  Get_Auswahl := -1;
  hst := st;
  for i := 1 to 10 do a[i] := '';
  j := 0;
  repeat
    i := pos(';',hst);
    if i > 0 then begin
      hst2 := copy(hst,1,i-1);
      system.Delete(hst,1,i);
      j := j + 1;
      a[j] := hst2;
    end;
  until i = 0;
  j := j + 1;
  a[j] := hst;


{
  newform := TGetAuswahl.CreateNew(procedure(AForm: TObject)
    begin
      (AForm as TGetAuswahl).Caption := 'Auswahl:';
      (AForm as TGetAuswahl).WebButton1.Caption := a[1];
      (AForm as TGetAuswahl).WebButton2.Caption := a[2];
      (AForm as TGetAuswahl).WebButton3.Caption := a[3];
      (AForm as TGetAuswahl).WebButton4.Caption := a[4];
      (AForm as TGetAuswahl).WebButton5.Caption := a[5];
      (AForm as TGetAuswahl).WebButton6.Caption := a[6];
      (AForm as TGetAuswahl).WebButton1.Visible := a[1] <> '';
      (AForm as TGetAuswahl).WebButton2.Visible := a[2] <> '';
      (AForm as TGetAuswahl).WebButton3.Visible := a[3] <> '';
      (AForm as TGetAuswahl).WebButton4.Visible := a[4] <> '';
      (AForm as TGetAuswahl).WebButton5.Visible := a[5] <> '';
      (AForm as TGetAuswahl).WebButton6.Visible := a[6] <> '';

//      frm2Edit.Text := WebEdit1.Text;
    end
  );
//  newForm.Caption := 'Neues Konto';
  newform.Border := fbSingle;

  newform.ShowModal(procedure(AValue: TModalResult)
  begin
    Get_Auswahl := StrToInt(newform.WebEdit2.Text);
  end
  );
}


end;


procedure TGetAuswahl.WebButton1Click(Sender: TObject);
begin
  WebEdit2.Text := '1';
  Close;
end;

procedure TGetAuswahl.WebButton2Click(Sender: TObject);
begin
  WebEdit2.Text := '2';
  Close;
end;

procedure TGetAuswahl.WebButton3Click(Sender: TObject);
begin
  WebEdit2.Text := '3';
  Close;
end;

procedure TGetAuswahl.WebButton4Click(Sender: TObject);
begin
  WebEdit2.Text := '4';
  Close;
end;

procedure TGetAuswahl.WebButton5Click(Sender: TObject);
begin
  WebEdit2.Text := '5';
  Close;
end;

procedure TGetAuswahl.WebButton6Click(Sender: TObject);
begin
  WebEdit2.Text := '6';
  Close;
end;

procedure TGetAuswahl.WebButton7Click(Sender: TObject);
begin
  WebEdit2.Text := '-1';
  Close;
end;

procedure TGetAuswahl.WebFormCreate(Sender: TObject);
begin
  WebEdit1.Text := AktKDName;
  GA_WebLabel_1.Caption := AktFirmaName;

end;

procedure TGetAuswahl.LoadDFMValues;
begin
  inherited LoadDFMValues;

  GA_WebLabel_1 := TLabel.Create(Self);
  Benutzer := TLabel.Create(Self);
  WebEdit1 := TEdit.Create(Self);
  WebButton1 := TButton.Create(Self);
  WebButton2 := TButton.Create(Self);
  WebButton3 := TButton.Create(Self);
  WebButton4 := TButton.Create(Self);
  WebButton5 := TButton.Create(Self);
  WebButton6 := TButton.Create(Self);
  WebButton7 := TButton.Create(Self);
  WebEdit2 := TEdit.Create(Self);

  GA_WebLabel_1.BeforeLoadDFMValues;
  Benutzer.BeforeLoadDFMValues;
  WebEdit1.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  WebButton3.BeforeLoadDFMValues;
  WebButton4.BeforeLoadDFMValues;
  WebButton5.BeforeLoadDFMValues;
  WebButton6.BeforeLoadDFMValues;
  WebButton7.BeforeLoadDFMValues;
  WebEdit2.BeforeLoadDFMValues;
  try
    Name := 'GetAuswahl';
    Left := 0;
    Top := 0;
    Width := 640;
    Height := 480;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Arial';
    Font.Style := [];
    TabOrder := 1;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    GA_WebLabel_1.SetParentComponent(Self);
    GA_WebLabel_1.Name := 'GA_WebLabel_1';
    GA_WebLabel_1.Left := 24;
    GA_WebLabel_1.Top := 8;
    GA_WebLabel_1.Width := 126;
    GA_WebLabel_1.Height := 19;
    GA_WebLabel_1.Caption := 'GA_WebLabel_1';
    GA_WebLabel_1.Font.Charset := DEFAULT_CHARSET;
    GA_WebLabel_1.Font.Color := clWindowText;
    GA_WebLabel_1.Font.Height := -16;
    GA_WebLabel_1.Font.Name := 'Arial';
    GA_WebLabel_1.Font.Style := [fsBold];
    GA_WebLabel_1.ParentFont := False;
    Benutzer.SetParentComponent(Self);
    Benutzer.Name := 'Benutzer';
    Benutzer.Left := 24;
    Benutzer.Top := 53;
    Benutzer.Width := 44;
    Benutzer.Height := 14;
    Benutzer.Caption := 'Benutzer';
    WebEdit1.SetParentComponent(Self);
    WebEdit1.Name := 'WebEdit1';
    WebEdit1.Left := 88;
    WebEdit1.Top := 52;
    WebEdit1.Width := 209;
    WebEdit1.Height := 22;
    WebEdit1.TabStop := False;
    WebEdit1.ChildOrder := 7;
    WebEdit1.ReadOnly := True;
    WebEdit1.Text := 'WebEdit1';
    WebButton1.SetParentComponent(Self);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 24;
    WebButton1.Top := 86;
    WebButton1.Width := 273;
    WebButton1.Height := 33;
    WebButton1.Caption := 'Speiseplan';
    WebButton1.ChildOrder := 3;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    WebButton2.SetParentComponent(Self);
    WebButton2.Name := 'WebButton2';
    WebButton2.Left := 24;
    WebButton2.Top := 125;
    WebButton2.Width := 273;
    WebButton2.Height := 33;
    WebButton2.Caption := 'Essen abbestellen';
    WebButton2.ChildOrder := 1;
    SetEvent(WebButton2, Self, 'OnClick', 'WebButton2Click');
    WebButton3.SetParentComponent(Self);
    WebButton3.Name := 'WebButton3';
    WebButton3.Left := 24;
    WebButton3.Top := 164;
    WebButton3.Width := 273;
    WebButton3.Height := 33;
    WebButton3.Caption := 'Essen bestellen';
    WebButton3.ChildOrder := 2;
    SetEvent(WebButton3, Self, 'OnClick', 'WebButton3Click');
    WebButton4.SetParentComponent(Self);
    WebButton4.Name := 'WebButton4';
    WebButton4.Left := 24;
    WebButton4.Top := 203;
    WebButton4.Width := 273;
    WebButton4.Height := 33;
    WebButton4.Caption := 'Konto bearbeiten';
    WebButton4.ChildOrder := 3;
    SetEvent(WebButton4, Self, 'OnClick', 'WebButton4Click');
    WebButton5.SetParentComponent(Self);
    WebButton5.Name := 'WebButton5';
    WebButton5.Left := 24;
    WebButton5.Top := 242;
    WebButton5.Width := 273;
    WebButton5.Height := 33;
    WebButton5.Caption := 'Kennwort '#228'ndern';
    WebButton5.ChildOrder := 4;
    SetEvent(WebButton5, Self, 'OnClick', 'WebButton5Click');
    WebButton6.SetParentComponent(Self);
    WebButton6.Name := 'WebButton6';
    WebButton6.Left := 24;
    WebButton6.Top := 281;
    WebButton6.Width := 273;
    WebButton6.Height := 33;
    WebButton6.Caption := 'Zur'#252'ck';
    WebButton6.ChildOrder := 5;
    SetEvent(WebButton6, Self, 'OnClick', 'WebButton6Click');
    WebButton7.SetParentComponent(Self);
    WebButton7.Name := 'WebButton7';
    WebButton7.Left := 24;
    WebButton7.Top := 320;
    WebButton7.Width := 273;
    WebButton7.Height := 33;
    WebButton7.Caption := 'Zur'#252'ck';
    WebButton7.ChildOrder := 5;
    SetEvent(WebButton7, Self, 'OnClick', 'WebButton7Click');
    WebEdit2.SetParentComponent(Self);
    WebEdit2.Name := 'WebEdit2';
    WebEdit2.Left := 376;
    WebEdit2.Top := 72;
    WebEdit2.Width := 121;
    WebEdit2.Height := 22;
    WebEdit2.ChildOrder := 10;
    WebEdit2.Text := 'WebEdit2';
    WebEdit2.Visible := False;
  finally
    GA_WebLabel_1.AfterLoadDFMValues;
    Benutzer.AfterLoadDFMValues;
    WebEdit1.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
    WebButton3.AfterLoadDFMValues;
    WebButton4.AfterLoadDFMValues;
    WebButton5.AfterLoadDFMValues;
    WebButton6.AfterLoadDFMValues;
    WebButton7.AfterLoadDFMValues;
    WebEdit2.AfterLoadDFMValues;
  end;
end;

end.
