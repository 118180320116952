unit UHMenu2;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.StdCtrls, WEBLib.ExtCtrls,
  DB,

  UDatamodule,
  UGlobvar,
  UVW_Einrichtungen,
  UEditEinrichtung,
  UStd_Edit;

type
  THMenu2 = class(TForm)
    WebPanel1: TPanel;
    WebButton1: TButton;
    WebButton6: TButton;
    procedure WebFormShow(Sender: TObject);
    procedure WebButton1Click(Sender: TObject);
    procedure WebButton6Click(Sender: TObject);
    procedure Zeige_Einrichtungen(DataSet: TDataSet);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
    procedure WebFormCreate(Sender: TObject);
    procedure WebFormResize(Sender: TObject);
  private
    { Private-Deklarationen }
  protected
    procedure LoadDFMValues; override;
  public
    { Public-Deklarationen }

    Zeilen_Liste : TStringList;
    Zeilen_Count : integer;
    Form_Aktiv : boolean;

  end;

var
  HMenu2: THMenu2;

implementation


procedure THMenu2.WebButton1Click(Sender: TObject);
var Newform: TVW_Einrichtungen;
    i : integer;


begin
  Datamodule1.EinrichtungDataSet.Close;
  Datamodule1.EinrichtungDataSet.AfterOpen := Zeige_Einrichtungen;
  DataModule1.EinrichtungDataSet.QueryString := '$filter=NL_Key eq '+IntToStr(AktFirmaNo);
  DataModule1.EinrichtungDataSet.Load;

end;

procedure THMenu2.WebButton6Click(Sender: TObject);
begin
  window.localStorage.setItem(cStorageTokenName, '');
  Akt_JWToken := '';
  AktKDName := '';
  AktKDID := 0;
  AktTeilnehmerID := 0;
  AktEinrichtungID := 0;
  AktTeilnehmer := 0;
  AktTeilnehmerName := '';
  AktEinrichtung := 0;
  AktEinrichtungName := '';

  Datamodule1.XDataWebConnection1.Connected := false;
  Datamodule1.XDataWebConnection2.Connected := false;
  Datamodule1.XDataWebConnection3.Connected := false;

  Close;
end;




procedure THMenu2.WebFormClose(Sender: TObject; var Action: TCloseAction);
begin
  Zeilen_Liste.Clear;
  Zeilen_Liste.Free;

end;

procedure THMenu2.WebFormCreate(Sender: TObject);
begin
  Form_Aktiv := false;

end;

procedure THMenu2.WebFormResize(Sender: TObject);
begin
  if not Form_Aktiv then exit;
  Resize_Edit_Controls(WF_Menu,self,Zeilen_Liste,WebPanel1,nil);

end;

procedure THMenu2.WebFormShow(Sender: TObject);
begin

  Resize_Top_Panel(self,WebPanel1);
  Zeilen_Liste := TStringList.Create;
  Zeilen_Count := 0;
  Resize_Edit_Controls(WF_Menu,self,Zeilen_Liste,WebPanel1,nil);
  Form_Aktiv := true;




end;


procedure THMenu2.Zeige_Einrichtungen(DataSet: TDataSet);
var Newform: TVW_Einrichtungen;
    i : integer;
begin

    Newform := TVW_Einrichtungen.CreateNew(procedure(AForm: TObject)
      begin
        (AForm as TVW_Einrichtungen).Caption := 'Einrichtungen';
      end
    );
    Newform.Border := fbSingle;

    Newform.ShowModal(procedure(AValue: TModalResult)
    begin
//      Close;
    end
    );
end;


procedure THMenu2.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebPanel1 := TPanel.Create(Self);
  WebButton1 := TButton.Create(Self);
  WebButton6 := TButton.Create(Self);

  WebPanel1.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  WebButton6.BeforeLoadDFMValues;
  try
    Name := 'HMenu2';
    Left := 0;
    Top := 0;
    Width := 640;
    Height := 480;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -16;
    Font.Name := 'Arial';
    Font.Style := [];
    TabOrder := 1;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnResize', 'WebFormResize');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 0;
    WebPanel1.Top := 0;
    WebPanel1.Width := 640;
    WebPanel1.Height := 89;
    WebPanel1.Align := alTop;
    WebPanel1.BorderColor := clSilver;
    WebPanel1.BorderStyle := bsSingle;
    WebPanel1.ChildOrder := 1;
    WebButton1.SetParentComponent(Self);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 24;
    WebButton1.Top := 121;
    WebButton1.Width := 273;
    WebButton1.Height := 33;
    WebButton1.Caption := 'Essensausgabe';
    WebButton1.ChildOrder := 1;
    WebButton1.ElementClassName := 'btn-secondary';
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    WebButton6.SetParentComponent(Self);
    WebButton6.Name := 'WebButton6';
    WebButton6.Left := 24;
    WebButton6.Top := 361;
    WebButton6.Width := 273;
    WebButton6.Height := 33;
    WebButton6.Caption := 'Programm beenden';
    WebButton6.ChildOrder := 5;
    WebButton6.ElementClassName := 'btn-secondary';
    SetEvent(WebButton6, Self, 'OnClick', 'WebButton6Click');
  finally
    WebPanel1.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    WebButton6.AfterLoadDFMValues;
  end;
end;

end.
