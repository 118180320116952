unit UZeigeEssen;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.StdCtrls, WEBLib.ExtCtrls,
  DB,

  UDatamodule,
  UGlobvar, WEBLib.Grids, WEBLib.DBCtrls;

type
  TZeigeEssen = class(TForm)
    WebPanel1: TPanel;
    WebLabel2: TLabel;
    WebLabel3: TLabel;
    WebLabel4: TLabel;
    WebLabel6: TLabel;
    WebPanel2: TPanel;
    WebButton1: TButton;
    WebButton2: TButton;

    procedure WebFormShow(Sender: TObject);
    procedure WebButton1Click(Sender: TObject);
    procedure WebButton2Click(Sender: TObject);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
    procedure WebFormCreate(Sender: TObject);
    procedure WebFormResize(Sender: TObject);
  private
    { Private-Deklarationen }
  protected
    procedure LoadDFMValues; override;
  public
    { Public-Deklarationen }
    Zeilen_Liste : TStringList;
    Zeilen_Count : integer;
    Form_Aktiv : boolean;

  end;

var
  ZeigeEssen: TZeigeEssen;

implementation


procedure TZeigeEssen.WebButton1Click(Sender: TObject);
begin

  Close;
end;

procedure TZeigeEssen.WebButton2Click(Sender: TObject);
begin
  if Datamodule1.SpeiseplanDataSet.FieldByName('TID').AsInteger = 0 then begin
    MessageDlg('Kein Essen bestellt', mtError, [], nil);
    exit;
  end;
  DataModule1.SpeiseplanDataSet.Edit;
  Datamodule1.SpeiseplanDataSet.Fieldbyname('Ausgabe').AsDateTime := Now;
  Datamodule1.SpeiseplanDataSet.Fieldbyname('KZ_Ausgabe').AsInteger := 1;
  try
    DataModule1.SpeiseplanDataSet.Post;
    DataModule1.SpeiseplanDataSet.ApplyUpdates;
    Close;
  except
    MessageDlg('Fehler beim Speichern', mtError, [], nil);
    exit;
  end;

end;

procedure TZeigeEssen.WebFormClose(Sender: TObject; var Action: TCloseAction);
begin
  Zeilen_Liste.Clear;
  Zeilen_Liste.Free;

end;

procedure TZeigeEssen.WebFormCreate(Sender: TObject);
begin
  Form_Aktiv := false;
end;

procedure TZeigeEssen.WebFormResize(Sender: TObject);
begin
  if not Form_Aktiv then exit;
  Resize_Edit_Controls(WF_Edit,self,Zeilen_Liste,WebPanel1,WebPanel2);

end;

procedure TZeigeEssen.WebFormShow(Sender: TObject);
var i,j : integer;
begin
  Resize_Top_Panel_2(self,WebPanel1,'Einrichtung: ' + AktEinrichtungName);
  Resize_Bottom_Panel(self,WebPanel2);
  Zeilen_Liste := TStringList.Create;
  Zeilen_Count := 0;
  Zeilen_Liste_Add_Hinweis(Zeilen_Liste,Zeilen_Count,WebLabel2);
  Zeilen_Liste_Add_Hinweis(Zeilen_Liste,Zeilen_Count,WebLabel3);
  Zeilen_Liste_Add_Hinweis(Zeilen_Liste,Zeilen_Count,WebLabel4);
  Zeilen_Liste_Add_Hinweis(Zeilen_Liste,Zeilen_Count,WebLabel6);
  Resize_Edit_Controls(WF_Edit,self,Zeilen_Liste,WebPanel1,WebPanel2);
  Form_Aktiv := true;



  WebLabel2.Caption := DataModule1.TeilnehmerDataSet.FieldByName('Name').AsString;
  WebLabel4.Caption := '';
  WebLabel6.Visible := false;
  WebButton2.Enabled := true;
  if Datamodule1.SpeiseplanDataSet.FieldByName('TID').AsInteger = 0 then begin
    WebLabel3.Caption := 'Kein Essen bestellt';
  end
  else begin
    WebLabel3.Caption := Datamodule1.SpeiseplanDataSet.FieldByName('Bezeichnung').AsString;
    WebLabel4.Caption := 'Bestellt: ' + DataModule1.SpeiseplanDataSet.FieldByName('Bestellt').AsString;

    if Datamodule1.SpeiseplanDataSet.FieldByName('KZ_Ausgabe').AsInteger = 1 then begin
      WebLabel6.Caption := 'Fehler: Bereits ausgegeben';
      WebLabel6.Visible := true;
      WebButton2.Enabled := false;
    end;
  end;
end;

procedure TZeigeEssen.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebLabel2 := TLabel.Create(Self);
  WebLabel3 := TLabel.Create(Self);
  WebLabel4 := TLabel.Create(Self);
  WebLabel6 := TLabel.Create(Self);
  WebPanel1 := TPanel.Create(Self);
  WebPanel2 := TPanel.Create(Self);
  WebButton1 := TButton.Create(Self);
  WebButton2 := TButton.Create(Self);

  WebLabel2.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  WebLabel4.BeforeLoadDFMValues;
  WebLabel6.BeforeLoadDFMValues;
  WebPanel1.BeforeLoadDFMValues;
  WebPanel2.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  try
    Name := 'ZeigeEssen';
    Left := 0;
    Top := 0;
    Width := 640;
    Height := 401;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -16;
    Font.Name := 'Arial';
    Font.Style := [];
    TabOrder := 1;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnResize', 'WebFormResize');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    WebLabel2.SetParentComponent(Self);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 8;
    WebLabel2.Top := 177;
    WebLabel2.Width := 244;
    WebLabel2.Height := 24;
    WebLabel2.Caption := 'Name Essensteilnehmer';
    WebLabel2.Color := clBtnFace;
    WebLabel2.Font.Charset := DEFAULT_CHARSET;
    WebLabel2.Font.Color := clWindowText;
    WebLabel2.Font.Height := -21;
    WebLabel2.Font.Name := 'Arial';
    WebLabel2.Font.Style := [fsBold];
    WebLabel2.ParentFont := False;
    WebLabel3.SetParentComponent(Self);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Left := 8;
    WebLabel3.Top := 223;
    WebLabel3.Width := 75;
    WebLabel3.Height := 24;
    WebLabel3.Caption := 'Bestellt';
    WebLabel3.Color := clBtnFace;
    WebLabel3.Font.Charset := DEFAULT_CHARSET;
    WebLabel3.Font.Color := clWindowText;
    WebLabel3.Font.Height := -21;
    WebLabel3.Font.Name := 'Arial';
    WebLabel3.Font.Style := [fsBold];
    WebLabel3.ParentFont := False;
    WebLabel4.SetParentComponent(Self);
    WebLabel4.Name := 'WebLabel4';
    WebLabel4.Left := 8;
    WebLabel4.Top := 253;
    WebLabel4.Width := 81;
    WebLabel4.Height := 18;
    WebLabel4.Caption := 'WebLabel4';
    WebLabel6.SetParentComponent(Self);
    WebLabel6.Name := 'WebLabel6';
    WebLabel6.Left := 8;
    WebLabel6.Top := 283;
    WebLabel6.Width := 127;
    WebLabel6.Height := 29;
    WebLabel6.Caption := 'WebLabel4';
    WebLabel6.Font.Charset := DEFAULT_CHARSET;
    WebLabel6.Font.Color := clRed;
    WebLabel6.Font.Height := -24;
    WebLabel6.Font.Name := 'Arial';
    WebLabel6.Font.Style := [fsBold];
    WebLabel6.ParentFont := False;
    WebLabel6.Visible := False;
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 0;
    WebPanel1.Top := 0;
    WebPanel1.Width := 640;
    WebPanel1.Height := 153;
    WebPanel1.Align := alTop;
    WebPanel1.BorderColor := clSilver;
    WebPanel1.BorderStyle := bsSingle;
    WebPanel1.ChildOrder := 1;
    WebPanel2.SetParentComponent(Self);
    WebPanel2.Name := 'WebPanel2';
    WebPanel2.Left := 0;
    WebPanel2.Top := 341;
    WebPanel2.Width := 640;
    WebPanel2.Height := 60;
    WebPanel2.Align := alBottom;
    WebPanel2.BorderColor := clSilver;
    WebPanel2.BorderStyle := bsSingle;
    WebPanel2.ChildOrder := 5;
    WebButton1.SetParentComponent(WebPanel2);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 183;
    WebButton1.Top := 10;
    WebButton1.Width := 120;
    WebButton1.Height := 43;
    WebButton1.Caption := 'Zur'#252'ck';
    WebButton1.ChildOrder := 3;
    WebButton1.ElementClassName := 'btn-primary';
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    WebButton2.SetParentComponent(WebPanel2);
    WebButton2.Name := 'WebButton2';
    WebButton2.Left := 3;
    WebButton2.Top := 10;
    WebButton2.Width := 147;
    WebButton2.Height := 43;
    WebButton2.Caption := 'Ausgabe OK';
    WebButton2.ChildOrder := 5;
    WebButton2.ElementClassName := 'btn-primary';
    SetEvent(WebButton2, Self, 'OnClick', 'WebButton2Click');
  finally
    WebLabel2.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
    WebLabel4.AfterLoadDFMValues;
    WebLabel6.AfterLoadDFMValues;
    WebPanel1.AfterLoadDFMValues;
    WebPanel2.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
  end;
end;

end.
