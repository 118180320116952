{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2018                                      }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit libswiper;

{$mode objfpc}
{$modeswitch externalclass}

interface

uses
  classes, JS, Web;

type
  TJSSwiper = class external name 'Swiper' (TJSObject)
  private
   fWidth: Integer; external name 'width';
   fHeight: Integer; external name 'height';
   fActiveIndex: Integer; external name 'activeIndex';
   fClickedIndex: Integer; external name 'clickedIndex';
   fPreviousIndex: Integer; external name 'previousIndex';
   fClickedSlide: TJSHTMLElement; external name 'clickedSlide';
   fAllowSlideNext: Boolean; external name 'allowSlideNext';
   fAllowSlidePrev: Boolean; external name 'allowSlidePrev';
  public
    constructor New(domElement: TJSElement; options: TJSObject);
    procedure doDestroy(deleteInstance: Boolean; cleanStyles: Boolean); external name 'destroy';
    procedure appendSlide(slideHtml: String); overload;
    procedure appendSlide(slidesHtml: TJSArray); overload;
    procedure removeAllSlides;
    procedure update;
    procedure updateSize;
    procedure slideTo(anIndex: Integer);

    property Width: Integer read fWidth write fWidth;
    property Height: Integer read fHeight write fHeight;
    property ActiveIndex: Integer read fActiveIndex;
    property ClickedIndex: Integer read fClickedIndex;
    property PreviousIndex: Integer read fPreviousIndex;
    property clickedSlide: TJSHTMLElement read fClickedSlide;
    property AllowSlideNext: Boolean read fAllowSlideNext write fAllowSlideNext;
    property AllowSlidePrev: Boolean read fAllowSlidePrev write fAllowSlidePrev;

    procedure on(Const anEvent : String; aListener: JSValue);
  end;

  function CreateSwiper(
             aContainer: TJSElement;
             slidesPerView: Integer;
             spaceBetween: Integer;

             // pass nil for these parameters for Thumbnails Swiper
             buttonNext: TJSElement;
             buttonPrev: TJSElement;
             thumbNailsSwiper: TJSSwiper;

             transitionEffect: String;
             usePagination: Boolean = False;
             // pass True for these parameters for Thumbnails Swiper
             freeMode: Boolean = False;
             watchSlidesVisibility: Boolean = False;
             watchSlidesProgress: Boolean = False
           ): TJSSwiper;

implementation

function CreateSwiper(
             aContainer: TJSElement;
             slidesPerView: Integer;
             spaceBetween: Integer;
             buttonNext: TJSElement;
             buttonPrev: TJSElement;
             thumbNailsSwiper: TJSSwiper;
             transitionEffect: String;
             usePagination: Boolean = False;
             freeMode: Boolean = False;
             watchSlidesVisibility: Boolean = False;
             watchSlidesProgress: Boolean = False
           ): TJSSwiper;
var
  parameters, navigation, thumbs,
  pagination: TJSObject;
begin
  navigation := nil;
  pagination := nil;
  if (buttonNext <> nil) and (buttonPrev <> nil) then
    navigation := New([
             'nextEl', buttonNext,
             'prevEl', buttonPrev
            ]);

  thumbs := nil;
  if (thumbNailsSwiper <> nil) then
    thumbs := New([
             'swiper', thumbNailsSwiper
            ]);

  if usePagination then
    pagination := New([
             'el', '.swiper-pagination',
             'clickable', True
            ]);

  parameters := New([
            'slidesPerView', slidesPerView,
            'spaceBetween', spaceBetween,
            'freeMode', freeMode,
            'watchSlidesVisibility', watchSlidesVisibility,
            'watchSlidesProgress', watchSlidesProgress
            ]);

  if thumbnailsSwiper <> nil then
    parameters['effect'] := transitionEffect;

  if navigation <> nil then
    parameters['navigation'] := navigation;
  if thumbs <> nil then
    parameters['thumbs'] := thumbs;

  if pagination <> nil then
    parameters['pagination'] := pagination;

  Result := TJSSwiper.New(aContainer, parameters);
end;

end.
