{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2019 - 2020                               }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.Slider;


interface

uses
  Classes, WebLib.Graphics, SysUtils, Web, WebLib.Controls, js,
  WebLib.Menus, WEBLib.ExtCtrls, libswiper;

type
  TImageSlider = class;
  TSliderAppearance = class;

  TNavigationBullets = class(TPersistent)
  private
    FOwner: TSliderAppearance;
    FOpacity: double;
    FColor, FColorActive: TColor;
    FSize, FSpaceBetween, FSpaceEdge: Integer;
    procedure SetOpacity(const Value: double);
    procedure SetColor(const Value: TColor);
    procedure SetColorActive(const Value: TColor);
    procedure SetSize(const Value: Integer);
    procedure SetSpaceBetween(const Value: Integer);
    procedure SetSpaceEdge(const Value: Integer);
  public
    constructor Create(AOwner: TSliderAppearance); overload;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    function GetOwner: TPersistent; override;
  published
    property Color: TColor read FColor write SetColor default clLtGray;
    property ColorActive: TColor read FColorActive write SetColorActive default clBlue;
    property Size: Integer read FSize write SetSize default 12;
    property SpaceBetween: Integer read FSpaceBetween write SetSpaceBetween default 12;
    property SpaceEdge: Integer read FSpaceEdge write SetSpaceEdge default 8;
    property Opacity: double read FOpacity write SetOpacity;
  end;

  TNavigationThumbnails = class(TPersistent)
  private
    FOwner: TSliderAppearance;
    FSizePercent, FSpaceBetween, FNumDisplayed: Integer;
    FOpacity, FOpacityActive: double;

    FWidthActiveBorder: Integer;
    FColorActiveBorder: TColor;

    procedure SetOpacity(const Value: double);
    procedure SetOpacityActive(const Value: double);
    procedure SetColorActiveBorder(const Value: TColor);
    procedure SetSizePercent(const Value: Integer);
    procedure SetSpaceBetween(const Value: Integer);
    procedure SetWidthActiveBorder(const Value: Integer);
    procedure SetNumDisplayed(const Value: Integer);
  public
    constructor Create(AOwner: TSliderAppearance); overload;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    function GetOwner: TPersistent; override;
  published
    property SizePercent: Integer read FSizePercent write SetSizePercent default 20;
    property NumDisplayed: Integer read FNumDisplayed write SetNumDisplayed default 6;
    property SpaceBetween: Integer read FSpaceBetween write SetSpaceBetween default 8;
    property Opacity: double read FOpacity write SetOpacity;
    property ColorActiveBorder: TColor read FColorActiveBorder write SetColorActiveBorder default clBlack;
    property WidthActiveBorder: Integer read FWidthActiveBorder write SetWidthActiveBorder default 3;
    property OpacityActive: double read FOpacityActive write SetOpacityActive;
  end;

  TNavigationButtons = class(TPersistent)
  private
    FOwner: TSliderAppearance;
    FColor: TColor;
    FVisible: Boolean;
    procedure SetColor(const Value: TColor);
    procedure SetVisible(const Value: Boolean);
  public
    constructor Create(AOwner: TSliderAppearance); overload;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    function GetOwner: TPersistent; override;
  published
    property Color: TColor read FColor write SetColor default clNone;
    property Visible: Boolean read FVisible write SetVisible default True;
  end;

  TNavigationStyle = (navsNone, navsThumbnails, navsBullets);
  TTransitionEffect = (tefSlide, tefFade, tefFlip);

  TSliderAppearance = class(TPersistent)
  private
    FOwner: TImageSlider;
    FNavigationStyle: TNavigationStyle;
    FTransitionEffect: TTransitionEffect;
    FBullets: TNavigationBullets;
    FThumbnails: TNavigationThumbnails;
    FButtons: TNavigationButtons;
    procedure SetNavigationStyle(const Value: TNavigationStyle);
    procedure SetTransitionEffect(const Value: TTransitionEffect);
    procedure SetBullets(const Value: TNavigationBullets);
    procedure SetThumbnails(const Value: TNavigationThumbnails);
    procedure SetButtons(const Value: TNavigationButtons);
  public
    constructor Create(AOwner: TImageSlider); overload;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    function GetOwner: TPersistent; override;
  published
    property NavigationStyle: TNavigationStyle read FNavigationStyle write SetNavigationStyle;
    property TransitionEffect: TTransitionEffect read FTransitionEffect write SetTransitionEffect;
    property Bullets: TNavigationBullets read FBullets write SetBullets;
    property Thumbnails: TNavigationThumbnails read FThumbnails write SetThumbnails;
    property Buttons: TNavigationButtons read FButtons write SetButtons;
  end;

  TImageSliderImageEvent = procedure(Sender: TObject; ImageIndex: integer) of object;
  TImageSliderThumbnailEvent = procedure(Sender: TObject; ImageIndex: integer) of object;

  TImageSlider = class(TCustomControl)
  private
    FImageSwiper: TJSSwiper;
    FThumbnailsSwiper: TJSSwiper;
    FAppearance: TSliderAppearance;
    FContainerImages: TJSHTMLElement;
    FContainerThumbnails: TJSHTMLElement;
    FContainerBullets: TJSHTMLElement;
    FNextButton, FPrevButton: TJSHTMLElement;
    FImageURLs: TStringList;
    FOnImageChange: TImageSliderImageEvent;
    FOnImageClick: TImageSliderImageEvent;
    FOnThumbnailClick: TImageSliderThumbnailEvent;
  protected
    procedure SetStyles;
    function CreateElement: TJSElement; override;
    procedure CreateInitialize; override;
    procedure SetImageURLs(const Value: TStringList);
    procedure update;
    procedure UpdateElementSize; override;
    procedure UpdateElementVisual; override;
    procedure AddSlides(aSwiper: TJSSwiper; isThumbnailsSwiper: Boolean);
    procedure CreateSwipers;
    procedure RecreateSwipers;
    procedure InitScript; override;
    procedure SetColor(AValue: TColor); override;
    function GetActiveImageIndex: Integer;
    function GetLastClickedIndex: Integer;
    function GetPreviousActiveIndex: Integer;

    function HandleDoClick(Event: TJSMouseEvent): Boolean; override;
    procedure HandleDoImageClick(Index: integer); virtual;
    procedure HandleDoThumbnailClick(Index: integer); virtual;
    procedure HandleDoImageChange(Event: TJSEvent); virtual;
  public
    constructor Create(AOwner: TComponent); overload; override;
    destructor Destroy; override;
    procedure RefreshImages;

    property ImageSwiper: TJSSwiper read FImageSwiper;
    property ThumbnailsSwiper: TJSSwiper read FThumbnailsSwiper;
    property ActiveImageIndex: Integer read GetActiveImageIndex;
    property PreviousActiveIndex: Integer read GetPreviousActiveIndex;
    property LastClickedIndex: Integer read GetLastClickedIndex;

  published
    property Align;
    property AlignWithMargins;
    property Anchors;
    property ImageURLs: TStringList read FImageURLs write SetImageURLs;
    property Appearance: TSliderAppearance read FAppearance write FAppearance;

    property OnImageChange: TImageSliderImageEvent read FOnImageChange write FOnImageChange;
    property OnImageClick: TImageSliderImageEvent read FOnImageClick write FOnImageClick;
    property OnThumbnailClick: TImageSliderThumbnailEvent read FOnThumbnailClick write FOnThumbnailClick;
    property OnClick;
    property OnDblClick;
    property OnMouseDown;
    property OnMouseUp;
    property OnMouseMove;
  end;

  TWebImageSlider = class(TImageSlider);

implementation

const
  ThumbsClassName: String = 'slider-thumbs';

{ TNavigationBullets }

procedure TNavigationBullets.Assign(Source: TPersistent);
begin
  inherited;
  if (Source is TNavigationBullets) then
  begin
    FOpacity := (Source as TNavigationBullets).FOpacity;
    FColor := (Source as TNavigationBullets).FColor;
    FColorActive := (Source as TNavigationBullets).FColorActive;
    FSize := (Source as TNavigationBullets).FSize;
    FSpaceBetween := (Source as TNavigationBullets).FSpaceBetween;
    FSpaceEdge := (Source as TNavigationBullets).FSpaceEdge;
  end;
end;

constructor TNavigationBullets.Create(AOwner: TSliderAppearance);
begin
  FOwner := AOwner;
  FOpacity := 1;
  FColor := clLtGray;
  FColorActive := clBlue;
  FSize := 12;
  FSpaceBetween := 12;
  FSpaceEdge := 8;
end;

destructor TNavigationBullets.Destroy;
begin
  inherited;
end;

function TNavigationBullets.GetOwner: TPersistent;
begin
  Result := FOwner;
end;

procedure TNavigationBullets.SetOpacity(const Value: Double);
begin
  if FOpacity <> Value then
  begin
    FOpacity := Value;
    FOwner.FOwner.Update;
  end;
end;

procedure TNavigationBullets.SetColor(const Value: TColor);
begin
  if FColor <> Value then
  begin
    FColor := Value;
    FOwner.FOwner.Update;
  end;
end;

procedure TNavigationBullets.SetColorActive(const Value: TColor);
begin
  if FColorActive <> Value then
  begin
    FColorActive := Value;
    FOwner.FOwner.Update;
  end;
end;

procedure TNavigationBullets.SetSize(const Value: Integer);
begin
  if FSize <> Value then
  begin
    FSize := Value;
    FOwner.FOwner.Update;
  end;
end;

procedure TNavigationBullets.SetSpaceBetween(const Value: Integer);
begin
  if FSpaceBetween <> Value then
  begin
    FSpaceBetween := Value;
    FOwner.FOwner.Update;
  end;
end;

procedure TNavigationBullets.SetSpaceEdge(const Value: Integer);
begin
  if FSpaceEdge <> Value then
  begin
    FSpaceEdge := Value;
    FOwner.FOwner.Update;
  end;
end;

{ TNavigationThumbnails }

procedure TNavigationThumbnails.Assign(Source: TPersistent);
begin
  inherited;
  if (Source is TNavigationThumbnails) then
  begin
    FOpacity := (Source as TNavigationThumbnails).FOpacity;
    FColorActiveBorder := (Source as TNavigationThumbnails).FColorActiveBorder;
    FSizePercent := (Source as TNavigationThumbnails).FSizePercent;
    FSpaceBetween := (Source as TNavigationThumbnails).FSpaceBetween;
    FWidthActiveBorder := (Source as TNavigationThumbnails).FWidthActiveBorder;
    FNumDisplayed := (Source as TNavigationThumbnails).FNumDisplayed;
    FOpacityActive := (Source as TNavigationThumbnails).FOpacityActive;
  end;
end;

constructor TNavigationThumbnails.Create(AOwner: TSliderAppearance);
begin
  FOwner := AOwner;
  FOpacity := 0.5;
  FOpacityActive := 1;
  FColorActiveBorder := clBlack;
  FWidthActiveBorder := 3;
  FSizePercent := 20;
  FSpaceBetween := 8;
  FNumDisplayed := 6;
end;

destructor TNavigationThumbnails.Destroy;
begin
  inherited;
end;

function TNavigationThumbnails.GetOwner: TPersistent;
begin
  Result := FOwner;
end;

procedure TNavigationThumbnails.SetColorActiveBorder(const Value: TColor);
begin
  if FColorActiveBorder <> Value then
  begin
    FColorActiveBorder := Value;
    FOwner.FOwner.Update;
  end;
end;

procedure TNavigationThumbnails.SetSizePercent(const Value: Integer);
begin
  if FSizePercent <> Value then
  begin
    FSizePercent := Value;
    FOwner.FOwner.Update;
  end;
end;

procedure TNavigationThumbnails.SetSpaceBetween(const Value: Integer);
begin
  if FSpaceBetween <> Value then
  begin
    FSpaceBetween := Value;
    FOwner.FOwner.UpdateElement;
  end;
end;

procedure TNavigationThumbnails.SetWidthActiveBorder(const Value: Integer);
begin
  if FWidthActiveBorder <> Value then
  begin
    FWidthActiveBorder := Value;
    FOwner.FOwner.Update;
  end;
end;

procedure TNavigationThumbnails.SetNumDisplayed(const Value: Integer);
begin
  if FNumDisplayed <> Value then
  begin
    FNumDisplayed := Value;
    FOwner.FOwner.UpdateElement;
  end;
end;

procedure TNavigationThumbnails.SetOpacity(const Value: Double);
begin
  if FOpacity <> Value then
  begin
    FOpacity := Value;
    FOwner.FOwner.Update;
  end;
end;

procedure TNavigationThumbnails.SetOpacityActive(const Value: Double);
begin
  if FOpacityActive <> Value then
  begin
    FOpacityActive := Value;
    FOwner.FOwner.Update;
  end;
end;

{ TNavigationButtons }

procedure TNavigationButtons.Assign(Source: TPersistent);
begin
  inherited;
  if (Source is TNavigationButtons) then
  begin
    FColor := (Source as TNavigationButtons).FColor;
    FVisible := (Source as TNavigationButtons).FVisible;
  end;
end;

constructor TNavigationButtons.Create(AOwner: TSliderAppearance);
begin
  FOwner := AOwner;
  FColor := clNone;
  FVisible := True;
end;

destructor TNavigationButtons.Destroy;
begin
  inherited;
end;

function TNavigationButtons.GetOwner: TPersistent;
begin
  Result := FOwner;
end;

procedure TNavigationButtons.SetColor(const Value: TColor);
begin
  if FColor <> Value then
  begin
    FColor := Value;
    FOwner.FOwner.Update;
  end;
end;

procedure TNavigationButtons.SetVisible(const Value: Boolean);
begin
  if FVisible <> Value then
  begin
    FVisible := Value;
    FOwner.FOwner.Update;
  end;
end;

{ TSliderAppearance }

procedure TSliderAppearance.Assign(Source: TPersistent);
begin
  inherited;
  if (Source is TSliderAppearance) then
  begin
    FBullets := (Source as TSliderAppearance).Bullets;
    FThumbnails := (Source as TSliderAppearance).Thumbnails;
    FButtons := (Source as TSliderAppearance).Buttons;
  end;
end;

constructor TSliderAppearance.Create(AOwner: TImageSlider);
begin
  inherited Create;
  FOwner := AOwner;
  FBullets := TNavigationBullets.Create(Self);
  FThumbnails := TNavigationThumbnails.Create(Self);
  FButtons := TNavigationButtons.Create(Self);
  FNavigationStyle := navsThumbnails;
end;

destructor TSliderAppearance.Destroy;
begin
  FBullets.Free;
  FThumbnails.Free;
  FButtons.Free;
  inherited;
end;

function TSliderAppearance.GetOwner: TPersistent;
begin
  Result := FOwner;
end;

procedure TSliderAppearance.SetNavigationStyle(const Value: TNavigationStyle);
begin
  if FNavigationStyle <> Value then
  begin
    FNavigationStyle := Value;
    FOwner.UpdateElement;
  end;
end;

procedure TSliderAppearance.SetTransitionEffect(const Value: TTransitionEffect);
begin
  if FTransitionEffect <> Value then
  begin
    FTransitionEffect := Value;
    FOwner.UpdateElement;
  end;
end;


procedure TSliderAppearance.SetBullets(const Value: TNavigationBullets);
begin
  FBullets.Assign(Value);
end;

procedure TSliderAppearance.SetThumbnails(const Value: TNavigationThumbnails);
begin
  FThumbnails.Assign(Value);
end;

procedure TSliderAppearance.SetButtons(const Value: TNavigationButtons);
begin
  FButtons.Assign(Value);
end;

{ TImageSlider }

procedure TImageSlider.SetStyles;
var
  aStyle, pgColor, pgColorActive: String;
  FBullets: TNavigationBullets;
  useImagePercent: Integer;
begin
  TJSHtmlElement(ElementHandle).style.setProperty('background-color', ColorToHtml(Color));

  useImagePercent := 100 - FAppearance.Thumbnails.SizePercent;
  if FAppearance.NavigationStyle <> navsThumbnails then
    useImagePercent := 100;
  if useImagePercent <> 100 then
    FContainerThumbnails.style.setProperty('height', Format('%d%%', [FAppearance.Thumbnails.SizePercent]));
  FContainerImages.style.setProperty('height', Format('%d%%', [useImagePercent]));
  FContainerImages.style.setProperty('width', '100%');

  FContainerThumbnails.style.setProperty('box-sizing', 'border-box');
  FContainerThumbnails.style.setProperty('padding', '10px 0');

  if FAppearance.NavigationStyle <> navsThumbnails then
    FContainerThumbnails.style.setProperty('display', 'none')
  else
    FContainerThumbnails.style.setProperty('display', 'flex');

  if FAppearance.Buttons.Visible then
  begin
    FNextButton.style.setProperty('display', 'flex'); //original style
    FPrevButton.style.setProperty('display', 'flex');
    FNextButton.style.setProperty('color', ColorToHtml(FAppearance.Buttons.Color));
    FPrevButton.style.setProperty('color', ColorToHtml(FAppearance.Buttons.Color));
  end
  else
  begin
    FNextButton.style.setProperty('display', 'none');
    FPrevButton.style.setProperty('display', 'none');
  end;

  FBullets := FAppearance.Bullets;
  aStyle := '';

  aStyle := Format('.%s .swiper-slide { opacity: %.1f;}'#13#10
      , [thumbsClassName, FAppearance.Thumbnails.Opacity]);

  if FAppearance.Thumbnails.ColorActiveBorder <> clNone then
    aStyle := aStyle +
      Format('.%s .swiper-slide-thumb-active { border: %dpx solid %s; opacity: %.1f;}'#13#10
      , [thumbsClassName, FAppearance.Thumbnails.WidthActiveBorder,
           ColorToHtml(FAppearance.Thumbnails.ColorActiveBorder),
           FAppearance.Thumbnails.OpacityActive
           ]);

  if FAppearance.NavigationStyle = navsBullets then
  begin
    FContainerBullets.style.setProperty('display', 'block');
    // The idea is to make overriding colors optional if clNone
    // is present
    pgColor := '';
    if FBullets.Color <> clNone then
      pgColor := 'background:' + ColorToHtml(FBullets.Color)+';';
    aStyle := aStyle +
              Format('.swiper-pagination-bullet { %s opacity: %.1f; width: %dpx; height: %dpx; }'#13#10+
              '.swiper-container-horizontal> .swiper-pagination-bullets .swiper-pagination-bullet { margin: %dpx %dpx; }'#13#10
              , [pgColor, FBullets.Opacity, FBullets.Size, FBullets.Size,
                      FBullets.SpaceEdge, FBullets.SpaceBetween]);
    pgColorActive := '';
    if FBullets.ColorActive <> clNone then
      pgColorActive := 'background:' + ColorToHtml(FBullets.ColorActive)+';';
    aStyle := aStyle +
              Format('.swiper-pagination-bullet-active { %s }',
                [pgColorActive]);
  end
  else
    FContainerBullets.style.setProperty('display', 'none');

  AddControlStyle(aStyle);
end;

function TImageSlider.CreateElement: TJSElement;
var
  FWrapper: TJSHTMLElement;
begin
  Result := document.createElement('DIV');
  Result['class'] := 'swiper-container';

  // keep zindex 0 for VSC dezigner!
  if (csDesigning in ComponentState) then
    TJSHTMLElement(Result).style.setProperty('z-index','0');

  FContainerImages := TJSHTMLElement(document.createElement('DIV'));
  FWrapper := TJSHTMLElement(document.createElement('DIV'));
  FWrapper['class'] := 'swiper-wrapper';
  FContainerImages.appendChild(FWrapper);
  Result.appendChild(FContainerImages);

  FContainerThumbnails := TJSHTMLElement(document.createElement('DIV'));
  FContainerThumbnails['class'] := thumbsClassName;
  FWrapper := TJSHTMLElement(document.createElement('DIV'));
  FWrapper['class'] := 'swiper-wrapper';
  FContainerThumbnails.appendChild(FWrapper);
  Result.appendChild(FContainerThumbnails);

  FNextButton := TJSHTMLElement(document.createElement('DIV'));
  FNextButton['class'] := 'swiper-button-next swiper-button-color';
  FContainerImages.appendChild(FNextButton);
  FPrevButton := TJSHTMLElement(document.createElement('DIV'));
  FPrevButton['class'] := 'swiper-button-prev swiper-button-color';
  FContainerImages.appendChild(FPrevButton);

  FContainerBullets := TJSHTMLElement(document.createElement('DIV'));
  FContainerBullets['class'] := 'swiper-pagination';
  FContainerImages.appendChild(FContainerBullets);
end;

procedure TImageSlider.CreateInitialize;
begin
  inherited;
  BorderStyle := bsSingle;
  CustomBorder := true;
  Width := 640;
  Height := 320;
end;

procedure TImageSlider.SetImageURLs(const Value: TStringList);
begin
  FImageURLs.Assign(Value);
  RefreshImages;
end;

procedure TImageSlider.Update;
begin
  if FThumbnailsSwiper <> nil then
    FThumbnailsSwiper.Update;
  if FImageSwiper <> nil then
  begin
    FImageSwiper.Update;
    SetStyles;
  end;
end;

procedure TImageSlider.UpdateElementSize;
begin
  inherited;
  Update;
end;

procedure TImageSlider.UpdateElementVisual;
begin
  inherited;
  RecreateSwipers;
end;

procedure TImageSlider.AddSlides(aSwiper: TJSSwiper; isThumbnailsSwiper: Boolean);
var
  FSlide: TJSHTMLElement;
  i: Integer;
  aSlide, style, anId: String;
  slidesArray: TJSArray;
begin
  if FImageURLs.count = 0 then
    exit;
  slidesArray := TJSArray.New;
  for i := 0 to FImageURLs.count-1 do
  begin
    FSlide := TJSHTMLElement(document.createElement('DIV'));
    FSlide['class'] := 'swiper-slide';
    style := 'background-position:center;';

    if isThumbnailsSwiper then
      style := style + 'background-size:100% 100%;'
    else
    begin
      style := style + 'background-size:auto 100%;background-repeat:no-repeat;'
    end;

    if isThumbnailsSwiper then
      style := style + 'width:25%;height:100%;';
    style := style + Format('background-image:url(%s);', [FImageURLs[i]]);
    anId := Format('m%d',[i]);
    if isThumbnailsSwiper then
      anId := Format('t%d',[i]);
    FSlide['id'] := anId;
    aSlide := Format('<div id="%s" class="swiper-slide" style="%s"></div>', [anId, style]);
    slidesArray.push(aSlide);
  end;

  aSwiper.removeAllSlides;
  aSwiper.appendSlide(slidesArray);
end;

procedure TImageSlider.CreateSwipers;
var
  transitionEffect: String;
begin
  if not Assigned(FAppearance) then
    Exit;

  if FAppearance.NavigationStyle = navsThumbnails then
  begin
    if FThumbnailsSwiper <> nil then
      FThumbnailsSwiper.doDestroy(true, true);
    FThumbnailsSwiper := nil;
    FThumbnailsSwiper := CreateSwiper(
              FContainerThumbnails,
              FAppearance.Thumbnails.NumDisplayed,
              FAppearance.Thumbnails.SpaceBetween,
              nil, nil, nil, 'slide', False,
              True, True, True);
    addSlides(FThumbnailsSwiper, True);
  end;

  transitionEffect := 'slide';
  if Appearance.TransitionEffect = tefFade then
    transitionEffect := 'fade'
  else
  if Appearance.TransitionEffect = tefFlip then
    transitionEffect := 'flip';

  if FImageSwiper <> nil then
    FImageSwiper.doDestroy(true, true);

  FImageSwiper := CreateSwiper(
            FContainerImages, 1,
            10, // parameter not really used here
            FNextButton, FPrevButton, FThumbnailsSwiper,
            transitionEffect,
            FAppearance.NavigationStyle = navsBullets
            );

  addSlides(FImageSwiper, False);
  FImageSwiper.on('slideChange',@HandleDoImageChange);

  SetStyles;
end;

procedure TImageSlider.RecreateSwipers;
begin
  // do it only if swiper has been created at least once
  if FImageSwiper <> nil then
    CreateSwipers;
end;

procedure TImageSlider.InitScript;
begin
  CreateSwipers;
end;

procedure TImageSlider.SetColor(AValue: TColor);
begin
  inherited;
  if not Assigned(ElementHandle) then
    Exit;
  TJSHTMLElement(Element).style.setProperty('background-color', ColorToHtml(Color));
end;

function TImageSlider.GetActiveImageIndex: Integer;
begin
  Result := -1;
  if FImageSwiper = nil then
    Exit;
  if isUndefined(FImageSwiper.ActiveIndex) then
    Exit;
  Result := FImageSwiper.ActiveIndex;
end;

function TImageSlider.GetLastClickedIndex: Integer;
begin
  Result := -1;
  if FImageSwiper = nil then
    exit;
  if isUndefined(FImageSwiper.ClickedIndex) then
    exit;
  Result := FImageSwiper.ClickedIndex;
end;

function TImageSlider.GetPreviousActiveIndex: Integer;
begin
  Result := -1;
  if FImageSwiper = nil then
    exit;
  if isUndefined(FImageSwiper.PreviousIndex) then
    exit;
  Result := FImageSwiper.PreviousIndex;
end;

constructor TImageSlider.Create(AOwner: TComponent);
begin
  inherited;
  EnablePropagation := True;
  FImageSwiper := nil;
  FThumbnailsSwiper := nil;
  FContainerImages := nil;
  FContainerThumbnails := nil;
  FNextButton := nil;
  FPrevButton := nil;
  // Handle change event is not used as will be too frequent update
  FAppearance := TSliderAppearance.Create(Self);
  FImageURLs := TStringList.Create;
end;

destructor TImageSlider.Destroy;
begin
  FAppearance.Free;
  FImageURLs.Free;
  if FImageSwiper <> nil then
    FImageSwiper.doDestroy(true, true);
  if FThumbnailsSwiper <> nil then
    FThumbnailsSwiper.doDestroy(true, true);
  inherited;
end;

procedure TImageSlider.RefreshImages;
begin
  UpdateElement;
end;

function TImageSlider.HandleDoClick(Event: TJSMouseEvent): Boolean;
var
  anId, which: string;
  i: integer;
  el: TJSElement;
begin
  Result := inherited HandleDoClick(Event);
  el := Event.target;

  anId := '';
  if el.hasAttribute('id') then
    anId := el.getAttribute('id');

  if anId <> '' then
  begin
    which := anId[1];
    anId := copy(anId, 2);
    if TryStrToInt(anId, i) then
    begin
      if which = 'm' then
        HandleDoImageClick(i);
      if which = 't' then
        HandleDoThumbnailClick(i);
    end;
  end;
end;

procedure TImageSlider.HandleDoImageClick(Index: integer);
begin
  if Assigned(FOnImageClick) then
    FOnImageClick(Self, Index);
end;

procedure TImageSlider.HandleDoThumbnailClick(Index: integer);
begin
  if Assigned(FOnThumbnailClick) then
    FOnThumbnailClick(Self, Index);
end;

procedure TImageSlider.HandleDoImageChange(Event: TJSEvent);
begin
  if Assigned(FOnImageChange) then
    FOnImageChange(Self, ActiveImageIndex);
end;

end.
