unit UEditSubAccount;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.StdCtrls, DB,
  XData.Web.Client,
  WEBLib.ExtCtrls, WEBLib.DBCtrls,
  UDatamodule,
  UGlobvar, WEBLib.Imaging.pngimage;

type
  TEditSubAccount = class(TForm)
    WebPanel1: TPanel;
    WebLabel2: TLabel;
    WebDBEdit2: TDBEdit;
    WebLabel4: TLabel;
    WebLabel5: TLabel;
    WebDBCheckBox1: TDBCheckBox;
    WebDBEdit1: TDBEdit;
    WebDBEdit3: TDBEdit;
    WebPanel2: TPanel;
    WebButton1: TButton;
    WebButton2: TButton;
    procedure WebFormShow(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
    procedure WebButton2Click(Sender: TObject);
    procedure WebButton1Click(Sender: TObject);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
    procedure WebFormResize(Sender: TObject);
  private
    { Private-Deklarationen }
  protected
    procedure LoadDFMValues; override;
  public
    { Public-Deklarationen }
    Zeilen_Liste : TStringList;
    Zeilen_Count : integer;
    Form_Aktiv : boolean;

  end;

var
  EditSubAccount: TEditSubAccount;


implementation


procedure TEditSubAccount.WebButton1Click(Sender: TObject);
var hst,hst2,TT,MM,JJ,GB : String;
begin
  if WebDBCheckBox1.Checked then begin
    hst := Trim(WebDBEdit1.Text);
    WebDBEdit1.Text := hst;
    if hst = '' then begin
      MessageDlg('Email-Adresse muß erfasst werden', mtError, [], nil);
      WebDBEdit1.SetFocus;
      exit;
    end;
    hst := Trim(WebDBEdit3.Text);
    WebDBEdit3.Text := hst;
    if hst = '' then begin
      MessageDlg('Kennwort muß erfasst werden', mtError, [], nil);
      WebDBEdit3.SetFocus;
      exit;
    end;
    if length(hst) < 6 then begin
      MessageDlg('Kennwort muß mindestens 6 Zeichen lang sein', mtError, [], nil);
      WebDBEdit3.SetFocus;
      exit;
    end;
  end;
  try
    DataModule1.TeilnehmerDataSet.Post;
    DataModule1.TeilnehmerDataSet.ApplyUpdates;
    Close;
  except
    MessageDlg('Fehler beim Speichern', mtError, [], nil);
    exit;
  end;
end;

procedure TEditSubAccount.WebButton2Click(Sender: TObject);
begin
  DataModule1.TeilnehmerDataSet.Cancel;
  Close;

end;

procedure TEditSubAccount.WebFormClose(Sender: TObject;
  var Action: TCloseAction);
begin
  Zeilen_Liste.Clear;
  Zeilen_Liste.Free;

end;

procedure TEditSubAccount.WebFormCreate(Sender: TObject);
begin
  Form_Aktiv := false;

end;

procedure TEditSubAccount.WebFormResize(Sender: TObject);
begin
  if not Form_Aktiv then exit;

  Resize_Edit_Controls(WF_Edit,self,Zeilen_Liste,WebPanel1,WebPanel2);

end;

procedure TEditSubAccount.WebFormShow(Sender: TObject);
var i,j : integer;
    TT,MM,JJ : word;
    hst : String;
begin
  Resize_Top_Panel(self,WebPanel1);
  Resize_Bottom_Panel(self,WebPanel2);
  Zeilen_Liste := TStringList.Create;
  asm debugger; end;
  Zeilen_Count := 0;
  Zeilen_Liste_Add(Zeilen_Liste,Zeilen_Count,WebLabel2,WebDBEdit2,nil);
  Zeilen_Liste_Add(Zeilen_Liste,Zeilen_Count,nil,WebDBCheckBox1,nil);
  Zeilen_Liste_Add(Zeilen_Liste,Zeilen_Count,WebLabel4,WebDBEdit1,nil);
  Zeilen_Liste_Add(Zeilen_Liste,Zeilen_Count,WebLabel5,WebDBEdit3,nil);
  Resize_Edit_Controls(WF_Edit,self,Zeilen_Liste,WebPanel1,WebPanel2);
  Form_Aktiv := true;

end;


procedure TEditSubAccount.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebLabel2 := TLabel.Create(Self);
  WebLabel4 := TLabel.Create(Self);
  WebLabel5 := TLabel.Create(Self);
  WebPanel1 := TPanel.Create(Self);
  WebDBEdit2 := TDBEdit.Create(Self);
  WebDBCheckBox1 := TDBCheckBox.Create(Self);
  WebDBEdit1 := TDBEdit.Create(Self);
  WebDBEdit3 := TDBEdit.Create(Self);
  WebPanel2 := TPanel.Create(Self);
  WebButton1 := TButton.Create(Self);
  WebButton2 := TButton.Create(Self);

  WebLabel2.BeforeLoadDFMValues;
  WebLabel4.BeforeLoadDFMValues;
  WebLabel5.BeforeLoadDFMValues;
  WebPanel1.BeforeLoadDFMValues;
  WebDBEdit2.BeforeLoadDFMValues;
  WebDBCheckBox1.BeforeLoadDFMValues;
  WebDBEdit1.BeforeLoadDFMValues;
  WebDBEdit3.BeforeLoadDFMValues;
  WebPanel2.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  try
    Name := 'EditSubAccount';
    Left := 0;
    Top := 0;
    Width := 640;
    Height := 480;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -16;
    Font.Name := 'Arial';
    Font.Style := [];
    TabOrder := 1;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnResize', 'WebFormResize');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    WebLabel2.SetParentComponent(Self);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 7;
    WebLabel2.Top := 119;
    WebLabel2.Width := 34;
    WebLabel2.Height := 16;
    WebLabel2.Caption := 'Name';
    WebLabel2.Color := clBtnFace;
    WebLabel2.Font.Charset := DEFAULT_CHARSET;
    WebLabel2.Font.Color := clWindowText;
    WebLabel2.Font.Height := -13;
    WebLabel2.Font.Name := 'Arial';
    WebLabel2.Font.Style := [];
    WebLabel2.ParentFont := False;
    WebLabel4.SetParentComponent(Self);
    WebLabel4.Name := 'WebLabel4';
    WebLabel4.Left := 7;
    WebLabel4.Top := 217;
    WebLabel4.Width := 150;
    WebLabel4.Height := 16;
    WebLabel4.Caption := 'eMail-Adresse Unterkonto';
    WebLabel4.Color := clBtnFace;
    WebLabel4.Font.Charset := DEFAULT_CHARSET;
    WebLabel4.Font.Color := clWindowText;
    WebLabel4.Font.Height := -13;
    WebLabel4.Font.Name := 'Arial';
    WebLabel4.Font.Style := [];
    WebLabel4.ParentFont := False;
    WebLabel5.SetParentComponent(Self);
    WebLabel5.Name := 'WebLabel5';
    WebLabel5.Left := 7;
    WebLabel5.Top := 271;
    WebLabel5.Width := 121;
    WebLabel5.Height := 16;
    WebLabel5.Caption := 'Kennwort Unterkonto';
    WebLabel5.Color := clBtnFace;
    WebLabel5.Font.Charset := DEFAULT_CHARSET;
    WebLabel5.Font.Color := clWindowText;
    WebLabel5.Font.Height := -13;
    WebLabel5.Font.Name := 'Arial';
    WebLabel5.Font.Style := [];
    WebLabel5.ParentFont := False;
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 0;
    WebPanel1.Top := 0;
    WebPanel1.Width := 640;
    WebPanel1.Height := 105;
    WebPanel1.Align := alTop;
    WebPanel1.BorderColor := clSilver;
    WebPanel1.BorderStyle := bsSingle;
    WebPanel1.ChildOrder := 1;
    WebDBEdit2.SetParentComponent(Self);
    WebDBEdit2.Name := 'WebDBEdit2';
    WebDBEdit2.Left := 7;
    WebDBEdit2.Top := 141;
    WebDBEdit2.Width := 248;
    WebDBEdit2.Height := 22;
    WebDBEdit2.TabStop := False;
    WebDBEdit2.ChildOrder := 28;
    WebDBEdit2.ElementClassName := 'form-control';
    WebDBEdit2.ReadOnly := True;
    WebDBEdit2.Text := 'WebDBEdit1';
    WebDBEdit2.DataField := 'NAME';
    WebDBEdit2.DataSource := DataModule1.TeilnehmerDataSource;
    WebDBCheckBox1.SetParentComponent(Self);
    WebDBCheckBox1.Name := 'WebDBCheckBox1';
    WebDBCheckBox1.Left := 8;
    WebDBCheckBox1.Top := 176;
    WebDBCheckBox1.Width := 217;
    WebDBCheckBox1.Height := 22;
    WebDBCheckBox1.Caption := 'Unterkonto aktiv';
    WebDBCheckBox1.ChildOrder := 11;
    WebDBCheckBox1.Color := clNone;
    WebDBCheckBox1.State := cbUnchecked;
    WebDBCheckBox1.DataField := 'KZ_SUBACCOUNT';
    WebDBCheckBox1.DataSource := DataModule1.TeilnehmerDataSource;
    WebDBCheckBox1.ValueChecked := '1';
    WebDBCheckBox1.ValueUnChecked := '0';
    WebDBEdit1.SetParentComponent(Self);
    WebDBEdit1.Name := 'WebDBEdit1';
    WebDBEdit1.Left := 7;
    WebDBEdit1.Top := 239;
    WebDBEdit1.Width := 248;
    WebDBEdit1.Height := 22;
    WebDBEdit1.ChildOrder := 28;
    WebDBEdit1.ElementClassName := 'form-control';
    WebDBEdit1.Text := 'WebDBEdit1';
    WebDBEdit1.DataField := 'EMAILSUBACCOUNT';
    WebDBEdit1.DataSource := DataModule1.TeilnehmerDataSource;
    WebDBEdit3.SetParentComponent(Self);
    WebDBEdit3.Name := 'WebDBEdit3';
    WebDBEdit3.Left := 8;
    WebDBEdit3.Top := 293;
    WebDBEdit3.Width := 248;
    WebDBEdit3.Height := 22;
    WebDBEdit3.ChildOrder := 28;
    WebDBEdit3.ElementClassName := 'form-control';
    WebDBEdit3.Text := 'WebDBEdit1';
    WebDBEdit3.DataField := 'KENNWORT';
    WebDBEdit3.DataSource := DataModule1.TeilnehmerDataSource;
    WebPanel2.SetParentComponent(Self);
    WebPanel2.Name := 'WebPanel2';
    WebPanel2.Left := 0;
    WebPanel2.Top := 420;
    WebPanel2.Width := 640;
    WebPanel2.Height := 60;
    WebPanel2.Align := alBottom;
    WebPanel2.BorderColor := clSilver;
    WebPanel2.BorderStyle := bsSingle;
    WebPanel2.ChildOrder := 8;
    WebButton1.SetParentComponent(WebPanel2);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 8;
    WebButton1.Top := 19;
    WebButton1.Width := 121;
    WebButton1.Height := 41;
    WebButton1.Caption := 'Speichern';
    WebButton1.ChildOrder := 15;
    WebButton1.ElementClassName := 'btn-primary';
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    WebButton2.SetParentComponent(WebPanel2);
    WebButton2.Name := 'WebButton2';
    WebButton2.Left := 161;
    WebButton2.Top := 19;
    WebButton2.Width := 121;
    WebButton2.Height := 41;
    WebButton2.Caption := 'Zur'#252'ck';
    WebButton2.ChildOrder := 16;
    WebButton2.ElementClassName := 'btn-primary';
    SetEvent(WebButton2, Self, 'OnClick', 'WebButton2Click');
  finally
    WebLabel2.AfterLoadDFMValues;
    WebLabel4.AfterLoadDFMValues;
    WebLabel5.AfterLoadDFMValues;
    WebPanel1.AfterLoadDFMValues;
    WebDBEdit2.AfterLoadDFMValues;
    WebDBCheckBox1.AfterLoadDFMValues;
    WebDBEdit1.AfterLoadDFMValues;
    WebDBEdit3.AfterLoadDFMValues;
    WebPanel2.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
  end;
end;

end.
