unit UAnzSpeiseplan;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.Calendar, WEBLib.StdCtrls,
  DB,
  UGlobvar,
  UDatamodule,
  UAnzSpeiseplanTag2, WEBLib.ExtCtrls;

type
  TAnzSpeiseplan = class(TForm)
    WebDateTimePicker1: TDateTimePicker;
    WebLabel2: TLabel;
    WebLabel5: TLabel;
    WebComboBox4: TComboBox;
    WebPanel1: TPanel;
    WebPanel2: TPanel;
    WebButton2: TButton;
    WebButton1: TButton;
    procedure WebFormCreate(Sender: TObject);
    procedure WebButton1Click(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
    procedure WebButton2Click(Sender: TObject);
    procedure Zeige_Speiseplan_Tag(DataSet: TDataSet);
    procedure Get_Einrichtung(DataSet: TDataSet);
    procedure WebFormResize(Sender: TObject);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
  private
    { Private-Deklarationen }
  protected
    procedure LoadDFMValues; override;
  public
    { Public-Deklarationen }
    TA,
    EA : array[0 .. 100] of Integer;

    Zeilen_Liste : TStringList;
    Zeilen_Count : integer;
    Form_Aktiv : boolean;

  end;

var
  AnzSpeiseplan: TAnzSpeiseplan;
  Abbestell_Modus : integer;

implementation


procedure TAnzSpeiseplan.WebButton1Click(Sender: TObject);
begin
  Close;
end;

procedure TAnzSpeiseplan.WebButton2Click(Sender: TObject);
var D,D1,D2 : TDateTime;
    Newform: TAnzSpeiseplanTag2;
    i : integer;
    hst,
    hst2 : String;
begin
  AktTeilnehmerID := TA[WebComboBox4.ItemIndex];
  AktTeilnehmerName := WebComboBox4.Text;
  AktEInrichtungID := EA[WebComboBox4.ItemIndex];
  if WebDateTimePicker1.Date < Date then begin
    Meldungs_Fenster('Dieses Datum liegt in der Vergangenheit',mtError);
    exit;
  end;
  i := DayOfWeek(WebDateTimePicker1.Date);
  case i of
    1 : D1 := WebDateTimePicker1.Date - 6;
    2 : D1 := WebDateTimePicker1.Date;
    3 : D1 := WebDateTimePicker1.Date - 1;
    4 : D1 := WebDateTimePicker1.Date - 2;
    5 : D1 := WebDateTimePicker1.Date - 3;
    6 : D1 := WebDateTimePicker1.Date - 4;
    7 : D1 := WebDateTimePicker1.Date - 5;
  end;
  D2 := D1 + 6;
  hst := DateToStr(D1);
  hst2 := DateToStr(D2);


  Datamodule1.SpeiseplanDataSet.Close;
  Datamodule1.SpeiseplanDataSet.AfterOpen := Get_Einrichtung;
  DataModule1.SpeiseplanDataSet.QueryString := '$filter=TID eq '+IntToStr(AktTeilnehmerID)
     + ' and Datum ge '+''''+hst +''''+' and Datum le '+''''+hst2+''''+' &$orderby=Datum';
  DataModule1.SpeiseplanDataSet.Load;

end;

procedure TAnzSpeiseplan.WebFormClose(Sender: TObject;
  var Action: TCloseAction);
begin
  Zeilen_Liste.Clear;
  Zeilen_Liste.Free;

end;

procedure TAnzSpeiseplan.WebFormCreate(Sender: TObject);
begin
  Form_Aktiv := false;
end;

procedure TAnzSpeiseplan.WebFormResize(Sender: TObject);
begin
  if not Form_Aktiv then exit;
  Resize_Edit_Controls(WF_Edit,self,Zeilen_Liste,WebPanel1,WebPanel2);

end;

procedure TAnzSpeiseplan.WebFormShow(Sender: TObject);
var i : integer;
begin
  Resize_Top_Panel(self,WebPanel1);
  Resize_Bottom_Panel(self,WebPanel2);
  Zeilen_Liste := TStringList.Create;
  Zeilen_Count := 0;
  Zeilen_Liste_Add(Zeilen_Liste,Zeilen_Count,WebLabel5,WebComboBox4,nil);
  Zeilen_Liste_Add(Zeilen_Liste,Zeilen_Count,WebLabel2,WebDateTimePicker1,nil);
  Form_Aktiv := true;

  Resize_Edit_Controls(WF_Edit,self,Zeilen_Liste,WebPanel1,WebPanel2);

  if Abbestell_Modus > 0 then begin
    WebButton2.Caption := 'Essen abbestellen';
    WebDateTimePicker1.Enabled := false;
    case Abbestell_Modus of
      1 : begin
            WebDateTimePicker1.Date := Date;
          end;
      2 : begin
            WebDateTimePicker1.Date := Date + 1;
          end;
      3 : begin
            WebDateTimePicker1.Date := Date + 2;
          end;
      4 : begin
            WebDateTimePicker1.Date := Date + 1;
            WebDateTimePicker1.Enabled := true;
          end;

    end
  end
  else begin
    WebDateTimePicker1.Date := Date;
  end;


  WebComboBox4.Items.Clear;
  for i := 0 to 100 do begin
    EA[i] := 0;
    TA[i] := 0;
  end;

  Datamodule1.TeilnehmerDataSet.First;
  i := 0;
  while not Datamodule1.TeilnehmerDataSet.EOF do begin
    if UnterKonto_Aktiv then begin
      if Datamodule1.TeilnehmerDataSet.FieldByName('ID').AsInteger = UnterKonto_ID then begin
        WebComboBox4.Items.Add(Datamodule1.TeilnehmerDataSet.FieldByName('Name').AsString);
        EA[i] := Datamodule1.TeilnehmerDataSet.FieldByName('Einrichtung').AsInteger;
        TA[i] := Datamodule1.TeilnehmerDataSet.FieldByName('ID').AsInteger;
        UK_EMail := Datamodule1.TeilnehmerDataSet.FieldByName('EMailSubAccount').AsString;
      end;
    end
    else begin
      WebComboBox4.Items.Add(Datamodule1.TeilnehmerDataSet.FieldByName('Name').AsString);
      EA[i] := Datamodule1.TeilnehmerDataSet.FieldByName('Einrichtung').AsInteger;
      TA[i] := Datamodule1.TeilnehmerDataSet.FieldByName('ID').AsInteger;
      i := i + 1;
    end;
    Datamodule1.TeilnehmerDataSet.Next;
  end;
  WebComboBox4.ItemIndex := 0;
  if UnterKonto_Aktiv then begin
    WebComboBox4.Enabled := false;
  end;

end;


procedure TAnzSpeiseplan.Zeige_Speiseplan_Tag(DataSet: TDataSet);
var newform : TAnzSpeiseplanTag2;
begin
  newform := TAnzSpeiseplanTag2.CreateNew(procedure(AForm: TObject)
    begin
//      Datamodule1.SpeiseplanDataset.Load;
      (AForm as TAnzSpeiseplanTag2).Caption := 'Speiseplan';
    end
  );
  newform.Border := fbSingle;
  newform.ShowModal(procedure(AValue: TModalResult)
  begin
//
  end
  );




end;


procedure TAnzSpeiseplan.Get_Einrichtung(DataSet: TDataSet);
begin
  Datamodule1.EinrichtungDataSet.Close;
  Datamodule1.EinrichtungDataSet.AfterOpen := Zeige_Speiseplan_Tag;
  DataModule1.EinrichtungDataSet.QueryString := '$filter=NL_Key eq '+IntToStr(AktFirmaNo)
     + ' and Nummer eq '+IntToStr(EA[WebComboBox4.ItemIndex]);
  DataModule1.EinrichtungDataSet.Load;

end;

procedure TAnzSpeiseplan.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebLabel2 := TLabel.Create(Self);
  WebLabel5 := TLabel.Create(Self);
  WebDateTimePicker1 := TDateTimePicker.Create(Self);
  WebComboBox4 := TComboBox.Create(Self);
  WebPanel1 := TPanel.Create(Self);
  WebPanel2 := TPanel.Create(Self);
  WebButton2 := TButton.Create(Self);
  WebButton1 := TButton.Create(Self);

  WebLabel2.BeforeLoadDFMValues;
  WebLabel5.BeforeLoadDFMValues;
  WebDateTimePicker1.BeforeLoadDFMValues;
  WebComboBox4.BeforeLoadDFMValues;
  WebPanel1.BeforeLoadDFMValues;
  WebPanel2.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  try
    Name := 'AnzSpeiseplan';
    Left := 0;
    Top := 0;
    Width := 640;
    Height := 480;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Arial';
    Font.Style := [];
    TabOrder := 1;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnResize', 'WebFormResize');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    WebLabel2.SetParentComponent(Self);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 16;
    WebLabel2.Top := 180;
    WebLabel2.Width := 104;
    WebLabel2.Height := 14;
    WebLabel2.Caption := 'Ausgew'#228'hltes Datum';
    WebLabel5.SetParentComponent(Self);
    WebLabel5.Name := 'WebLabel5';
    WebLabel5.Left := 16;
    WebLabel5.Top := 114;
    WebLabel5.Width := 85;
    WebLabel5.Height := 14;
    WebLabel5.Caption := 'Essensteilnehmer';
    WebLabel5.Color := clBtnFace;
    WebDateTimePicker1.SetParentComponent(Self);
    WebDateTimePicker1.Name := 'WebDateTimePicker1';
    WebDateTimePicker1.Left := 16;
    WebDateTimePicker1.Top := 200;
    WebDateTimePicker1.Width := 297;
    WebDateTimePicker1.Height := 41;
    WebDateTimePicker1.BorderStyle := bsSingle;
    WebDateTimePicker1.ChildOrder := 4;
    WebDateTimePicker1.Color := clWhite;
    WebDateTimePicker1.Date := 44097.583971446760000000;
    WebDateTimePicker1.Font.Charset := DEFAULT_CHARSET;
    WebDateTimePicker1.Font.Color := clWindowText;
    WebDateTimePicker1.Font.Height := -19;
    WebDateTimePicker1.Font.Name := 'Arial';
    WebDateTimePicker1.Font.Style := [];
    WebDateTimePicker1.ParentFont := False;
    WebDateTimePicker1.Role := '';
    WebDateTimePicker1.Text := '';
    WebComboBox4.SetParentComponent(Self);
    WebComboBox4.Name := 'WebComboBox4';
    WebComboBox4.Left := 16;
    WebComboBox4.Top := 134;
    WebComboBox4.Width := 297;
    WebComboBox4.Height := 22;
    WebComboBox4.Font.Charset := DEFAULT_CHARSET;
    WebComboBox4.Font.Color := clWindowText;
    WebComboBox4.Font.Height := -19;
    WebComboBox4.Font.Name := 'Arial';
    WebComboBox4.Font.Style := [];
    WebComboBox4.ParentFont := False;
    WebComboBox4.Text := '--';
    WebComboBox4.ItemIndex := 0;
    WebComboBox4.Items.BeginUpdate;
    try
      WebComboBox4.Items.Clear;
      WebComboBox4.Items.Add('--');
      WebComboBox4.Items.Add('Januar');
      WebComboBox4.Items.Add('Februar');
      WebComboBox4.Items.Add('M'#228'rz');
      WebComboBox4.Items.Add('April');
      WebComboBox4.Items.Add('Mai');
      WebComboBox4.Items.Add('Juni');
      WebComboBox4.Items.Add('Juli');
      WebComboBox4.Items.Add('August');
      WebComboBox4.Items.Add('September');
      WebComboBox4.Items.Add('Oktober');
      WebComboBox4.Items.Add('November');
      WebComboBox4.Items.Add('Dezember');
    finally
      WebComboBox4.Items.EndUpdate;
    end;
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 0;
    WebPanel1.Top := 0;
    WebPanel1.Width := 640;
    WebPanel1.Height := 89;
    WebPanel1.Align := alTop;
    WebPanel1.BorderColor := clSilver;
    WebPanel1.BorderStyle := bsSingle;
    WebPanel1.ChildOrder := 1;
    WebPanel2.SetParentComponent(Self);
    WebPanel2.Name := 'WebPanel2';
    WebPanel2.Left := 0;
    WebPanel2.Top := 420;
    WebPanel2.Width := 640;
    WebPanel2.Height := 60;
    WebPanel2.Align := alBottom;
    WebPanel2.BorderColor := clSilver;
    WebPanel2.BorderStyle := bsSingle;
    WebPanel2.ChildOrder := 5;
    WebButton2.SetParentComponent(WebPanel2);
    WebButton2.Name := 'WebButton2';
    WebButton2.Left := 16;
    WebButton2.Top := 13;
    WebButton2.Width := 177;
    WebButton2.Height := 25;
    WebButton2.Caption := 'Speiseplan anzeigen';
    WebButton2.ChildOrder := 4;
    WebButton2.ElementClassName := 'btn-primary';
    SetEvent(WebButton2, Self, 'OnClick', 'WebButton2Click');
    WebButton1.SetParentComponent(WebPanel2);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 217;
    WebButton1.Top := 13;
    WebButton1.Width := 96;
    WebButton1.Height := 25;
    WebButton1.Caption := 'Zur'#252'ck';
    WebButton1.ChildOrder := 3;
    WebButton1.ElementClassName := 'btn-primary';
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
  finally
    WebLabel2.AfterLoadDFMValues;
    WebLabel5.AfterLoadDFMValues;
    WebDateTimePicker1.AfterLoadDFMValues;
    WebComboBox4.AfterLoadDFMValues;
    WebPanel1.AfterLoadDFMValues;
    WebPanel2.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
  end;
end;

end.