unit UEditTeilnehmer;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.StdCtrls, DB,
  XData.Web.Client,
  WEBLib.ExtCtrls, WEBLib.DBCtrls,
  XData.Web.Connection,

  UDatamodule,
  UGlobvar, WEBLib.Imaging.pngimage;

type
  TEditTeilnehmer = class(TForm)
    WebPanel1: TPanel;
    WebLabel2: TLabel;
    WebDBEdit2: TDBEdit;
    WebLabel13: TLabel;
    WebLabel20: TLabel;
    WebLabel3: TLabel;
    WebLabel4: TLabel;
    WebLabel5: TLabel;
    WebComboBox1: TComboBox;
    WebComboBox2: TComboBox;
    WebComboBox3: TComboBox;
    WebComboBox4: TComboBox;
    WebComboBox6: TComboBox;
    WebPanel2: TPanel;
    WebButton1: TButton;
    WebButton2: TButton;
    procedure WebFormShow(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
    procedure After_Insert_Teilnehmer(DataSet: TDataSet);
    procedure WebButton2Click(Sender: TObject);
    procedure WebButton1Click(Sender: TObject);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
    procedure WebFormResize(Sender: TObject);
    procedure ServerError(Error: TXDataWebConnectionError);
  private
    { Private-Deklarationen }
  protected
    procedure LoadDFMValues; override;
  public
    { Public-Deklarationen }
    Alte_Einrichtung,
    Letzte_ID : integer;
    A_ID,
    E_ID : array[1 .. 100] of integer;

    Zeilen_Liste : TStringList;
    Zeilen_Count : integer;
    Form_Aktiv : boolean;

  end;

var
  EditTeilnehmer: TEditTeilnehmer;
  Teilnehmer_Insert_Mode : boolean;
  Teilnehmer_Delete_Mode : boolean;
  Teilnehmer_Activate_Mode : boolean;
  Teilnehmer_Deactivate_Mode : boolean;
  Teilnehmer_EChange_Mode : boolean;



implementation


procedure TEditTeilnehmer.WebButton1Click(Sender: TObject);
var hst,hst2,TT,MM,JJ,GB : String;
begin
  if (not Teilnehmer_Delete_Mode) and (not Teilnehmer_Activate_Mode) and (not Teilnehmer_DeActivate_Mode) and (not Teilnehmer_EChange_Mode) then begin
      hst := Trim(WebDBEdit2.Text);
      WebDBEdit2.Text := hst;
      if hst = '' then begin
        MessageDlg('Name muß erfasst werden', mtError, [], nil);
        WebDBEdit2.SetFocus;
        exit;
      end;

      if WebComboBox1.ItemIndex < 1 then begin
        MessageDlg('Geburtsdatum (Tag) muß erfasst werden', mtError, [], nil);
        WebComboBox1.SetFocus;
        exit;
      end;

      if WebComboBox2.ItemIndex < 1 then begin
        MessageDlg('Geburtsdatum (Monat) muß erfasst werden', mtError, [], nil);
        WebComboBox2.SetFocus;
        exit;
      end;

      if WebComboBox3.ItemIndex < 1 then begin
        MessageDlg('Geburtsdatum (Jahr) muß erfasst werden', mtError, [], nil);
        WebComboBox3.SetFocus;
        exit;
      end;

      if WebComboBox6.ItemIndex < 1 then begin
        MessageDlg('Geschlecht muß erfasst werden', mtError, [], nil);
        WebComboBox6.SetFocus;
        exit;
      end;

      if WebComboBox4.ItemIndex < 1 then begin
        MessageDlg('Einrichtung/Schule muß erfasst werden', mtError, [], nil);
        WebComboBox4.SetFocus;
        exit;
      end;




      Datamodule1.TeilnehmerDataSet.FieldByName('TT').AsInteger := WebComboBox1.ItemIndex;
      Datamodule1.TeilnehmerDataSet.FieldByName('MM').AsInteger := WebComboBox2.ItemIndex;
      hst := WebComboBox3.Text;
      Datamodule1.TeilnehmerDataSet.FieldByName('JJ').AsInteger := StrToInt(hst);





      try
        Datamodule1.TeilnehmerDataSet.FieldByName('Geburtsdatum').AsDateTime := EncodeDate(Datamodule1.TeilnehmerDataSet.FieldByName('JJ').AsInteger,
               Datamodule1.TeilnehmerDataSet.FieldByName('MM').AsInteger,
               Datamodule1.TeilnehmerDataSet.FieldByName('TT').AsInteger);
      except
        MessageDlg('Datum ist ungültig', mtError, [], nil);
        WebComboBox1.SetFocus;
        exit;
      end;
      hst := WebComboBox6.Text;
      Datamodule1.TeilnehmerDataSet.FieldByName('Geschlecht').AsString := hst;


{      if WebComboBox5.ItemIndex < 1 then begin
        MessageDlg('Variante muß erfasst werden', mtError, [], nil);
        WebComboBox5.SetFocus;
        exit;
      end;
}

      Datamodule1.TeilnehmerDataSet.FieldByName('Einrichtung').AsInteger := E_ID[WebComboBox4.ItemIndex];
      Datamodule1.TeilnehmerDataSet.FieldByName('Variante').AsInteger := 0; //A_ID[WebComboBox5.ItemIndex];


    {  hst := Trim(WebDBEdit3.Text);
      WebDBEdit3.Text := hst;
      if hst = '' then begin
        MessageDlg('Vorname muß erfasst werden', mtError, [], nil);
        WebDBEdit3.SetFocus;
        exit;
      end;
    }

    {  if WebDBCombobox1.ItemIndex = -1 then begin
        MessageDlg('Anrede muß erfasst werden', mtError, [], nil);
        WebDBCombobox1.SetFocus;
        exit;
      end;
    }
  end;
  if Teilnehmer_Delete_Mode then begin
{
    hst := 'SELECT p.ERRNO FROM DELETE_TEILNEHMER ('+Datamodule1.TeilnehmerDataSet.FieldByName('ID').AsString+') p;';
    DataModule1.XDataWebConnection3.OnError := ServerError;
    DataModule1.XDataWebClientApiService.RawInvoke('IApiService.Do_Transaction', [0, hst],
      procedure(Response: TXDataClientResponse)
      var
        i: integer;
        hst : String;
      begin
        hst := JS.ToString(JS.ToObject(Response.Result)['value']);
        if hst <> '' then begin
          i := StrToInt(hst);
          if i = -1 then begin
            MessageDlg('Löschen nicht möglich, erst Bestellungen löschen', mtError, [], nil);
          end
          else begin
            Datamodule1.TeilnehmerDataSet.Refresh;
            Close;
          end;
        end
        else begin
          MessageDlg('Löschen nicht möglich, erst Bestellungen löschen', mtError, [], nil);
        end;
      end
    );
}


    try
      Datamodule1.TeilnehmerDataSet.Delete;
      DataModule1.TeilnehmerDataSet.ApplyUpdates;
    except
      MessageDlg('Fehler beim Löschen', mtError, [], nil);
      exit;
    end;
    Close;

  end
  else if Teilnehmer_Activate_Mode then begin
    DataModule1.TeilnehmerDataSet.Edit;
    Datamodule1.TeilnehmerDataSet.Fieldbyname('AKtiv').AsInteger := 1;
    try
      DataModule1.TeilnehmerDataSet.Post;
      DataModule1.TeilnehmerDataSet.ApplyUpdates;
      Close;
    except
      MessageDlg('Fehler beim Speichern', mtError, [], nil);
      exit;
    end;
  end
  else if Teilnehmer_DeActivate_Mode then begin
    DataModule1.TeilnehmerDataSet.Edit;
    Datamodule1.TeilnehmerDataSet.Fieldbyname('AKtiv').AsInteger := 0;
    try
      DataModule1.TeilnehmerDataSet.Post;
      DataModule1.TeilnehmerDataSet.ApplyUpdates;
      Close;
    except
      MessageDlg('Fehler beim Speichern', mtError, [], nil);
      exit;
    end;
  end
  else if Teilnehmer_EChange_Mode then begin
    if WebComboBox4.ItemIndex < 1 then begin
      MessageDlg('Einrichtung/Schule muß erfasst werden', mtError, [], nil);
      WebComboBox4.SetFocus;
      exit;
    end;

    DataModule1.TeilnehmerDataSet.Edit;
    Datamodule1.TeilnehmerDataSet.FieldByName('Einrichtung').AsInteger := E_ID[WebComboBox4.ItemIndex];
    try
      DataModule1.TeilnehmerDataSet.Post;
      DataModule1.TeilnehmerDataSet.ApplyUpdates;
      Close;
    except
      MessageDlg('Fehler beim Speichern', mtError, [], nil);
      exit;
    end;
  end



  else if not Teilnehmer_Insert_Mode then begin
    try
      DataModule1.TeilnehmerDataSet.Post;
      DataModule1.TeilnehmerDataSet.ApplyUpdates;
      Close;
    except
      MessageDlg('Fehler beim Speichern', mtError, [], nil);
      exit;
    end;
  end
  else begin

//SELECT p.NEWID
//FROM STD_INSERT_TEILNEHMER ('P_ID', 'P_NL_KEY', 'P_KDID', 'P_LFDNO', 'P_NAME',
//    'P_EINRICHTUNG', 'P_GESCHLECHT', 'P_GEBURTSDATUM', 'P_LKZ', 'P_LOESCHUNG',
//'P_PLAN1', 'P_PLAN2', 'P_PLAN3', 'P_PLAN4', 'P_PLAN5', 'P_PLAN6', 'P_PLAN7', 'P_EINRICHTUNGBEZ', 'P_TT', 'P_MM', 'P_JJ', 'P_BERECHTIGUNG', 'P_VARIANTE') p;





    TT := WebCombobox1.Text;
    MM := IntToStr(WebCombobox2.ItemIndex);
    if length(MM) < 2 then MM := '0' + MM;
    JJ := WebCombobox3.Text;
    GB := TT+'.'+MM+'.'+JJ;

    hst2 := 'Select p.NEWID FROM STD_INSERT_Teilnehmer(0,'
      +IntToStr(AktFirmaNo)+','+
      IntToStr(Datamodule1.KundenDataSet.Fieldbyname('ID').AsInteger)+','
      + Datamodule1.TeilnehmerDataSet.Fieldbyname('lfdno').AsString+','
      +AFZ(WebDBEdit2.Text)  // Name
      +AFZ(IntToStr(E_ID[WebComboBox4.ItemIndex])) //Einrichtung
      +AFZ(WebCombobox6.Text) //Geschlecht
//      +AFZ(DateToStr(Datamodule1.TeilnehmerDataSet.Fieldbyname('Geburtsdatum').AsDateTime))
      +AFZ(GB)  //Geburtsdatum
      +AFZ('F') //LKZ
      +'NULL,'  //Löschung
      +AFZ('0') //Plan1
      +AFZ('0')
      +AFZ('0')
      +AFZ('0')
      +AFZ('0')
      +AFZ('0')
      +AFZ('0') //Plan7
      +AFZ(WebCombobox4.Text)
      +AFZ(TT)
      +AFZ(MM)
      +AFZ(JJ)
      +AFZ('0')
      +AFZ2('0') // IntToStr(A_ID[WebComboBox5.ItemIndex])) // Variante

    +') p';


  DataModule1.XDataWebClientApiService.RawInvoke('IApiService.Do_Transaction', [0, hst2],
    procedure(Response: TXDataClientResponse)
    var
      i: integer;
      hst : String;
    begin
      hst := JS.ToString(JS.ToObject(Response.Result)['value']);
      if hst <> '' then begin
        i := StrToInt(hst);
        if i = -1 then begin
          MessageDlg('Teilnehmer nicht gespeichert', mtError, [], nil);
        end
        else begin
          Letzte_ID := i;
          Datamodule1.TeilnehmerDataSet.Close;
          Datamodule1.TeilnehmerDataSet.AfterOpen := After_Insert_Teilnehmer;
          if Lade_Daten(Datamodule1.TeilnehmerDataSet,'Teilnehmer','$filter=((NL_Key eq '+IntToStr(AktFirmaNo)+' AND (KDID eq '
            + Datamodule1.KundenDataSet.Fieldbyname('ID').AsString+')))') then begin
          end;
          Close;
        end;
      end
      else begin
        MessageDlg('Speichern nicht möglich', mtError, [], nil);
      end;
    end,
    procedure(Error: TXDataClientError)
    begin
      MessageDlg('Speichern nicht möglich', mtError, [], nil);
    end

  );
  end;

end;

procedure TEditTeilnehmer.WebButton2Click(Sender: TObject);
begin
  DataModule1.TeilnehmerDataSet.Cancel;
  Close;

end;

procedure TEditTeilnehmer.WebFormClose(Sender: TObject;
  var Action: TCloseAction);
begin
  Zeilen_Liste.Clear;
  Zeilen_Liste.Free;

end;

procedure TEditTeilnehmer.WebFormCreate(Sender: TObject);
begin
  Form_Aktiv := false;

  Letzte_ID := -1;

  if Teilnehmer_Delete_Mode or Teilnehmer_Activate_Mode or Teilnehmer_DeActivate_Mode then begin
    WebDBEdit2.ReadOnly := true;
    WebComboBox1.Enabled := false;
    WebComboBox2.Enabled := false;
    WebComboBox3.Enabled := false;
    WebComboBox6.Enabled := false;
    WebComboBox4.Enabled := false;


    if Teilnehmer_Delete_Mode then begin
      WebButton1.Caption := 'Löschen';
    end
    else if Teilnehmer_Activate_Mode then begin
      WebButton1.Caption := 'Aktivieren';
    end
    else if Teilnehmer_DeActivate_Mode then begin
      WebButton1.Caption := 'Deaktivieren';
    end
    else begin
      WebButton1.Caption := 'Speichern';
    end;

    WebLabel20.Visible := false;
    WebLabel13.Visible := false;
  end;



end;

procedure TEditTeilnehmer.WebFormResize(Sender: TObject);
begin
  if not Form_Aktiv then exit;

  Resize_Edit_Controls(WF_Edit,self,Zeilen_Liste,WebPanel1,WebPanel2);

end;

procedure TEditTeilnehmer.WebFormShow(Sender: TObject);
var i,j : integer;
    TT,MM,JJ : word;
    hst : String;
begin

  Resize_Top_Panel(self,WebPanel1);
  Resize_Bottom_Panel(self,WebPanel2);
  Zeilen_Liste := TStringList.Create;
  Zeilen_Count := 0;
  Zeilen_Liste_Add(Zeilen_Liste,Zeilen_Count,WebLabel2,WebDBEdit2,WebLabel13);
  Zeilen_Liste_Add(Zeilen_Liste,Zeilen_Count,WebLabel3,WebComboBox1,nil);
  Zeilen_Liste_Set(Zeilen_Liste,WebComboBox1,WebComboBox2);
  Zeilen_Liste_Set(Zeilen_Liste,WebComboBox1,WebComboBox3);

  Zeilen_Liste_Add(Zeilen_Liste,Zeilen_Count,WebLabel4,WebComboBox6,nil);
  Zeilen_Liste_Add(Zeilen_Liste,Zeilen_Count,WebLabel5,WebComboBox4,nil);
  Zeilen_Liste_Add(Zeilen_Liste,Zeilen_Count,WebLabel20,nil,nil);
  Resize_Edit_Controls(WF_Edit,self,Zeilen_Liste,WebPanel1,WebPanel2);
  Form_Aktiv := true;



  if Teilnehmer_Delete_Mode or Teilnehmer_Activate_Mode or Teilnehmer_DeActivate_Mode  or Teilnehmer_EChange_Mode then begin
    WebDBEdit2.ReadOnly := true;
    WebComboBox1.Enabled := false;
    WebComboBox2.Enabled := false;
    WebComboBox3.Enabled := false;
    WebComboBox6.Enabled := false;
    WebComboBox4.Enabled := false;

    if Teilnehmer_Delete_Mode then begin
      WebButton1.Caption := 'Löschen';
    end
    else if Teilnehmer_Activate_Mode then begin
      WebButton1.Caption := 'Aktivieren';
    end
    else if Teilnehmer_DeActivate_Mode then begin
      WebButton1.Caption := 'Deaktivieren';
    end
    else if Teilnehmer_EChange_Mode then begin
      WebButton1.Caption := 'Einrichtung ändern';
      WebComboBox4.Enabled := true;
    end
    else begin
      WebButton1.Caption := 'Speichern';
    end;

    WebLabel20.Visible := false;
    WebLabel13.Visible := false;
  end;





  WebComboBox1.ItemIndex := Datamodule1.TeilnehmerDataSet.FieldByName('TT').AsInteger;
  WebComboBox2.ItemIndex := Datamodule1.TeilnehmerDataSet.FieldByName('MM').AsInteger;
  WebComboBox3.Items.Clear;
  WebComboBox3.Items.Add('--');
  DecodeDate(Date,JJ,MM,TT);
  for i := JJ downto JJ-120 do begin
    WebComboBox3.Items.Add(IntToStr(i));
  end;
  if Datamodule1.TeilnehmerDataSet.FieldByName('JJ').AsInteger = 0 then begin
    WebComboBox3.ItemIndex := 0;
  end
  else begin
    hst := Datamodule1.TeilnehmerDataSet.FieldByName('JJ').AsString;
    WebComboBox3.ItemIndex := WebComboBox3.Items.IndexOf(hst);
  end;

  if Teilnehmer_Insert_Mode then begin
    WebComboBox6.ItemIndex := 0;
  end
  else begin
    hst := Datamodule1.TeilnehmerDataSet.FieldByName('Geschlecht').AsString;
    WebComboBox6.ItemIndex := WebComboBox6.Items.IndexOf(hst);
  end;


  if Datamodule1.TeilnehmerDataSet.FieldByName('Einrichtung').AsInteger = 0 then begin
    WebComboBox4.ItemIndex := 0;
  end
  else begin
    hst := Datamodule1.TeilnehmerDataSet.FieldByName('JJ').AsString;
    WebComboBox4.ItemIndex := WebComboBox3.Items.IndexOf(hst);
  end;
  Alte_Einrichtung := Datamodule1.TeilnehmerDataSet.FieldByName('Einrichtung').AsInteger;

  Datamodule1.EinrichtungDataSet.First;
  WebComboBox4.Items.Clear;
  WebComboBox4.Items.Add('-----------');
  for i := 1 to 100 do begin
    E_ID[i] := 0;
  end;
  i := 0;
  j := -1;
  while not Datamodule1.EinrichtungDataSet.Eof do begin
    i := i + 1;
    E_ID[i] := Datamodule1.EinrichtungDataSet.FieldByName('ID').AsInteger;
    if E_ID[i] = Datamodule1.TeilnehmerDataSet.FieldByName('Einrichtung').AsInteger then j := i;
    WebComboBox4.Items.Add(Datamodule1.EinrichtungDataSet.FieldByName('Bezeichnung').AsString);
    Datamodule1.EinrichtungDataSet.Next;
  end;
  if j <> -1 then begin
    WebComboBox4.ItemIndex := j;
  end;

  WebComboBox4.Enabled := false;
  if Teilnehmer_Insert_Mode or Teilnehmer_EChange_Mode then begin
    WebComboBox4.Enabled := true;
  end;



end;


procedure TEditTeilnehmer.After_Insert_Teilnehmer(DataSet: TDataSet);
begin
  if Letzte_ID <> -1 then begin
    if Datamodule1.TeilnehmerDataSet.Locate('ID',Letzte_ID,[]) then ;
    Letzte_ID := -1;
  end;
  Datamodule1.TeilnehmerDataSet.AfterOpen := nil;
end;


procedure TEditTeilnehmer.ServerError(Error: TXDataWebConnectionError);
var hst : String;
begin
  hst := 'Server 1 Fehler: '+Error.ErrorMessage;
  MessageDlg('hst', mtError, [], nil);
end;




procedure TEditTeilnehmer.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebLabel2 := TLabel.Create(Self);
  WebLabel13 := TLabel.Create(Self);
  WebLabel20 := TLabel.Create(Self);
  WebLabel3 := TLabel.Create(Self);
  WebLabel4 := TLabel.Create(Self);
  WebLabel5 := TLabel.Create(Self);
  WebPanel1 := TPanel.Create(Self);
  WebDBEdit2 := TDBEdit.Create(Self);
  WebComboBox1 := TComboBox.Create(Self);
  WebComboBox2 := TComboBox.Create(Self);
  WebComboBox3 := TComboBox.Create(Self);
  WebComboBox4 := TComboBox.Create(Self);
  WebComboBox6 := TComboBox.Create(Self);
  WebPanel2 := TPanel.Create(Self);
  WebButton1 := TButton.Create(Self);
  WebButton2 := TButton.Create(Self);

  WebLabel2.BeforeLoadDFMValues;
  WebLabel13.BeforeLoadDFMValues;
  WebLabel20.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  WebLabel4.BeforeLoadDFMValues;
  WebLabel5.BeforeLoadDFMValues;
  WebPanel1.BeforeLoadDFMValues;
  WebDBEdit2.BeforeLoadDFMValues;
  WebComboBox1.BeforeLoadDFMValues;
  WebComboBox2.BeforeLoadDFMValues;
  WebComboBox3.BeforeLoadDFMValues;
  WebComboBox4.BeforeLoadDFMValues;
  WebComboBox6.BeforeLoadDFMValues;
  WebPanel2.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  try
    Name := 'EditTeilnehmer';
    Left := 0;
    Top := 0;
    Width := 640;
    Height := 480;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -16;
    Font.Name := 'Arial';
    Font.Style := [];
    TabOrder := 1;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnResize', 'WebFormResize');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    WebLabel2.SetParentComponent(Self);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 7;
    WebLabel2.Top := 119;
    WebLabel2.Width := 34;
    WebLabel2.Height := 16;
    WebLabel2.Caption := 'Name';
    WebLabel2.Color := clBtnFace;
    WebLabel2.Font.Charset := DEFAULT_CHARSET;
    WebLabel2.Font.Color := clWindowText;
    WebLabel2.Font.Height := -13;
    WebLabel2.Font.Name := 'Arial';
    WebLabel2.Font.Style := [];
    WebLabel2.ParentFont := False;
    WebLabel13.SetParentComponent(Self);
    WebLabel13.Name := 'WebLabel13';
    WebLabel13.Left := 263;
    WebLabel13.Top := 141;
    WebLabel13.Width := 11;
    WebLabel13.Height := 18;
    WebLabel13.Caption := '*)';
    WebLabel13.Color := clBtnFace;
    WebLabel20.SetParentComponent(Self);
    WebLabel20.Name := 'WebLabel20';
    WebLabel20.Left := 7;
    WebLabel20.Top := 329;
    WebLabel20.Width := 95;
    WebLabel20.Height := 18;
    WebLabel20.Caption := '*) = Pflichtfeld';
    WebLabel20.Color := clBtnFace;
    WebLabel3.SetParentComponent(Self);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Left := 7;
    WebLabel3.Top := 169;
    WebLabel3.Width := 190;
    WebLabel3.Height := 16;
    WebLabel3.Caption := 'Geburtsdatum (Tag, Monat, Jahr)';
    WebLabel3.Color := clBtnFace;
    WebLabel3.Font.Charset := DEFAULT_CHARSET;
    WebLabel3.Font.Color := clWindowText;
    WebLabel3.Font.Height := -13;
    WebLabel3.Font.Name := 'Arial';
    WebLabel3.Font.Style := [];
    WebLabel3.ParentFont := False;
    WebLabel4.SetParentComponent(Self);
    WebLabel4.Name := 'WebLabel4';
    WebLabel4.Left := 7;
    WebLabel4.Top := 221;
    WebLabel4.Width := 66;
    WebLabel4.Height := 16;
    WebLabel4.Caption := 'Geschlecht';
    WebLabel4.Color := clBtnFace;
    WebLabel4.Font.Charset := DEFAULT_CHARSET;
    WebLabel4.Font.Color := clWindowText;
    WebLabel4.Font.Height := -13;
    WebLabel4.Font.Name := 'Arial';
    WebLabel4.Font.Style := [];
    WebLabel4.ParentFont := False;
    WebLabel5.SetParentComponent(Self);
    WebLabel5.Name := 'WebLabel5';
    WebLabel5.Left := 7;
    WebLabel5.Top := 271;
    WebLabel5.Width := 109;
    WebLabel5.Height := 16;
    WebLabel5.Caption := 'Einrichtung/Schule';
    WebLabel5.Color := clBtnFace;
    WebLabel5.Font.Charset := DEFAULT_CHARSET;
    WebLabel5.Font.Color := clWindowText;
    WebLabel5.Font.Height := -13;
    WebLabel5.Font.Name := 'Arial';
    WebLabel5.Font.Style := [];
    WebLabel5.ParentFont := False;
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 0;
    WebPanel1.Top := 0;
    WebPanel1.Width := 640;
    WebPanel1.Height := 105;
    WebPanel1.Align := alTop;
    WebPanel1.BorderColor := clSilver;
    WebPanel1.BorderStyle := bsSingle;
    WebPanel1.ChildOrder := 1;
    WebDBEdit2.SetParentComponent(Self);
    WebDBEdit2.Name := 'WebDBEdit2';
    WebDBEdit2.Left := 7;
    WebDBEdit2.Top := 141;
    WebDBEdit2.Width := 248;
    WebDBEdit2.Height := 22;
    WebDBEdit2.ChildOrder := 28;
    WebDBEdit2.ElementClassName := 'form-control';
    WebDBEdit2.Text := 'WebDBEdit1';
    WebDBEdit2.DataField := 'NAME';
    WebDBEdit2.DataSource := DataModule1.TeilnehmerDataSource;
    WebComboBox1.SetParentComponent(Self);
    WebComboBox1.Name := 'WebComboBox1';
    WebComboBox1.Left := 7;
    WebComboBox1.Top := 193;
    WebComboBox1.Width := 41;
    WebComboBox1.Height := 22;
    WebComboBox1.Text := '--';
    WebComboBox1.ItemIndex := 0;
    WebComboBox1.Items.BeginUpdate;
    try
      WebComboBox1.Items.Clear;
      WebComboBox1.Items.Add('--');
      WebComboBox1.Items.Add('01');
      WebComboBox1.Items.Add('02');
      WebComboBox1.Items.Add('03');
      WebComboBox1.Items.Add('04');
      WebComboBox1.Items.Add('05');
      WebComboBox1.Items.Add('06');
      WebComboBox1.Items.Add('07');
      WebComboBox1.Items.Add('08');
      WebComboBox1.Items.Add('09');
      WebComboBox1.Items.Add('10');
      WebComboBox1.Items.Add('11');
      WebComboBox1.Items.Add('12');
      WebComboBox1.Items.Add('13');
      WebComboBox1.Items.Add('14');
      WebComboBox1.Items.Add('15');
      WebComboBox1.Items.Add('16');
      WebComboBox1.Items.Add('17');
      WebComboBox1.Items.Add('18');
      WebComboBox1.Items.Add('19');
      WebComboBox1.Items.Add('20');
      WebComboBox1.Items.Add('21');
      WebComboBox1.Items.Add('22');
      WebComboBox1.Items.Add('23');
      WebComboBox1.Items.Add('24');
      WebComboBox1.Items.Add('25');
      WebComboBox1.Items.Add('26');
      WebComboBox1.Items.Add('27');
      WebComboBox1.Items.Add('28');
      WebComboBox1.Items.Add('29');
      WebComboBox1.Items.Add('30');
      WebComboBox1.Items.Add('31');
    finally
      WebComboBox1.Items.EndUpdate;
    end;
    WebComboBox2.SetParentComponent(Self);
    WebComboBox2.Name := 'WebComboBox2';
    WebComboBox2.Left := 62;
    WebComboBox2.Top := 193;
    WebComboBox2.Width := 87;
    WebComboBox2.Height := 22;
    WebComboBox2.Text := '--';
    WebComboBox2.ItemIndex := 0;
    WebComboBox2.Items.BeginUpdate;
    try
      WebComboBox2.Items.Clear;
      WebComboBox2.Items.Add('--');
      WebComboBox2.Items.Add('Januar');
      WebComboBox2.Items.Add('Februar');
      WebComboBox2.Items.Add('M'#228'rz');
      WebComboBox2.Items.Add('April');
      WebComboBox2.Items.Add('Mai');
      WebComboBox2.Items.Add('Juni');
      WebComboBox2.Items.Add('Juli');
      WebComboBox2.Items.Add('August');
      WebComboBox2.Items.Add('September');
      WebComboBox2.Items.Add('Oktober');
      WebComboBox2.Items.Add('November');
      WebComboBox2.Items.Add('Dezember');
    finally
      WebComboBox2.Items.EndUpdate;
    end;
    WebComboBox3.SetParentComponent(Self);
    WebComboBox3.Name := 'WebComboBox3';
    WebComboBox3.Left := 161;
    WebComboBox3.Top := 193;
    WebComboBox3.Width := 87;
    WebComboBox3.Height := 22;
    WebComboBox3.Text := '--';
    WebComboBox3.ItemIndex := 0;
    WebComboBox3.Items.BeginUpdate;
    try
      WebComboBox3.Items.Clear;
      WebComboBox3.Items.Add('--');
      WebComboBox3.Items.Add('Januar');
      WebComboBox3.Items.Add('Februar');
      WebComboBox3.Items.Add('M'#228'rz');
      WebComboBox3.Items.Add('April');
      WebComboBox3.Items.Add('Mai');
      WebComboBox3.Items.Add('Juni');
      WebComboBox3.Items.Add('Juli');
      WebComboBox3.Items.Add('August');
      WebComboBox3.Items.Add('September');
      WebComboBox3.Items.Add('Oktober');
      WebComboBox3.Items.Add('November');
      WebComboBox3.Items.Add('Dezember');
    finally
      WebComboBox3.Items.EndUpdate;
    end;
    WebComboBox4.SetParentComponent(Self);
    WebComboBox4.Name := 'WebComboBox4';
    WebComboBox4.Left := 8;
    WebComboBox4.Top := 293;
    WebComboBox4.Width := 247;
    WebComboBox4.Height := 22;
    WebComboBox4.Text := '--';
    WebComboBox4.ItemIndex := 0;
    WebComboBox4.Items.BeginUpdate;
    try
      WebComboBox4.Items.Clear;
      WebComboBox4.Items.Add('--');
      WebComboBox4.Items.Add('Januar');
      WebComboBox4.Items.Add('Februar');
      WebComboBox4.Items.Add('M'#228'rz');
      WebComboBox4.Items.Add('April');
      WebComboBox4.Items.Add('Mai');
      WebComboBox4.Items.Add('Juni');
      WebComboBox4.Items.Add('Juli');
      WebComboBox4.Items.Add('August');
      WebComboBox4.Items.Add('September');
      WebComboBox4.Items.Add('Oktober');
      WebComboBox4.Items.Add('November');
      WebComboBox4.Items.Add('Dezember');
    finally
      WebComboBox4.Items.EndUpdate;
    end;
    WebComboBox6.SetParentComponent(Self);
    WebComboBox6.Name := 'WebComboBox6';
    WebComboBox6.Left := 8;
    WebComboBox6.Top := 243;
    WebComboBox6.Width := 247;
    WebComboBox6.Height := 22;
    WebComboBox6.Text := '--';
    WebComboBox6.ItemIndex := 0;
    WebComboBox6.Items.BeginUpdate;
    try
      WebComboBox6.Items.Clear;
      WebComboBox6.Items.Add('--');
      WebComboBox6.Items.Add('Weiblich');
      WebComboBox6.Items.Add('M'#228'nnlich');
    finally
      WebComboBox6.Items.EndUpdate;
    end;
    WebPanel2.SetParentComponent(Self);
    WebPanel2.Name := 'WebPanel2';
    WebPanel2.Left := 0;
    WebPanel2.Top := 420;
    WebPanel2.Width := 640;
    WebPanel2.Height := 60;
    WebPanel2.Align := alBottom;
    WebPanel2.BorderColor := clSilver;
    WebPanel2.BorderStyle := bsSingle;
    WebPanel2.ChildOrder := 15;
    WebButton1.SetParentComponent(WebPanel2);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 3;
    WebButton1.Top := 19;
    WebButton1.Width := 209;
    WebButton1.Height := 41;
    WebButton1.Caption := 'Speichern';
    WebButton1.ChildOrder := 15;
    WebButton1.ElementClassName := 'btn-primary';
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    WebButton2.SetParentComponent(WebPanel2);
    WebButton2.Name := 'WebButton2';
    WebButton2.Left := 233;
    WebButton2.Top := 16;
    WebButton2.Width := 121;
    WebButton2.Height := 41;
    WebButton2.Caption := 'Zur'#252'ck';
    WebButton2.ChildOrder := 16;
    WebButton2.ElementClassName := 'btn-primary';
    SetEvent(WebButton2, Self, 'OnClick', 'WebButton2Click');
  finally
    WebLabel2.AfterLoadDFMValues;
    WebLabel13.AfterLoadDFMValues;
    WebLabel20.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
    WebLabel4.AfterLoadDFMValues;
    WebLabel5.AfterLoadDFMValues;
    WebPanel1.AfterLoadDFMValues;
    WebDBEdit2.AfterLoadDFMValues;
    WebComboBox1.AfterLoadDFMValues;
    WebComboBox2.AfterLoadDFMValues;
    WebComboBox3.AfterLoadDFMValues;
    WebComboBox4.AfterLoadDFMValues;
    WebComboBox6.AfterLoadDFMValues;
    WebPanel2.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
  end;
end;

end.