unit UStartseite;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.StdCtrls, DB,
  XData.Web.JsonDataset,
  XData.Web.Dataset,
  XData.Web.Client,
  XData.Web.Connection,

  WEBLib.DB,

  WEBLib.ExtCtrls,
  UDataModule, WEBLib.DBCtrls,
  Types,
  UGlobvar,
  ULogin;



type



  TStartseite = class(TForm)
    WebImageControl1: TImageControl;
    WebButton1: TButton;
    procedure WebFormCreate(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
    procedure WebButton1Click(Sender: TObject);
  private
    { Private-Deklarationen }
  protected
    procedure LoadDFMValues; override;

  public
    { Public-Deklarationen
    class function DoLogin(LoginProc, CreateAccountProc: TLoginProc): Boolean;
    property OnLogin: TLoginProc read FOnLogin write FOnLogin;
    property OnCreateAccount: TLoginProc read FOnCreateAccount write FOnCreateAccount;
}
  end;

var
  Startseite: TStartseite;

implementation




procedure TStartseite.WebButton1Click(Sender: TObject);
var newform: TLoginForm;

begin
  Akt_JWToken := '';
  WebButton1.Visible := false;

  newform := TLoginForm.CreateNew(procedure(AForm: TObject)
    begin
    end
  );
  newform.Border := fbSingle;
  newform.ShowModal(procedure(AValue: TModalResult)
  begin
    WebButton1.Visible := true;

  end
  );


end;

procedure TStartseite.WebFormCreate(Sender: TObject);
begin
  Application.OnImageCacheReady := WebFormShow;
  Akt_JWToken := '';

end;

procedure TStartseite.WebFormShow(Sender: TObject);
var hst : String;
    newform: TLoginForm;

begin
  application.OnImageCacheReady := nil;
  Akt_JWToken := '';
  WebButton1.Visible := false;

  newform := TLoginForm.CreateNew(procedure(AForm: TObject)
    begin
    end
  );
  newform.Border := fbSingle;
  newform.ShowModal(procedure(AValue: TModalResult)
  begin
    WebButton1.Visible := true;

  end
  );



end;


procedure TStartseite.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebImageControl1 := TImageControl.Create(Self);
  WebButton1 := TButton.Create(Self);

  WebImageControl1.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  try
    Name := 'Startseite';
    Left := 0;
    Top := 0;
    Width := 331;
    Height := 480;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Arial';
    Font.Style := [];
    TabOrder := 1;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    WebImageControl1.SetParentComponent(Self);
    WebImageControl1.Name := 'WebImageControl1';
    WebImageControl1.Left := 8;
    WebImageControl1.Top := 8;
    WebImageControl1.Width := 305;
    WebImageControl1.Height := 185;
    WebImageControl1.ChildOrder := 5;
    WebImageControl1.Picture.LoadFromFile('ustartseite.WebImageControl1.Picture.bmp');
    WebButton1.SetParentComponent(Self);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 80;
    WebButton1.Top := 280;
    WebButton1.Width := 121;
    WebButton1.Height := 57;
    WebButton1.Caption := 'Login';
    WebButton1.ChildOrder := 1;
    WebButton1.Font.Charset := DEFAULT_CHARSET;
    WebButton1.Font.Color := clWindowText;
    WebButton1.Font.Height := -16;
    WebButton1.Font.Name := 'Arial';
    WebButton1.Font.Style := [fsBold];
    WebButton1.ParentFont := False;
    WebButton1.Visible := False;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
  finally
    WebImageControl1.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
  end;
end;

end.
