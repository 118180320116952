unit UNeuesKonto2;

interface

uses
  Classes,
  SysUtils, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.StdCtrls, WEBLib.ExtCtrls,
  XData.Web.Client,

  UGlobvar,
  UDataModule,
  UEditSichfragen;


type
  TNeuesKonto2 = class(TForm)
    WebPanel1: TPanel;
    WebLabel7: TLabel;
    WebLabel8: TLabel;
    WebLabel2: TLabel;
    WebEdit2: TEdit;
    WebLabel14: TLabel;
    UNK_WebLabel_1: TLabel;
    WebEdit1: TEdit;
    WebLabel13: TLabel;
    WebLabel4: TLabel;
    WebEdit3: TEdit;
    WebLabel15: TLabel;
    WebLabel6: TLabel;
    WebEdit5: TEdit;
    WebLabel17: TLabel;
    WebLabel20: TLabel;
    WebButton2: TButton;
    WebButton1: TButton;
    procedure WebFormShow(Sender: TObject);
    procedure WebButton1Click(Sender: TObject);
    procedure WebButton2Click(Sender: TObject);
  private
    { Private-Deklarationen }
  protected
    procedure LoadDFMValues; override;
  public
    { Public-Deklarationen }
  end;

var
  NeuesKonto2: TNeuesKonto2;

implementation


procedure TNeuesKonto2.WebButton1Click(Sender: TObject);
begin
  Close;
end;

procedure TNeuesKonto2.WebButton2Click(Sender: TObject);
var hst,hst2 : String;
    newForm : TEditSichFragen;
    NLK : String;
begin

  hst := Do_Trim(WebEdit2.Text);
  WebEdit2.Text := hst;
  if hst = '' then begin
    MessageDlg('Email muß erfasst werden', mtError, [], nil);
    WebEdit2.SetFocus;
    exit;
  end;

{  if pos('@',hst) < 0 then begin
    MessageDlg('Dies kann keine gültige Email-Adresse sein', mtError, [], nil);
    WebEdit7.SetFocus;
    exit;
  end;
}

  hst := Do_Trim(WebEdit1.Text);
  WebEdit1.Text := hst;
  if hst = '' then begin
    MessageDlg('Wiederholung Email muß erfasst werden', mtError, [], nil);
    WebEdit1.SetFocus;
    exit;
  end;

  if Uppercase(WebEdit1.Text) <> Uppercase(WebEdit2.Text) then begin
    MessageDlg('Email und Wiederholung Email stimmen nicht überein', mtError, [], nil);
    WebEdit1.SetFocus;
    exit;
  end;



  hst := Do_Trim(WebEdit3.Text);
  WebEdit3.Text := hst;
  if hst = '' then begin
    MessageDlg('Kennwort muß erfasst werden', mtError, [], nil);
    WebEdit3.SetFocus;
    exit;
  end;

  if pos(' ',hst) > 0 then begin
    MessageDlg('Leerzeichen im Kennwort nicht erlaubt', mtError, [], nil);
    WebEdit3.SetFocus;
    exit;
  end;

  if length(hst) < 6 then begin
    MessageDlg('Kennwort muß mindestens 6 Zeichen lang sein', mtError, [], nil);
    WebEdit3.SetFocus;
    exit;
  end;


  hst := Do_Trim(WebEdit5.Text);
  WebEdit5.Text := hst;
  if hst = '' then begin
    MessageDlg('Wiederholung Kennwort muß erfasst werden', mtError, [], nil);
    WebEdit5.SetFocus;
    exit;
  end;

  if WebEdit3.Text <> WebEdit5.Text then begin
    MessageDlg('Kennwort und Wiederholung Kennwort stimmen nicht überein', mtError, [], nil);
    WebEdit3.SetFocus;
    exit;
  end;
  NeuKD_Email := WebEdit2.Text;
  NeuKD_Kennwort := WebEdit3.Text;

  NLK := Do_IntToStr(AktFirmaNo);
  DataModule1.XDataWebClientAuth.RawInvoke('ILoginService.CheckUser', [NLK,NeuKD_Email],
    procedure(Response: TXDataClientResponse)
    begin
      SichFragen_Modus := 0;

      newform := TEditSichFragen.CreateNew(procedure(AForm: TObject)
        begin
          (AForm as TEditSichFragen).Caption := 'Konto bearbeiten';
        end
      );
    //  newForm.Caption := 'Neues Konto';
      newform.Border := fbSingle;

      newform.ShowModal(procedure(AValue: TModalResult)
      begin
        if Neues_Konto_Erstellt then begin
          Close;
        end;
      end
      );

    end,

    procedure(Error: TXDataClientError)
    begin
      MessageDlg('Email-Adresse bereits in Verwendung für anderes Kundenkonto', mtError, [], nil);

    end
   );




end;

procedure TNeuesKonto2.WebFormShow(Sender: TObject);
begin
  WebLabel7.Caption := AktFirmaName;

end;

procedure TNeuesKonto2.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebLabel2 := TLabel.Create(Self);
  WebLabel14 := TLabel.Create(Self);
  UNK_WebLabel_1 := TLabel.Create(Self);
  WebLabel13 := TLabel.Create(Self);
  WebLabel4 := TLabel.Create(Self);
  WebLabel15 := TLabel.Create(Self);
  WebLabel6 := TLabel.Create(Self);
  WebLabel17 := TLabel.Create(Self);
  WebLabel20 := TLabel.Create(Self);
  WebPanel1 := TPanel.Create(Self);
  WebLabel7 := TLabel.Create(Self);
  WebLabel8 := TLabel.Create(Self);
  WebEdit2 := TEdit.Create(Self);
  WebEdit1 := TEdit.Create(Self);
  WebEdit3 := TEdit.Create(Self);
  WebEdit5 := TEdit.Create(Self);
  WebButton2 := TButton.Create(Self);
  WebButton1 := TButton.Create(Self);

  WebLabel2.BeforeLoadDFMValues;
  WebLabel14.BeforeLoadDFMValues;
  UNK_WebLabel_1.BeforeLoadDFMValues;
  WebLabel13.BeforeLoadDFMValues;
  WebLabel4.BeforeLoadDFMValues;
  WebLabel15.BeforeLoadDFMValues;
  WebLabel6.BeforeLoadDFMValues;
  WebLabel17.BeforeLoadDFMValues;
  WebLabel20.BeforeLoadDFMValues;
  WebPanel1.BeforeLoadDFMValues;
  WebLabel7.BeforeLoadDFMValues;
  WebLabel8.BeforeLoadDFMValues;
  WebEdit2.BeforeLoadDFMValues;
  WebEdit1.BeforeLoadDFMValues;
  WebEdit3.BeforeLoadDFMValues;
  WebEdit5.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  try
    Name := 'NeuesKonto2';
    Left := 0;
    Top := 0;
    Width := 640;
    Height := 480;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -16;
    Font.Name := 'Arial';
    Font.Style := [];
    TabOrder := 1;
    SetEvent(Self, 'OnShow', 'WebFormShow');
    WebLabel2.SetParentComponent(Self);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 8;
    WebLabel2.Top := 108;
    WebLabel2.Width := 83;
    WebLabel2.Height := 16;
    WebLabel2.Caption := 'eMail-Adresse';
    WebLabel2.Font.Charset := DEFAULT_CHARSET;
    WebLabel2.Font.Color := clWindowText;
    WebLabel2.Font.Height := -13;
    WebLabel2.Font.Name := 'Arial';
    WebLabel2.Font.Style := [];
    WebLabel2.ParentFont := False;
    WebLabel14.SetParentComponent(Self);
    WebLabel14.Name := 'WebLabel14';
    WebLabel14.Left := 268;
    WebLabel14.Top := 130;
    WebLabel14.Width := 11;
    WebLabel14.Height := 18;
    WebLabel14.Caption := '*)';
    UNK_WebLabel_1.SetParentComponent(Self);
    UNK_WebLabel_1.Name := 'UNK_WebLabel_1';
    UNK_WebLabel_1.Left := 8;
    UNK_WebLabel_1.Top := 158;
    UNK_WebLabel_1.Width := 166;
    UNK_WebLabel_1.Height := 16;
    UNK_WebLabel_1.Caption := 'Wiederholung eMail-Adresse';
    UNK_WebLabel_1.Font.Charset := DEFAULT_CHARSET;
    UNK_WebLabel_1.Font.Color := clWindowText;
    UNK_WebLabel_1.Font.Height := -13;
    UNK_WebLabel_1.Font.Name := 'Arial';
    UNK_WebLabel_1.Font.Style := [];
    UNK_WebLabel_1.ParentFont := False;
    WebLabel13.SetParentComponent(Self);
    WebLabel13.Name := 'WebLabel13';
    WebLabel13.Left := 268;
    WebLabel13.Top := 177;
    WebLabel13.Width := 11;
    WebLabel13.Height := 18;
    WebLabel13.Caption := '*)';
    WebLabel4.SetParentComponent(Self);
    WebLabel4.Name := 'WebLabel4';
    WebLabel4.Left := 8;
    WebLabel4.Top := 207;
    WebLabel4.Width := 54;
    WebLabel4.Height := 16;
    WebLabel4.Caption := 'Kennwort';
    WebLabel4.Font.Charset := DEFAULT_CHARSET;
    WebLabel4.Font.Color := clWindowText;
    WebLabel4.Font.Height := -13;
    WebLabel4.Font.Name := 'Arial';
    WebLabel4.Font.Style := [];
    WebLabel4.ParentFont := False;
    WebLabel15.SetParentComponent(Self);
    WebLabel15.Name := 'WebLabel15';
    WebLabel15.Left := 268;
    WebLabel15.Top := 228;
    WebLabel15.Width := 11;
    WebLabel15.Height := 18;
    WebLabel15.Caption := '*)';
    WebLabel6.SetParentComponent(Self);
    WebLabel6.Name := 'WebLabel6';
    WebLabel6.Left := 8;
    WebLabel6.Top := 259;
    WebLabel6.Width := 137;
    WebLabel6.Height := 16;
    WebLabel6.Caption := 'Wiederholung Kennwort';
    WebLabel6.Font.Charset := DEFAULT_CHARSET;
    WebLabel6.Font.Color := clWindowText;
    WebLabel6.Font.Height := -13;
    WebLabel6.Font.Name := 'Arial';
    WebLabel6.Font.Style := [];
    WebLabel6.ParentFont := False;
    WebLabel17.SetParentComponent(Self);
    WebLabel17.Name := 'WebLabel17';
    WebLabel17.Left := 268;
    WebLabel17.Top := 280;
    WebLabel17.Width := 11;
    WebLabel17.Height := 18;
    WebLabel17.Caption := '*)';
    WebLabel20.SetParentComponent(Self);
    WebLabel20.Name := 'WebLabel20';
    WebLabel20.Left := 8;
    WebLabel20.Top := 313;
    WebLabel20.Width := 81;
    WebLabel20.Height := 16;
    WebLabel20.Caption := '*) = Pflichtfeld';
    WebLabel20.Font.Charset := DEFAULT_CHARSET;
    WebLabel20.Font.Color := clWindowText;
    WebLabel20.Font.Height := -13;
    WebLabel20.Font.Name := 'Arial';
    WebLabel20.Font.Style := [];
    WebLabel20.ParentFont := False;
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 0;
    WebPanel1.Top := 0;
    WebPanel1.Width := 640;
    WebPanel1.Height := 89;
    WebPanel1.Align := alTop;
    WebPanel1.BorderColor := clSilver;
    WebPanel1.BorderStyle := bsSingle;
    WebPanel1.ChildOrder := 1;
    WebLabel7.SetParentComponent(WebPanel1);
    WebLabel7.Name := 'WebLabel7';
    WebLabel7.Left := 10;
    WebLabel7.Top := 8;
    WebLabel7.Width := 85;
    WebLabel7.Height := 19;
    WebLabel7.Caption := 'WebLabel7';
    WebLabel7.Font.Charset := DEFAULT_CHARSET;
    WebLabel7.Font.Color := clWindowText;
    WebLabel7.Font.Height := -16;
    WebLabel7.Font.Name := 'Arial';
    WebLabel7.Font.Style := [fsBold];
    WebLabel7.ParentFont := False;
    WebLabel8.SetParentComponent(WebPanel1);
    WebLabel8.Name := 'WebLabel8';
    WebLabel8.Left := 8;
    WebLabel8.Top := 56;
    WebLabel8.Width := 203;
    WebLabel8.Height := 18;
    WebLabel8.Caption := 'Neues Kundenkonto anlegen ';
    WebEdit2.SetParentComponent(Self);
    WebEdit2.Name := 'WebEdit2';
    WebEdit2.Left := 8;
    WebEdit2.Top := 130;
    WebEdit2.Width := 249;
    WebEdit2.Height := 22;
    WebEdit2.ChildOrder := 1;
    WebEdit2.ElementClassName := 'form-control';
    WebEdit1.SetParentComponent(Self);
    WebEdit1.Name := 'WebEdit1';
    WebEdit1.Left := 8;
    WebEdit1.Top := 177;
    WebEdit1.Width := 249;
    WebEdit1.Height := 22;
    WebEdit1.ChildOrder := 1;
    WebEdit1.ElementClassName := 'form-control';
    WebEdit1.TabOrder := 1;
    WebEdit3.SetParentComponent(Self);
    WebEdit3.Name := 'WebEdit3';
    WebEdit3.Left := 8;
    WebEdit3.Top := 227;
    WebEdit3.Width := 249;
    WebEdit3.Height := 22;
    WebEdit3.ChildOrder := 1;
    WebEdit3.ElementClassName := 'form-control';
    WebEdit3.PasswordChar := '*';
    WebEdit3.TabOrder := 2;
    WebEdit5.SetParentComponent(Self);
    WebEdit5.Name := 'WebEdit5';
    WebEdit5.Left := 8;
    WebEdit5.Top := 281;
    WebEdit5.Width := 249;
    WebEdit5.Height := 22;
    WebEdit5.ChildOrder := 1;
    WebEdit5.ElementClassName := 'form-control';
    WebEdit5.PasswordChar := '*';
    WebEdit5.TabOrder := 3;
    WebButton2.SetParentComponent(Self);
    WebButton2.Name := 'WebButton2';
    WebButton2.Left := 4;
    WebButton2.Top := 347;
    WebButton2.Width := 241;
    WebButton2.Height := 41;
    WebButton2.Caption := 'Weiter';
    WebButton2.ChildOrder := 13;
    WebButton2.ElementClassName := 'btn-primary';
    WebButton2.TabOrder := 4;
    SetEvent(WebButton2, Self, 'OnClick', 'WebButton2Click');
    WebButton1.SetParentComponent(Self);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 251;
    WebButton1.Top := 347;
    WebButton1.Width := 96;
    WebButton1.Height := 41;
    WebButton1.Caption := 'Zur'#252'ck';
    WebButton1.ChildOrder := 12;
    WebButton1.ElementClassName := 'btn-secondary';
    WebButton1.TabOrder := 30;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
  finally
    WebLabel2.AfterLoadDFMValues;
    WebLabel14.AfterLoadDFMValues;
    UNK_WebLabel_1.AfterLoadDFMValues;
    WebLabel13.AfterLoadDFMValues;
    WebLabel4.AfterLoadDFMValues;
    WebLabel15.AfterLoadDFMValues;
    WebLabel6.AfterLoadDFMValues;
    WebLabel17.AfterLoadDFMValues;
    WebLabel20.AfterLoadDFMValues;
    WebPanel1.AfterLoadDFMValues;
    WebLabel7.AfterLoadDFMValues;
    WebLabel8.AfterLoadDFMValues;
    WebEdit2.AfterLoadDFMValues;
    WebEdit1.AfterLoadDFMValues;
    WebEdit3.AfterLoadDFMValues;
    WebEdit5.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
  end;
end;

end.