unit UAnzSpeiseplanTag2;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.Grids, WEBLib.DBCtrls,
  WEBLib.DB,
  DB,
  XData.Web.JsonDataset,
  XData.Web.Dataset,
  XData.Web.Client,
  XData.Web.Connection,


  UGlobvar,
  UDatamodule, WEBLib.StdCtrls, WEBLib.ExtCtrls, WEBLib.ComCtrls,
  UAnzArtikel,
  UAnzArtBilder,
  UGetBewertung;


type
  TAnzSpeiseplanTag2 = class(TForm)
    WebPanel1: TPanel;
    WebPanel3: TPanel;
    WebButton1: TButton;
    WebDBGrid1: TDBGrid;
    WebButton2: TButton;
    WebButton3: TButton;
    WebButton6: TButton;
    WebPanel2: TPanel;
    WebButton4: TButton;
    WebButton5: TButton;
    WebButton7: TButton;
    WebPanel4: TPanel;
    StatusWebLabel: TLabel;
    StatusLabel2: TLabel;
    procedure WebButton1Click(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
    procedure WebButton2Click(Sender: TObject);
    procedure WebButton3Click(Sender: TObject);
    procedure WebButton4Click(Sender: TObject);
    procedure Zeige_Artikel(DataSet: TDataSet);
    procedure Zeige_Artikel_1(DataSet: TDataSet);
    procedure WebButton5Click(Sender: TObject);
    procedure Zeige_Bilder(DataSet: TDataSet);
    procedure Zeige_Bilder_1(DataSet: TDataSet);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
    procedure WebFormResize(Sender: TObject);
    procedure WebButton6Click(Sender: TObject);
    procedure Enable_Buttons(b : boolean);
    procedure WebDBGrid1Click(Sender: TObject);
    procedure Refresh_Speiseplan(DataSet: TDataSet);
    procedure ServerError_1(Error: TXDataWebConnectionError);
    procedure WebButton7Click(Sender: TObject);
  private
    { Private-Deklarationen }
  protected
    procedure LoadDFMValues; override;
  public
    { Public-Deklarationen }
    BestEnde : TdateTime;
    Akt_TID,
    Akt_SPTID : integer;
    Akt_Datum : TDateTime;
    Daten_Geaendert : boolean;


    Zeilen_Liste : TStringList;
    Zeilen_Count : integer;
    Form_Aktiv : boolean;

  end;

var
  AnzSpeiseplanTag2: TAnzSpeiseplanTag2;

implementation


procedure TAnzSpeiseplanTag2.WebButton1Click(Sender: TObject);
begin
  Datamodule1.SpeiseplanDataSet.Close;
  Close;

end;

procedure TAnzSpeiseplanTag2.WebButton2Click(Sender: TObject);
var D : TDateTime;
    A,G,TID,SPTID : integer;
    hst,hst2 : String;
begin
  if DataModule1.SpeiseplanDataSet.Fieldbyname('TID').AsInteger = 0 then exit;
  if DataModule1.SpeiseplanDataSet.Fieldbyname('KZ_Bestellt').AsInteger = 1 then exit;

  if DataModule1.SpeiseplanDataSet.Fieldbyname('Datum').AsDateTime < BestEnde  then begin
    MessageDlg('Dieses Datum liegt in der Vergangenheit oder Bestell-Ende bereits erreicht', mtError, [], nil);
    exit;
  end;
  try
    Enable_Buttons(false);
//    DataModule1.SpeiseplanDataSet.DisableControls;

    TID := DataModule1.SpeiseplanDataSet.Fieldbyname('TID').AsInteger;
    D := DataModule1.SpeiseplanDataSet.Fieldbyname('Datum').AsDateTime;
    G := DataModule1.SpeiseplanDataSet.Fieldbyname('Gruppe').AsInteger;
    A := DataModule1.SpeiseplanDataSet.Fieldbyname('Artikel').AsInteger;
    SPTID := DataModule1.SpeiseplanDataSet.Fieldbyname('SPTID').AsInteger;


    repeat
      DataModule1.SpeiseplanDataSet.Prior;
    until DataModule1.SpeiseplanDataSet.BOF or
      ((DataModule1.SpeiseplanDataSet.Fieldbyname('Datum').AsDateTime <> D)
      or (DataModule1.SpeiseplanDataSet.Fieldbyname('Gruppe').AsInteger <> G));
    if ((DataModule1.SpeiseplanDataSet.Fieldbyname('Datum').AsDateTime <> D)
         or (DataModule1.SpeiseplanDataSet.Fieldbyname('Gruppe').AsInteger <> G)) then begin
      DataModule1.SpeiseplanDataSet.Next;
    end;
    repeat
      try
        DataModule1.SpeiseplanDataSet.Edit;
        if DataModule1.SpeiseplanDataSet.Fieldbyname('SPTID').AsInteger = SPTID then begin
          DataModule1.SpeiseplanDataSet.Fieldbyname('KZ_Bestellt').AsInteger := 1;
        end
        else begin
          DataModule1.SpeiseplanDataSet.Fieldbyname('KZ_Bestellt').AsInteger := 0;
        end;
        DataModule1.SpeiseplanDataSet.Post;
        Daten_Geaendert := true;
      except
        MessageDlg('Fehler beim Speichern', mtError, [], nil);
        exit;
      end;
      DataModule1.SpeiseplanDataSet.Next;
    until DataModule1.SpeiseplanDataSet.EOF or
      ((DataModule1.SpeiseplanDataSet.Fieldbyname('Datum').AsDateTime <> D)
        or (DataModule1.SpeiseplanDataSet.Fieldbyname('Gruppe').AsInteger <> G));
  finally
//    DataModule1.SpeiseplanDataSet.EnableControls;
    Enable_Buttons(true);
    Akt_TID := TID;
    Akt_SPTID := SPTID;
    Akt_Datum := D;
    if Daten_Geaendert then begin
      DataModule1.SpeiseplanDataSet.ApplyUpdates;
    end;
    Daten_Geaendert := false;
    WebDBGrid1Click(Self);

//    DataModule1.SpeiseplanDataSet.Refresh;


{
    WebDBGrid1.OnClick := nil;
    WebDBGrid1.DataSource := nil;
    Datamodule1.XDataWebConnection1.OnError := ServerError_1;


    hst := DataModule1.SpeiseplanDataSet.QueryString;
    Datamodule1.SpeiseplanDataSet.Close;
    Datamodule1.SpeiseplanDataSet.AfterOpen := Refresh_SpeisePlan;
    DataModule1.SpeiseplanDataSet.QueryString := hst;
    DataModule1.SpeiseplanDataSet.Load;
}
  end;

end;

procedure TAnzSpeiseplanTag2.WebButton3Click(Sender: TObject);
var D : TDateTime;
    A,G,TID,SPTID : integer;
    hst : String;
begin
  if DataModule1.SpeiseplanDataSet.Fieldbyname('TID').AsInteger = 0 then exit;
  if DataModule1.SpeiseplanDataSet.Fieldbyname('KZ_Bestellt').AsInteger = 0 then exit;

  if DataModule1.SpeiseplanDataSet.Fieldbyname('Datum').AsDateTime < BestEnde  then begin
    MessageDlg('Dieses Datum liegt in der Vergangenheit oder Bestell-Ende bereits erreicht', mtError, [], nil);
    exit;
  end;
  try
    Enable_Buttons(false);
//    DataModule1.SpeiseplanDataSet.DisableControls;

    TID := DataModule1.SpeiseplanDataSet.Fieldbyname('TID').AsInteger;
    D := DataModule1.SpeiseplanDataSet.Fieldbyname('Datum').AsDateTime;
    G := DataModule1.SpeiseplanDataSet.Fieldbyname('Gruppe').AsInteger;
    A := DataModule1.SpeiseplanDataSet.Fieldbyname('Artikel').AsInteger;
    SPTID := DataModule1.SpeiseplanDataSet.Fieldbyname('SPTID').AsInteger;

    try
      DataModule1.SpeiseplanDataSet.Edit;
      DataModule1.SpeiseplanDataSet.Fieldbyname('KZ_Bestellt').AsInteger := 0;
      DataModule1.SpeiseplanDataSet.Post;
      Daten_Geaendert := true;

//      DataModule1.SpeiseplanDataSet.Refresh;
    except
      MessageDlg('Fehler beim Speichern', mtError, [], nil);
      exit;
    end;
  finally
//    DataModule1.SpeiseplanDataSet.EnableControls;
    Enable_Buttons(true);
    if Daten_Geaendert then begin
      DataModule1.SpeiseplanDataSet.ApplyUpdates;
    end;
    Daten_Geaendert := false;
//    DataModule1.SpeiseplanDataSet.Refresh;

    WebDBGrid1Click(Self);
    Akt_TID := TID;
    Akt_SPTID := SPTID;
    Akt_Datum := D;

{
    WebDBGrid1.OnClick := nil;
    WebDBGrid1.DataSource := nil;
    Datamodule1.XDataWebConnection1.OnError := ServerError_1;

    hst := DataModule1.SpeiseplanDataSet.QueryString;
    Datamodule1.SpeiseplanDataSet.Close;
    Datamodule1.SpeiseplanDataSet.AfterOpen := Refresh_SpeisePlan;
    DataModule1.SpeiseplanDataSet.QueryString := hst;

    DataModule1.SpeiseplanDataSet.Load;
}

  end;

end;

procedure TAnzSpeiseplanTag2.WebButton4Click(Sender: TObject);
begin
  if DataModule1.SpeiseplanDataSet.Fieldbyname('TID').AsInteger = 0 then exit;

  Datamodule1.ArtikelDataSet.Close;
  Datamodule1.ArtikelDataSet.AfterOpen := Zeige_Artikel_1;
  DataModule1.ArtikelDataSet.QueryString := '$filter=NL_Key eq '+IntToStr(AktFirmaNo)
     + ' and Nummer eq '+ DataModule1.SpeiseplanDataSet.Fieldbyname('Artikel').AsString;
  DataModule1.ArtikelDataSet.Load;

end;

procedure TAnzSpeiseplanTag2.WebButton5Click(Sender: TObject);
begin
  if DataModule1.SpeiseplanDataSet.Fieldbyname('TID').AsInteger = 0 then exit;

  Datamodule1.ArtikelDataSet.Close;
  Datamodule1.ArtikelDataSet.AfterOpen := Zeige_Bilder_1;
  DataModule1.ArtikelDataSet.QueryString := '$filter=NL_Key eq '+IntToStr(AktFirmaNo)
     + ' and Nummer eq '+ DataModule1.SpeiseplanDataSet.Fieldbyname('Artikel').AsString;
  DataModule1.ArtikelDataSet.Load;



end;

procedure TAnzSpeiseplanTag2.WebButton6Click(Sender: TObject);
var D : TDateTime;
    A,G,TID,SPTID : integer;
    hst,hst2 : String;

begin
  if DataModule1.SpeiseplanDataSet.Fieldbyname('TID').AsInteger = 0 then exit;
  if DataModule1.SpeiseplanDataSet.Fieldbyname('KZ_Bestellt').AsInteger = 9 then exit;

  if DataModule1.SpeiseplanDataSet.Fieldbyname('Datum').AsDateTime < BestEnde  then begin
    MessageDlg('Dieses Datum liegt in der Vergangenheit oder Bestell-Ende bereits erreicht', mtError, [], nil);
    exit;
  end;
  try
    Enable_Buttons(false);
//    DataModule1.SpeiseplanDataSet.DisableControls;

    TID := DataModule1.SpeiseplanDataSet.Fieldbyname('TID').AsInteger;
    D := DataModule1.SpeiseplanDataSet.Fieldbyname('Datum').AsDateTime;
    G := DataModule1.SpeiseplanDataSet.Fieldbyname('Gruppe').AsInteger;
    A := DataModule1.SpeiseplanDataSet.Fieldbyname('Artikel').AsInteger;
    SPTID := DataModule1.SpeiseplanDataSet.Fieldbyname('SPTID').AsInteger;

    repeat
      DataModule1.SpeiseplanDataSet.Prior;
    until DataModule1.SpeiseplanDataSet.BOF or
      ((DataModule1.SpeiseplanDataSet.Fieldbyname('Datum').AsDateTime <> D)
      or (DataModule1.SpeiseplanDataSet.Fieldbyname('Gruppe').AsInteger <> G));
    if ((DataModule1.SpeiseplanDataSet.Fieldbyname('Datum').AsDateTime <> D)
         or (DataModule1.SpeiseplanDataSet.Fieldbyname('Gruppe').AsInteger <> G)) then begin
      DataModule1.SpeiseplanDataSet.Next;
    end;
    repeat
      try
        DataModule1.SpeiseplanDataSet.Edit;
        DataModule1.SpeiseplanDataSet.Fieldbyname('KZ_Bestellt').AsInteger := 9;
        DataModule1.SpeiseplanDataSet.Post;
        Daten_Geaendert := true;


//        DataModule1.SpeiseplanDataSet.Refresh;
      except
        MessageDlg('Fehler beim Speichern', mtError, [], nil);
        exit;
      end;
      DataModule1.SpeiseplanDataSet.Next;
    until DataModule1.SpeiseplanDataSet.EOF or
      ((DataModule1.SpeiseplanDataSet.Fieldbyname('Datum').AsDateTime <> D)
        or (DataModule1.SpeiseplanDataSet.Fieldbyname('Gruppe').AsInteger <> G));
  finally
    Datamodule1.XDataWebConnection1.OnError := ServerError_1;
    if Daten_Geaendert then begin
      DataModule1.SpeiseplanDataSet.ApplyUpdates;
    end;
    Daten_Geaendert := false;
//    DataModule1.SpeiseplanDataSet.Refresh;
//    DataModule1.SpeiseplanDataSet.EnableControls;
    Enable_Buttons(true);
    WebDBGrid1Click(Self);

{
    Akt_TID := TID;
    Akt_SPTID := SPTID;
    Akt_Datum := D;

    WebDBGrid1.OnClick := nil;
    WebDBGrid1.DataSource := nil;

    hst := DataModule1.SpeiseplanDataSet.QueryString;
    Datamodule1.SpeiseplanDataSet.Close;
    Datamodule1.SpeiseplanDataSet.AfterOpen := Refresh_SpeisePlan;
    DataModule1.SpeiseplanDataSet.QueryString := hst;
    DataModule1.SpeiseplanDataSet.Load;
}

  end;


end;

procedure TAnzSpeiseplanTag2.WebButton7Click(Sender: TObject);
begin
  Do_Bewertung;
end;

procedure TAnzSpeiseplanTag2.WebDBGrid1Click(Sender: TObject);
var i,j : integer;
begin
  WebButton2.Visible := false;
  WebButton3.Visible := false;
  WebButton6.Visible := false;
  i := DataModule1.SpeiseplanDataSet.Fieldbyname('TID').AsInteger;
  if i = 0 then exit;
  WebButton2.Visible := true;
  WebButton3.Visible := true;
  WebButton6.Visible := true;

  j := DataModule1.SpeiseplanDataSet.Fieldbyname('KZ_Bestellt').AsInteger;
  case j of
    0 : begin
          WebButton3.Visible := false;
        end;
    1 : begin
          WebButton2.Visible := false;
        end;
    9 : begin
          WebButton3.Visible := false;
          WebButton6.Visible := false;
        end;

  end;
  StatusWebLabel.Caption := DataModule1.SpeiseplanDataSet.Fieldbyname('DatumD2').AsString + ' '
                            + DataModule1.SpeiseplanDataSet.Fieldbyname('Status').AsString;
  StatusLabel2.Caption := DataModule1.SpeiseplanDataSet.Fieldbyname('Bezeichnung').AsString + ' '
                            + DataModule1.SpeiseplanDataSet.Fieldbyname('Bezeichnung2').AsString;

end;

procedure TAnzSpeiseplanTag2.WebFormClose(Sender: TObject;
  var Action: TCloseAction);
begin
  Zeilen_Liste.Clear;
  Zeilen_Liste.Free;

end;

procedure TAnzSpeiseplanTag2.WebFormCreate(Sender: TObject);
begin
  Form_Aktiv := false;

end;

procedure TAnzSpeiseplanTag2.WebFormResize(Sender: TObject);
begin
  if not Form_Aktiv then exit;
  Resize_Edit_Controls(WF_Grid,self,Zeilen_Liste,WebPanel1,WebPanel3);

end;

procedure TAnzSpeiseplanTag2.WebFormShow(Sender: TObject);
var DT : TDateTime;
    D : TDateTime;
begin

  Daten_Geaendert := false;



  D := Date;
  case DataModule1.EinrichtungDataSet.Fieldbyname('Bestell_Ende_Art').AsInteger of
    1 : D := D - 1;
    2 : D := D - 2;
    3 : D := D - 3;
  end;
  D := D + DataModule1.EinrichtungDataSet.Fieldbyname('Bestell_Ende').AsDateTime;
  BestEnde := D;


  Resize_Top_Panel_4(self,WebPanel1,'Akt. Benutzer: '+ AktKDName,'Akt. Teilnehmer: '+ AktTeilnehmerName,
    'Bestell-Ende: '+Convert_Datum(DateToStr(D)) + ' ' + TimeToStr(DataModule1.EinrichtungDataSet.Fieldbyname('Bestell_Ende').AsDateTime));

  Resize_Bottom_Panel(self,WebPanel3);
  Zeilen_Liste := TStringList.Create;
  Zeilen_Count := 0;
  Form_Aktiv := true;
  Resize_Edit_Controls(WF_Grid,self,Zeilen_Liste,WebPanel1,WebPanel3);
  WebDBGrid1.DataSource := DataModule1.SpeiseplanSource;
  WebDBGrid1Click(Self);
end;



procedure TAnzSpeiseplanTag2.Zeige_Artikel_1(DataSet: TDataSet);
begin
  Datamodule1.Artikel_LTDataSet.Close;
  Datamodule1.Artikel_LTDataSet.AfterOpen := Zeige_Artikel;
//  DataModule1.Artikel_LTDataSet.QueryString := 'Artikel_Langtext?$filter=ID eq 364&$expand=txt';

  DataModule1.Artikel_LTDataSet.QueryString := '$filter=ID eq '+
     DataModule1.SpeiseplanDataSet.Fieldbyname('ArtikelID').AsString;
  DataModule1.Artikel_LTDataSet.Load;
end;



procedure TAnzSpeiseplanTag2.Zeige_Artikel(DataSet: TDataSet);
var newform : TAnzArtikel;
begin
  newform := TAnzArtikel.CreateNew(procedure(AForm: TObject)
    begin
      (AForm as TAnzArtikel).Caption := 'Artikel';
    end
  );
  newform.Border := fbSingle;

  newform.ShowModal(procedure(AValue: TModalResult)
  begin

  end
  );



end;

procedure TAnzSpeiseplanTag2.Zeige_Bilder_1(DataSet: TDataSet);
begin
  Datamodule1.BilderDataSet.Close;
  Datamodule1.BilderDataSet.AfterOpen := Zeige_Bilder;
  DataModule1.BilderDataSet.QueryString := '$filter=Art eq 1 and ID2 eq '
     + DataModule1.SpeiseplanDataSet.Fieldbyname('ArtikelID').AsString
     + ' &$orderby=lfdno';
  DataModule1.BilderDataSet.Load;

end;



procedure TAnzSpeiseplanTag2.Zeige_Bilder(DataSet: TDataSet);
var newform : TAnzArtBilder;
begin
  newform := TAnzArtBilder.CreateNew(procedure(AForm: TObject)
    begin
      (AForm as TAnzArtBilder).Caption := 'Artikel';
    end
  );
  newform.Border := fbSingle;

  newform.ShowModal(procedure(AValue: TModalResult)
  begin

  end
  );



end;

procedure TAnzSpeiseplanTag2.Enable_Buttons(b : boolean);
begin
  WebButton4.Enabled := b;
  WebButton5.Enabled := b;
  WebButton2.Enabled := b;
  WebButton3.Enabled := b;
  WebButton6.Enabled := b;
  WebButton1.Enabled := b;


end;

procedure TAnzSpeiseplanTag2.Refresh_Speiseplan(DataSet: TDataSet);
begin
  WebDBGrid1.DataSource := nil;
  try
  if (DataModule1.SpeiseplanDataSet.Fieldbyname('TID').AsInteger = AKt_TID)
    and (DataModule1.SpeiseplanDataSet.Fieldbyname('Datum').AsDateTime = Akt_Datum)
    and (DataModule1.SpeiseplanDataSet.Fieldbyname('SPTID').AsInteger = Akt_SPTID) then begin
      WebDBGrid1.DataSource := DataModule1.SpeiseplanSource;
      WebDBGrid1.OnClick := WebDBGrid1Click;
      WebDBGrid1Click(Self);
      exit;
  end;
  DataModule1.SpeiseplanDataSet.First;
  while not DataModule1.SpeiseplanDataSet.EOF do begin
    if (DataModule1.SpeiseplanDataSet.Fieldbyname('TID').AsInteger = AKt_TID)
      and (DataModule1.SpeiseplanDataSet.Fieldbyname('Datum').AsDateTime = Akt_Datum)
      and (DataModule1.SpeiseplanDataSet.Fieldbyname('SPTID').AsInteger = Akt_SPTID) then begin
        WebDBGrid1.DataSource := DataModule1.SpeiseplanSource;
        WebDBGrid1.OnClick := WebDBGrid1Click;
        WebDBGrid1Click(Self);
        exit;
    end;
    DataModule1.SpeiseplanDataSet.Next;
  end;
  except
  end;
end;



procedure TAnzSpeiseplanTag2.ServerError_1(Error: TXDataWebConnectionError);
var hst : String;
begin
  hst := 'Server 1 Fehler: '+Error.ErrorMessage;
  MessageDlg('hst', mtError, [], nil);
end;


procedure TAnzSpeiseplanTag2.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebPanel1 := TPanel.Create(Self);
  WebPanel3 := TPanel.Create(Self);
  WebButton1 := TButton.Create(Self);
  WebButton2 := TButton.Create(Self);
  WebButton3 := TButton.Create(Self);
  WebButton6 := TButton.Create(Self);
  WebDBGrid1 := TDBGrid.Create(Self);
  WebPanel2 := TPanel.Create(Self);
  WebButton4 := TButton.Create(Self);
  WebButton5 := TButton.Create(Self);
  WebButton7 := TButton.Create(Self);
  WebPanel4 := TPanel.Create(Self);
  StatusWebLabel := TLabel.Create(Self);
  StatusLabel2 := TLabel.Create(Self);

  WebPanel1.BeforeLoadDFMValues;
  WebPanel3.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  WebButton3.BeforeLoadDFMValues;
  WebButton6.BeforeLoadDFMValues;
  WebDBGrid1.BeforeLoadDFMValues;
  WebPanel2.BeforeLoadDFMValues;
  WebButton4.BeforeLoadDFMValues;
  WebButton5.BeforeLoadDFMValues;
  WebButton7.BeforeLoadDFMValues;
  WebPanel4.BeforeLoadDFMValues;
  StatusWebLabel.BeforeLoadDFMValues;
  StatusLabel2.BeforeLoadDFMValues;
  try
    Name := 'AnzSpeiseplanTag2';
    Left := 0;
    Top := 0;
    Width := 323;
    Height := 480;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -16;
    Font.Name := 'Arial';
    Font.Style := [];
    TabOrder := 1;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnResize', 'WebFormResize');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 0;
    WebPanel1.Top := 0;
    WebPanel1.Width := 323;
    WebPanel1.Height := 137;
    WebPanel1.Align := alTop;
    WebPanel1.BorderColor := clSilver;
    WebPanel1.BorderStyle := bsSingle;
    WebPanel1.ChildOrder := 1;
    WebPanel3.SetParentComponent(Self);
    WebPanel3.Name := 'WebPanel3';
    WebPanel3.Left := 0;
    WebPanel3.Top := 408;
    WebPanel3.Width := 323;
    WebPanel3.Height := 72;
    WebPanel3.Align := alBottom;
    WebPanel3.BorderColor := clSilver;
    WebPanel3.BorderStyle := bsSingle;
    WebPanel3.ChildOrder := 3;
    WebButton1.SetParentComponent(WebPanel3);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 233;
    WebButton1.Top := 9;
    WebButton1.Width := 78;
    WebButton1.Height := 43;
    WebButton1.Caption := 'Zur'#252'ck';
    WebButton1.ChildOrder := 3;
    WebButton1.ElementClassName := 'btn-primary';
    WebButton1.Font.Charset := DEFAULT_CHARSET;
    WebButton1.Font.Color := clWindowText;
    WebButton1.Font.Height := -16;
    WebButton1.Font.Name := 'Arial';
    WebButton1.Font.Style := [];
    WebButton1.ParentFont := False;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    WebButton2.SetParentComponent(WebPanel3);
    WebButton2.Name := 'WebButton2';
    WebButton2.Left := 7;
    WebButton2.Top := 9;
    WebButton2.Width := 119;
    WebButton2.Height := 43;
    WebButton2.Caption := 'Bestellen';
    WebButton2.ChildOrder := 1;
    WebButton2.ElementClassName := 'btn-primary';
    WebButton2.Font.Charset := DEFAULT_CHARSET;
    WebButton2.Font.Color := clWindowText;
    WebButton2.Font.Height := -16;
    WebButton2.Font.Name := 'Arial';
    WebButton2.Font.Style := [];
    WebButton2.ParentFont := False;
    SetEvent(WebButton2, Self, 'OnClick', 'WebButton2Click');
    WebButton3.SetParentComponent(WebPanel3);
    WebButton3.Name := 'WebButton3';
    WebButton3.Left := 7;
    WebButton3.Top := 28;
    WebButton3.Width := 119;
    WebButton3.Height := 41;
    WebButton3.Caption := 'Abbestellen';
    WebButton3.ChildOrder := 4;
    WebButton3.ElementClassName := 'btn-primary';
    WebButton3.Font.Charset := DEFAULT_CHARSET;
    WebButton3.Font.Color := clWindowText;
    WebButton3.Font.Height := -16;
    WebButton3.Font.Name := 'Arial';
    WebButton3.Font.Style := [];
    WebButton3.ParentFont := False;
    SetEvent(WebButton3, Self, 'OnClick', 'WebButton3Click');
    WebButton6.SetParentComponent(WebPanel3);
    WebButton6.Name := 'WebButton6';
    WebButton6.Left := 132;
    WebButton6.Top := 6;
    WebButton6.Width := 95;
    WebButton6.Height := 43;
    WebButton6.Caption := 'Storno';
    WebButton6.ChildOrder := 3;
    WebButton6.ElementClassName := 'btn-primary';
    WebButton6.Font.Charset := DEFAULT_CHARSET;
    WebButton6.Font.Color := clWindowText;
    WebButton6.Font.Height := -16;
    WebButton6.Font.Name := 'Arial';
    WebButton6.Font.Style := [];
    WebButton6.ParentFont := False;
    SetEvent(WebButton6, Self, 'OnClick', 'WebButton6Click');
    WebDBGrid1.SetParentComponent(Self);
    WebDBGrid1.Name := 'WebDBGrid1';
    WebDBGrid1.Left := 0;
    WebDBGrid1.Top := 238;
    WebDBGrid1.Width := 323;
    WebDBGrid1.Height := 170;
    WebDBGrid1.Align := alClient;
    WebDBGrid1.Columns.Clear;
    with WebDBGrid1.Columns.Add do
    begin
      DataField := 'DatumD2';
      Title := 'Datum';
      Width := 80;
    end;
    with WebDBGrid1.Columns.Add do
    begin
      DataField := 'BildUrl';
      DataType := cdImage;
      Width := 41;
    end;
    with WebDBGrid1.Columns.Add do
    begin
      DataField := 'BEZEICHNUNG';
      Title := 'Bezeichnung';
      Width := 378;
    end;
    WebDBGrid1.FixedCols := 1;
    WebDBGrid1.Font.Charset := DEFAULT_CHARSET;
    WebDBGrid1.Font.Color := clWindowText;
    WebDBGrid1.Font.Height := -19;
    WebDBGrid1.Font.Name := 'Arial';
    WebDBGrid1.Font.Style := [];
    WebDBGrid1.Options := [goFixedVertLine,goFixedHorzLine,goVertLine,goHorzLine,goRangeSelect,goRowSelect];
    WebDBGrid1.ParentFont := False;
    WebDBGrid1.TabOrder := 2;
    WebDBGrid1.ShowIndicator := False;
    SetEvent(WebDBGrid1, Self, 'OnClick', 'WebDBGrid1Click');
    WebDBGrid1.ColWidths[0] := 20;
    WebDBGrid1.ColWidths[1] := 80;
    WebDBGrid1.ColWidths[2] := 41;
    WebDBGrid1.ColWidths[3] := 378;
    WebDBGrid1.RowHeights[0] := 22;
    WebDBGrid1.RowHeights[1] := 22;
    WebDBGrid1.RowHeights[2] := 22;
    WebDBGrid1.RowHeights[3] := 22;
    WebDBGrid1.RowHeights[4] := 22;
    WebPanel2.SetParentComponent(Self);
    WebPanel2.Name := 'WebPanel2';
    WebPanel2.Left := 0;
    WebPanel2.Top := 197;
    WebPanel2.Width := 323;
    WebPanel2.Height := 41;
    WebPanel2.Align := alTop;
    WebPanel2.BorderColor := clSilver;
    WebPanel2.BorderStyle := bsSingle;
    WebPanel2.ChildOrder := 3;
    WebButton4.SetParentComponent(WebPanel2);
    WebButton4.Name := 'WebButton4';
    WebButton4.Left := 5;
    WebButton4.Top := 6;
    WebButton4.Width := 98;
    WebButton4.Height := 27;
    WebButton4.Caption := 'Info';
    WebButton4.ChildOrder := 3;
    WebButton4.Font.Charset := DEFAULT_CHARSET;
    WebButton4.Font.Color := clWindowText;
    WebButton4.Font.Height := -16;
    WebButton4.Font.Name := 'Arial';
    WebButton4.Font.Style := [];
    WebButton4.ParentFont := False;
    SetEvent(WebButton4, Self, 'OnClick', 'WebButton4Click');
    WebButton5.SetParentComponent(WebPanel2);
    WebButton5.Name := 'WebButton5';
    WebButton5.Left := 113;
    WebButton5.Top := 6;
    WebButton5.Width := 98;
    WebButton5.Height := 27;
    WebButton5.Caption := 'Bilder';
    WebButton5.ChildOrder := 4;
    WebButton5.Font.Charset := DEFAULT_CHARSET;
    WebButton5.Font.Color := clWindowText;
    WebButton5.Font.Height := -16;
    WebButton5.Font.Name := 'Arial';
    WebButton5.Font.Style := [];
    WebButton5.ParentFont := False;
    SetEvent(WebButton5, Self, 'OnClick', 'WebButton5Click');
    WebButton7.SetParentComponent(WebPanel2);
    WebButton7.Name := 'WebButton7';
    WebButton7.Left := 221;
    WebButton7.Top := 6;
    WebButton7.Width := 96;
    WebButton7.Height := 27;
    WebButton7.Caption := 'Bewertung';
    WebButton7.ChildOrder := 2;
    SetEvent(WebButton7, Self, 'OnClick', 'WebButton7Click');
    WebPanel4.SetParentComponent(Self);
    WebPanel4.Name := 'WebPanel4';
    WebPanel4.Left := 0;
    WebPanel4.Top := 137;
    WebPanel4.Width := 323;
    WebPanel4.Height := 60;
    WebPanel4.Align := alTop;
    WebPanel4.BorderColor := clSilver;
    WebPanel4.BorderStyle := bsSingle;
    WebPanel4.ChildOrder := 4;
    StatusWebLabel.SetParentComponent(WebPanel4);
    StatusWebLabel.Name := 'StatusWebLabel';
    StatusWebLabel.Left := 6;
    StatusWebLabel.Top := 8;
    StatusWebLabel.Width := 115;
    StatusWebLabel.Height := 18;
    StatusWebLabel.Caption := 'StatusWebLabel';
    StatusWebLabel.Font.Charset := DEFAULT_CHARSET;
    StatusWebLabel.Font.Color := clWindowText;
    StatusWebLabel.Font.Height := -15;
    StatusWebLabel.Font.Name := 'Arial';
    StatusWebLabel.Font.Style := [fsBold];
    StatusWebLabel.ParentFont := False;
    StatusLabel2.SetParentComponent(WebPanel4);
    StatusLabel2.Name := 'StatusLabel2';
    StatusLabel2.Left := 6;
    StatusLabel2.Top := 32;
    StatusLabel2.Width := 92;
    StatusLabel2.Height := 18;
    StatusLabel2.Caption := 'StatusLabel2';
    StatusLabel2.Font.Charset := DEFAULT_CHARSET;
    StatusLabel2.Font.Color := clWindowText;
    StatusLabel2.Font.Height := -15;
    StatusLabel2.Font.Name := 'Arial';
    StatusLabel2.Font.Style := [fsBold];
    StatusLabel2.ParentFont := False;
  finally
    WebPanel1.AfterLoadDFMValues;
    WebPanel3.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
    WebButton3.AfterLoadDFMValues;
    WebButton6.AfterLoadDFMValues;
    WebDBGrid1.AfterLoadDFMValues;
    WebPanel2.AfterLoadDFMValues;
    WebButton4.AfterLoadDFMValues;
    WebButton5.AfterLoadDFMValues;
    WebButton7.AfterLoadDFMValues;
    WebPanel4.AfterLoadDFMValues;
    StatusWebLabel.AfterLoadDFMValues;
    StatusLabel2.AfterLoadDFMValues;
  end;
end;

end.